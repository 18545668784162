import React from "react";

import { Box, Flex, InputDebounce, PageTitle, PillHeading, Text } from "atoms";
import Pagination from "react-js-pagination";

export const PageHead = ({
  Icon,
  title,
  query,
  setQuery,
  page,
  setPage,
  resultPerPage,
  totalCount,
  type="page",
  bg=false,
  children
}) => {
  return (
    <>
      {type === "page" && <PageTitle
        icon={Icon}
        title={title}
      />}
      <Box
        overflow={type === "pill" ? "hidden" : "unset"}
        py={(type === "pill" || bg) ? "3rem" : "unset"}
        px={type === "pill" ? "3rem" : "4rem"}
        bg={bg && "secondary.300"}
        borderRadius={bg && "0.4rem"}
        m={bg && "2rem"}
      >        
        {type === "pill" && <Box width="fit-content" mx="auto">
          <PillHeading title={title} />
        </Box>}      
        <Box my="2rem">
          <InputDebounce   
            name="search"
            type="text"
            id="search"                    
            label="Search here"
            placeholder="Search here"
            value={query}          
            setValue={setQuery}                        
            mb="2rem"
          />
        </Box> 

        {children}

        {resultPerPage < totalCount &&  <Flex
          justifyContent="center"
          my="4rem"
        >
          <Pagination
            activePage={page}
            itemsCountPerPage={resultPerPage}
            totalItemsCount={totalCount}
            onChange={(e) => setPage(e)}
            nextPageText="Next"
            prevPageText="Prev"
            firstPageText="1st"
            lastPageText="Last"
            itemClass="pagination-item"
            linkClass="page-link"
            activeClass="pagination-item-active"
            activeLinkClass="pagination-link-active"
          />
        </Flex>}       
        {totalCount > 0 &&  <Text
          textAlign="center"
          fontSize="1.4rem"
          fontWeight="600"
          color="primary.200"
          my="3rem"
        >
          Total Entries Found : {totalCount}
        </Text>}  
      </Box>
    </>
  )
}