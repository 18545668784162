import React from "react";

import { Box, Text, VocPageTitle } from "atoms";
import { GetLink, PdfCard } from "molecules";
import SEO from "seo/SEO";

export const NEPPage = () => {
  return (
    <>
      <SEO
        title="National Education Policy || Soban Singh Jeena University Almora"
        description="National Education Policy Admission Rules || Soban Singh Jeena University Almora"
        keywords="National Education Policy SSJU , National Education Policy Soban Singh Jeena University Almora , National Education Policy Soban Singh Jeena University , National Education Policy Uttarakhand University , National Education Policy SSJ University , National Education Policy SSJU"        
      />
      <Box      
        mx="auto"
        maxWidth="100rem" 
        px={{xs: "3rem", lg: "unset"}} 
        color="grey.300"
      >
        <Box my="3rem">
          <VocPageTitle
            title="About NEP"
          />
          <Text textAlign="center" fontSize="1.5rem" lineHeight="2.4REM">        
            The National Education Policy of India 2020, which was approved by the Union Cabinet of India on 29 July 2020, outlines the vision of new education system of India. The new policy replaces the previous National Policy on Education, 1986.
          </Text> 
        </Box>
        <Box my="4rem">    
          <VocPageTitle
            title="Resources"
          /> 
          <Box width="fit-content" mx="auto">
            <PdfCard          
              title={"स्नातक पाठ्यक्रमों (बी0ए0, बी0एस-सी0, बी0कॉम0) के लिए प्रवेश नियम व अध्यादेश 2022"}
              href={"https://ssju.ac.in/pdf/NEP-Admission-Rule-160822.pdf"}
              addedOn={"Tuesday, 16 Aug 2022"}
            />
          </Box>             
          <Box width="fit-content" mx="auto" my="3rem">
            <PdfCard          
              title={"MINOR VOCATIONAL SUBJECTS/COURSE"}
              href={"https://ssju.ac.in/pdf/Vocational-&-Cocurricular-170822.pdf"}
              addedOn={"Wednesday, 17 Aug 2022"}
            />
          </Box>             
          <Box mx="auto" width="fit-content">
            <GetLink url="/nep-syllabus">
              <Box maxWidth="22rem" bg="primary.300" color="white" py="1rem" px="2rem" textAlign="center" borderRadius="0.4rem" mt="4rem" cursor="pointer" fontSize="1.6rem">
                Download NEP Syllabus
              </Box>
            </GetLink>
          </Box>
        </Box>
      </Box>
    </>
  )
}