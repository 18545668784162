import React, { useEffect } from "react";
import { MdOutlineCastForEducation } from "react-icons/md";

import { PageTitle } from "atoms";
import { Layout } from "organisms";
import { NEPPage } from "templates";
import { scrollToTop } from "utils/utilities";

const NEP = () => {

  useEffect(() => {
    scrollToTop(window)    
  }, [])

  return (
    <Layout>
      <PageTitle
        icon={<MdOutlineCastForEducation size="2.5rem"/>}
        title="National Education Policy"
      />     
      <NEPPage/> 
    </Layout>
  )
}

export default NEP