import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

import { Box, Flex, Text } from "atoms";
import { GetLink } from "molecules";

export const VocHeader = ({
  data
}) => {

  const [ mobileMenu, setMobileMenu] = useState(false);

  return (
    <>      
      <Box bg="white" py="1rem" width="100vw" zIndex="5" borderBottom="1px solid" borderColor="grey.50">
        {/* Desktop Navbar */}
        <Box          
          maxWidth="144rem"
          mx="auto"        
          px="4rem"          
          alignItems="center"
          justifyContent="center"
          fontSize="1.2rem"
          fontWeight="500"
          display={{xs: "none", lg: "flex"}}  
          color="primary.200"
          py="0.8rem"      
        >
          {data?.map((item) => (
            <Flex key={item?.id} textTransform="uppercase" fontWeight="600" mx="3rem" letterSpacing="0.3rem">
              <GetLink url={item?.url}>
                <Text>
                  {item?.label}
                </Text>
              </GetLink>                         
            </Flex>
          ))}
        </Box>
        {/* Mobile & Tab Navbar */}
        <Box
          display={{xs: "flex", lg: "none"}}    
          justifyContent="flex-end"          
          color="primry.200"
          bg="white"
          pr="3rem"
        >
          <Box
            onClick={()=> setMobileMenu(!mobileMenu)}
            color="primary.200"
          >
            {mobileMenu ? <AiOutlineClose size="3rem" />  : <AiOutlineMenu size="4rem" />}            
          </Box>
        </Box>
      </Box>  
      {mobileMenu &&  <Box
        bg="white"             
        width="100vw"             
        zIndex="4"
        color="primary.200"
        fontSize="1.8rem"
        pb="2rem"
        display={{xs: "block", lg: "none"}}
      >
        {data?.map((item) => (
          <Flex key={item?.id} py="1.2rem" px="2rem" fontWeight="600" textTransform="uppercase" letterSpacing="0.3rem">
            <GetLink url={item?.url}>
              <Text>
                {item?.label}
              </Text>
            </GetLink>                       
          </Flex>
        ))}
      </Box>}    
    </>
  )
}