import React, { useState } from "react";

import { Box, Flex, Button, Grid, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { formatDate, generatePublicUrl } from "utils/utilities";
import { BtnColors } from "utils/constant-data";

export const SportsCard = ({data}) => {

  const [ showMore, setShowMore ] = useState(false);
  const desc = data?.desc?.split("<br/>");

  return (
    <Grid
      minWidth={{xm: "110rem"}}
      maxWidth={{xm: "110rem"}}
      bg="white"
      borderRadius="1rem"
      boxShadow="0px 10px 25px rgba(0, 0, 51, 0.08)"
      border="1px solid whitesmoke"
      gridTemplateColumns={{xm: "0.8fr 1fr"}}      
      mx="auto"  
    >
      <Box
        width="100%"
        height={{xs: "24rem", md: "45rem", xm: "35rem"}}        
        borderRadius={{xs: "1rem", xm: "1rem 0 0 1rem"}}
        border="1px solid"
        borderColor="whitesmoke"        
      >
        <Box
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"          
          backgroundImage={`url(${generatePublicUrl(data?.image)})`}
          borderRadius={{xs: "0.4rem", xm: "0.4rem 0 0 0.4rem"}}
          color="whitesmoke"
          className="sports-card-background-blur"
          width="100%"
          height="100%"      
          position="absolute"   
        />
        <Image
          src={generatePublicUrl(data?.image)}
          alt={data?.title}
          width="100%"
          height="100%"
          borderRadius="4px"
          objectFit="contain"   
          preview={true}       
        />
      </Box>
      <Box 
        px="2rem" 
        py="1rem"
        display="flex"
        flexDirection="column"        
      >
        <Box flexGrow="1">
          <Text color="grey.200">{formatDate(data?.createdAt)}</Text>
          <Text fontSize="1.8rem" fontWeight="600" my="1rem">{data.title}</Text>        
          <Box>
            {showMore ? desc?.map((item, index) => (
              <Text key={index} color="grey.200" mb="1rem" className="text-justify">
                {item} 
                {index === desc?.length - 1 && <Text as="span" ml="1rem" color="danger.100" cursor="pointer" onClick={() => setShowMore(false)}>show less...</Text>}
              </Text>
            )): <Text color="grey.200" mb="1rem" className="text-justify">
              {desc[0]}
              {desc?.length > 1 && <Text as="span" ml="1rem" color="danger.100" cursor="pointer" onClick={() => setShowMore(true)}>show more...</Text>}
            </Text>}
          </Box>
        </Box>
        <Flex
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="center"
          mt="2rem"
          style={{gap: "2rem"}}
        >
        {data?.sportsSrc?.map((item, index) => (
          <GetLink url={generatePublicUrl(item?.href)}>
            <Box width="15rem" my="2rem">
              <Button
                py="0.6rem"
                variant={BtnColors[index % 4]}
              >
                <Text fontSize="1.4rem">
                  {item?.title}
                </Text>                
              </Button>
            </Box>
          </GetLink>
        ))}
        </Flex>
      </Box>      
    </Grid>
  )
}