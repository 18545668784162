import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Text } from "atoms";
import { Layout } from "organisms";
import { getNewsDetails } from "redux/actions";
import { NewsInfoPage } from "templates";
import { scrollToTop } from "utils/utilities";

const NewsInfo = () => {
  
  const { newsId } = useParams()
  
  const dispatch = useDispatch()
  const { loading, newsInfo } = useSelector(state => state.app);

  useEffect(() => {
    scrollToTop(window);
    if(newsId !== newsInfo?.id) dispatch(getNewsDetails(newsId));
  }, [ newsId, dispatch, newsInfo?.id ]) 

  return (
    <>
      <Layout>
        { !loading && newsInfo?.id !== newsId ? <Text my="2rem" textAlign="center">News Not Found</Text> : <NewsInfoPage data={newsInfo}/>}        
      </Layout>
    </>
  )
}

export default NewsInfo