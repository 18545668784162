import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { PageHead, SportsCard } from "molecules";
import { getSportsActivities } from "redux/actions";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";
import { MdOutlineSportsHandball } from "react-icons/md";

export const SportsPage = () => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");  

  const dispatch = useDispatch()
  const { sportsActivity } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getSportsActivities(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);      
  
  return (
    <>      
      <SEO
        title={`Sports Activities || Soban Singh Jeena University Almora`}
        description={`Sports Activities || Soban Singh Jeena University Almora`}
        keywords={`Sports Activities SSJU,Sports Activities Soban Singh Jeena University Almora,Sports Activities Soban Singh Jeena University,Sports Activities Uttarakhand University,Sports Activities SSJ University,Sports Activities SSJU`}
      />
      <PageHead
        Icon={<MdOutlineSportsHandball size="2.5rem" />}
        title="Recent Sports Activities"
        query={query}
        setQuery={setQuery}
        page={page}
        setPage={setPage}
        resultPerPage={sportsActivity?.resultPerPage}
        totalCount={sportsActivity?.totalCount}            
      >      
        <Grid
          my="3rem"
          gridTemplateColumns="1fr"
          gridGap="3rem"
          px="3rem"
          pb="4rem"        
        >
          {sportsActivity?.sports?.map((item) => (
            <SportsCard
              key={item?.id}
              data={item}
            />
          ))}        
        </Grid>
      </PageHead>          
    </>
  )
}