import React, { useEffect } from "react";

import { Layout } from "organisms";
import SEO from "seo/SEO";
import { FundingAgenciesPage } from "templates";
import { scrollToTop } from "utils/utilities";

const FundingAgencies = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <Layout>
      <SEO
        title="Research Funding Agencies || Soban Singh Jeena University Almora"
        description="Research Funding Agencies || Soban Singh Jeena University Almora"
        keywords="Research Funding Agencies SSJU,Research Funding Agencies Soban Singh Jeena University Almora,Research Funding Agencies Soban Singh Jeena University,Research Funding Agencies Uttarakhand University,Research Funding Agencies SSJ University,Research Funding Agencies SSJU"
      />
      <FundingAgenciesPage/>
    </Layout>
  )
}

export default FundingAgencies;