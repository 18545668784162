import React from "react";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { getStrapiMediaUrl } from "utils/utilities";

export const SectionCards = ({
  title,
  cards
}) => {
  return (
    <Box mb="3rem">
      {title && <Text
        color="primary.200"
        fontWeight="700"
        fontSize="2.4rem"
        lineHeight="4rem"
        borderBottom="3px solid"
        borderColor="primary.100"
      >
        {title}
      </Text>}
      <Grid
        gridTemplateColumns={{md: "1fr 1fr 1fr"}}
        gridColumnGap="2rem"
        gridRowGap="2rem"
        mt="2rem"
      >
        {cards?.map((item) => (
          <Flex
            key={item?.id}
            bg="white"
            alignItems="center"
            justifyContent="center"
            borderRadius="0.4rem"
            boxShadow="2px 2px 15px -5px rgba(0,0,0,0.75)"
            py={item?.type === "icon" && "4rem"}
            px={item?.type === "icon" && "2rem"}
            flexDirection="column"
            overflow="hidden"
          >
            {item?.image?.data &&  item?.type === "image" ? <Box
              width="100%"
              height="240px"
            >
              <Image
                src={getStrapiMediaUrl(item?.image?.data?.attributes?.url)}
                alt={item?.image?.data?.attributes?.alt}
                width="100%"
                height="100%"
              />
            </Box> : item?.image?.data &&  item?.type === "icon" && <Flex
              alignItems="center"
              justifyContent="center"
            >              
              <Box
                width="54px"
                height="54px"
              >                
                <Image
                  src={getStrapiMediaUrl(item?.image?.data?.attributes?.url)}
                  alt={item?.image?.data?.attributes?.alt}
                  width="100%"
                  height="100%"
                  objectFit="contain"
                />
              </Box>
            </Flex>} 
            <Box
              py={item?.type === "image" && "4rem"}
              px={item?.type === "image" && "2rem"}
              textAlign="center"
            >
              {item?.title && <Text mt="3rem" fontSize="2rem" fontWeight="600" color="primary.150">
                {item?.title}
              </Text>}
              {item?.description && <Text mt="2rem" fontSize="1.8rem" fontWeight="600" color="primary.100">
                {item?.description}
              </Text>}
            </Box>           
          </Flex>
        ))}              
      </Grid>
    </Box>
  )
}