import React from "react";

import { Box, Text } from "atoms";
import { GetLink } from "molecules";

export const SyllabusCard = ({title, href}) => {
  return (
    <Box
      width="100%"
      padding="2rem 1.5rem"
      boxShadow="1px 1px 2px 0px rgba(132,127,127,0.75)"
      borderRadius="4px"
      bg="grey.150"
    >
      <Text fontSize="1.7rem" color="grey.300" textAlign="center">
        {title}
      </Text>
      <GetLink url={href}>
        <Box
          textTransform="uppercase"
          bg="primary.300"
          py="0.8rem"
          px="2rem"
          color="white"
          borderRadius="0.4rem"
          maxWidth="20rem"
          mx="auto"
          textAlign="center"
          fontSize="1.5rem"
          mt="3rem"
        >
          Download
        </Box>
      </GetLink>
    </Box>
  )
}