import React from "react";
import Markdown from "markdown-to-jsx";

import { Text } from "atoms";

export const MarkdownJSX = ({
  content, 
  textAlign="center",
  ...props
}) => {

  const MyH1 = ({ children }) => <Text
    as="h1"    
    fontSize={{xs: "2.4rem", md: "3.6rem"}}
    lineHeight={{xs: "3rem", md: "4.8rem"}}
    fontWeight="600"        
    textAlign={textAlign}
    {...props}
  >
    {children}
  </Text>

  const MyH2 = ({ children }) => <Text
    as="h2"    
    fontSize={{xs: "2rem", md: "3rem"}}
    lineHeight="1.7"
    fontWeight="600"    
    textAlign={textAlign}
    {...props}
  >
    {children}
  </Text>

  const MyH3 = ({ children }) => <Text
    as="h3"    
    fontSize={{xs: "1.8rem", md: "2.4rem"}}
    lineHeight="1.7"
    fontWeight="600"    
    textAlign={textAlign}
    {...props}
  >
    {children}
  </Text>

  const MyH4 = ({ children }) => <Text
    as="h4"    
    fontSize={{xs: "1.6rem", md: "2rem"}}
    lineHeight="1.7"
    fontWeight="500"    
    textAlign={textAlign}
    {...props}
  >
    {children}
  </Text>

  const MyH5 = ({ children }) => <Text
    as="h5"    
    fontSize={{xs: "1.4rem", md: "1.8rem"}}
    lineHeight="1.7"
    fontWeight="500"    
    textAlign={textAlign}
    {...props}
  >
    {children}
  </Text>

  const MyH6 = ({ children }) => <Text
    as="p"    
    fontSize={{xs: "1.5rem" }}
    lineHeight="1.6"
    fontWeight="400"    
    color="grey.200"
    textAlign={textAlign}
    {...props}
  >    
    {children}
  </Text>

  const MyP = ({ children }) => <Text    
    fontSize={{xs: "1.5rem"}}
    lineHeight="1.6"
    fontWeight="400"    
    color="grey.200"
    textAlign={textAlign}
    {...props}
  >
    {children}
  </Text>

  const MyLi = ({ children }) => <Text    
    fontSize={{xs: "1.5rem"}}
    lineHeight="1.6"
    fontWeight="400"    
    color="grey.200"
    textAlign="start"
    {...props}
    as="li"
  >
    {children}
  </Text>

  return (
    <Markdown
      options={{
        overrides: {
          h1: {
            component: MyH1,
          },
          h2: {
            component: MyH2,
          },
          h3: {
            component: MyH3,
          },
          h4: {
            component: MyH4,
          },
          h5: {
            component: MyH5,
          },
          h6: {
            component: MyH6,
          },
          p: {
            component: MyP,
          },
          li: {
            component: MyLi,
          }
        },
      }}
    >
      {content}
    </Markdown>
  )
}