import React from "react";

import { Description } from "molecules";
import SEO from "seo/SEO";
import { formatDate, generatePublicUrl } from "utils/utilities";

export const CareerInfoPage = ({
  data
}) => {
  return (
    <>
      <SEO
        title={`${data?.title} - Recruitment || Soban Singh Jeena University Almora`}
        description={`${data?.title} - Recruitment || SSJU`}
        keywords={`${data?.title} - Recruitment SSJU,${data?.title} - Recruitment Soban Singh Jeena University Almora,${data?.title} - Recruitment Soban Singh Jeena University,${data?.title} - Recruitment SSJ University,`}        
        previewImage={generatePublicUrl(data?.image)}
      />
      <Description
        image={generatePublicUrl(data?.image)}
        title={data?.title}
        createdAt={formatDate(data?.createdAt)}
        desc={data?.desc}
        src={data?.recuritmentSrc?.map(src => ({title: src?.title, url: generatePublicUrl(src?.href)}))}
      />      
    </> 
  )
}