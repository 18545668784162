import React from "react";

import { Box, Grid } from "atoms";
import { MemberCard } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";
import { AddingSoon } from "../AddingSoon";

export const Members = ({ data }) => {
  return (
    <>      
      <Box className="tab-body">      
        <Grid
          gridTemplateColumns={data?.length ? {md: "repeat(2, 1fr)"} : "1fr"}
          gridColumnGap="2.5rem"      
          gridRowGap="2.5rem"      
          my="2rem"      
          px="1rem"
        >
          {data?.length > 0 ? data?.map((item, index) => (
            <MemberCard 
              key={index} 
              data={{
                imageURL: getStrapiMediaUrl(item?.image?.data?.attributes?.url),
                memberName: item?.name,
                profileURL: getStrapiMediaUrl(item?.profile?.data?.attributes?.url),
                designation: item?.designation,
                specialization: item?.specification,
                phone: item?.phone,
                email: item?.email
              }} 
            />
          )) : <AddingSoon/>}        
        </Grid>
      </Box>
    </>
  )
}