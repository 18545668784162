import React, { useEffect } from "react";

import { Layout } from "organisms";
import { AboutTypePage } from "templates";
import { scrollToTop } from "utils/utilities";

const AboutType = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <Layout>
        <AboutTypePage/>
      </Layout>
    </>
  )
}

export default AboutType