import React from "react";

import { Box, Grid, PillHeading, Text } from "atoms";
import { CourseCard } from "molecules";

export const HomeFeatureCourse = ({
  courses
}) => {
  return (
    <>
      <Box maxWidth="100vw" px="3rem" py="3rem" bg="primary.200">
        <Box width="fit-content" mx="auto" mb="3rem">
          <PillHeading title="Featured Courses" />
        </Box>
        <Text color="white" fontSize="1.4rem" fontWeight="bold" textAlign="center" opacity="0.8">
          Our Featured Programs are selected through a rigorous process and uniquely created for each semester.
        </Text>
        <Grid
          my="4rem"
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}
          gridColumnGap="2rem"
          gridRowGap="2rem"
          justifyContent="center"
        >
          {courses?.map((item) => (
            <CourseCard
              key={item?.id}
              data={item}
            />
          ))}          
        </Grid>
      </Box>
    </>
  )
}