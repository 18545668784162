import React, { useEffect } from "react";
import { BsCardChecklist } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, PageTitle } from "atoms";
import { Layout } from "organisms";
import { getEntranceExamInfo } from "redux/actions";
import { EntranceExamInfoPage } from "templates";
import { scrollToTop } from "utils/utilities";

const EntranceExamInfo = () => {

  document.title = "Online Entrance Exam || Soban Singh Jeena University Almora";

  const { courseName } = useParams();  
  const dispatch = useDispatch();
  const { entranceExamInfo, loading } = useSelector((state) => state.app);

  useEffect(() => {
    scrollToTop(window);
    if(entranceExamInfo?.id !== courseName) dispatch(getEntranceExamInfo(courseName));
  }, [courseName, dispatch, entranceExamInfo?.id])

  return (
    <Layout>      
      <PageTitle
        icon={<BsCardChecklist size="2.5rem"/>}
        title="Online Application Form For Entrance Examination SSJU 2023"
      />
      {entranceExamInfo && !entranceExamInfo.hide && entranceExamInfo?.id === courseName ?
        <EntranceExamInfoPage
          data={entranceExamInfo}
        />
      : !loading && <Box px="2rem" py="3rem" textAlign="center" fontSize="2rem" color="gray">Entrance Exam Not Found</Box>}      
    </Layout>
  )
}

export default EntranceExamInfo