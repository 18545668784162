import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { SyllabusCard } from "molecules";
import SEO from "seo/SEO";
import { getStrapiMediaUrl } from "utils/utilities";
import { getVocSyllabus } from "redux/actions";

export const VocSyllabusPage = () => {

  const dispatch = useDispatch();
  const { vocSyllabus } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(getVocSyllabus());
  }, [dispatch]);

  return (
    <>
      <SEO
        title={`Syllabus - Center of Vocational Studies || Soban Singh Jeena University Almora`}
        description={`Syllabus - Center of Vocational Studies || Soban Singh Jeena University Almora`}
        keywords={`Syllabus - Center of Vocational Studies SSJU,Syllabus - Center of Vocational Studies Soban Singh Jeena University Almora,Syllabus - Center of Vocational Studies Soban Singh Jeena University,Syllabus - Center of Vocational Studies Uttarakhand University,Syllabus - Center of Vocational Studies SSJ University,Syllabus - Center of Vocational Studies SSJU`}
      />
      <Box px="3rem" mb="3rem">
        <Grid
          gridTemplateColumns={{md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}        
          my="2rem"
          gridColumnGap="2rem"
          gridRowGap="2rem"
        >
          {vocSyllabus?.data?.map((item) => (
            <SyllabusCard
              key={item?.id}
              title={item?.attributes?.title}
              href={getStrapiMediaUrl(item?.attributes?.file?.data?.attributes?.url)}
            />
          ))}
        </Grid>      
      </Box>
    </>
  )
}