import React, { useEffect } from "react";
import { FcLibrary } from "react-icons/fc";

import { PageTitle } from "atoms";
import { Layout } from "organisms";
import SEO from "seo/SEO";
import { LibraryPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Library = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <Layout>   
        <SEO
          title="A D Pant Central Library, Soban Singh Jeena University, Campus Almora || Soban Singh Jeena University Almora"
          description="A D Pant Central Library, Soban Singh Jeena University, Campus Almora || Soban Singh Jeena University Almora"
          keywords="A D Pant Central Library, Soban Singh Jeena University, Campus Almora SSJU,A D Pant Central Library, Soban Singh Jeena University, Campus Almora Soban Singh Jeena University Almora,A D Pant Central Library, Soban Singh Jeena University, Campus Almora Soban Singh Jeena University,A D Pant Central Library, Soban Singh Jeena University, Campus Almora Uttarakhand University,A D Pant Central Library, Soban Singh Jeena University, Campus Almora SSJ University,A D Pant Central Library, Soban Singh Jeena University, Campus Almora SSJU,Images Soban Singh Jeena University Almora,Images Soban Singh Jeena University,Images Uttarakhand University,Images SSJ University"        
        />   
        <PageTitle
          icon={<FcLibrary size="2.5rem" />}
          title="A D Pant Central Library, Soban Singh Jeena University, Campus Almora"
        />
        <LibraryPage/>
      </Layout>
    </>
  )
}

export default Library