//Admission
const ADMPGCourses = [
  {
    title: "Department of Yogic Science",
    desc: "P.G Diploma In Yogic Science(Self Mode)",
    table: [
      {
        title: "Duration",
        info: "01 year ( 02 Semester)",
      },
      {
        title: "Fees",
        info: "1st Sem - ₹10,000 <br/> 2nd Sem - ₹6,710",
      },
      {
        title: "Eligibility",
        info: "Bachelor Degree in any Discipline",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "40",
      },
    ],
  },
  {
    title: "Department of Yogic Science",
    desc: "Master Degree In Yogic Science(Self Mode)",
    table: [
      {
        title: "Duration",
        info: "02 year ( 04 Semester)",
      },
      {
        title: "Fees",
        info: "1st Sem - ₹10,000 <br/> 2nd Sem - ₹9,640 <br/> 3rd Sem - ₹10,000 <br/> 4th Sem - ₹7,650",
      },
      {
        title: "Eligibility",
        info: "Bachelor Degree in any Discipline",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "40",
      },
    ],
  },
  {
    title: "Department of Yogic Science",
    desc: "P.G Diploma In Yogic Science(General Mode)",
    table: [
      {
        title: "Duration",
        info: "01 year ( 02 Semester)",
      },
      {
        title: "Fees",
        info: "1st Sem - ₹4,560 <br/> 2nd Sem - ₹1,200",
      },
      {
        title: "Eligibility",
        info: "Bachelor Degree in any Discipline",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "40",
      },
    ],
  },
  {
    title: "Department of Yogic Science",
    desc: "Master Degree In Yogic Science(General Mode)",
    table: [
      {
        title: "Duration",
        info: "02 year ( 04 Semester)",
      },
      {
        title: "Fees",
        info: "1st Sem - ₹4,560 <br/> 2nd Sem - ₹1,130 <br/> 3rd Sem - ₹2,560 <br/> 4th Sem - ₹1,240",
      },
      {
        title: "Eligibility",
        info: "Bachelor Degree in any Discipline",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "40",
      },
    ],
  },
];

const ADMBVocCourse = [
  {
    title: "CERTIFICATE COURSE IN ORNAMENTAL FLOWER CULTIVATION",
    desc: "",
    table: [
      {
        title: "Fees",
        info: "₹8000/-",
      },
      {
        title: "Eligibility",
        info: "Intermediate with biology background with 50 % marks for General candidates and 45 % marks for reserved category students.",
      },
      {
        title: "Duration",
        info: "6 Months",
      },
    ],
  },
  {
    title: "CERTIFICATE COURSE IN ESSENTIAL OIL FARMING",
    desc: "",
    table: [
      {
        title: "Fees",
        info: "₹8000/-",
      },
      {
        title: "Eligibility",
        info: "Intermediate with biology background with 50 % marks for General candidates and 45 % marks for reserved category students.",
      },
      {
        title: "Duration",
        info: "6 Months",
      },
    ],
  },
  {
    title: "Department of Vocational Studies",
    desc: "Certificate Course in Home Stay & Hospitality Management- 6 months (4 Months Theory+2 Months Practical)",
    table: [
      {
        title: "Fees",
        info: "₹ 8,000/-",
      },
      {
        title: "Eligibility",
        info: "Graduation in any stream with 45% marks, for reserve category-40%.",
      },
    ],
  },
];

const ADMSelfCourse = [
  {
    title: "Department of Forestry",
    desc: "B.Sc. (Honours ) Forestry- 4 Year Programme (08 Semesters)",
    table: [
      {
        title: "Fees",
        info: "₹30000/Semester",
      },
      {
        title: "Eligibility",
        info: "Intermediate (10+2) with Biology or Mathematics or Equivalent stream with 50% marks, for reserve category-45%.",
      },
      {
        title: "Mode of Admission",
        info: "On merit basis",
      },
    ],
  },
  {
    title: "Department of Botany",
    desc: "Applied Bio Science: Mushroom Cultivation and Beekeeping – 1 year Diploma Course (02 Semesters)",
    table: [
      {
        title: "Fees",
        info: "₹10000/ Semester",
      },
      {
        title: "Eligibility",
        info: "Intermediate (preference for Bio Group) with 50% marks, for reserve category-45%.",
      },
      {
        title: "Mode of Admission",
        info: "On merit basis",
      },
      {
        title: "Age limit",
        info: "40 Year",
      },
    ],
  },
  {
    title: "Department of Geography",
    desc: "P.G. Diploma in Tourism Management- one year (02 Semesters)",
    table: [
      {
        title: "Fees",
        info: "₹17000 in First Semester <br/> ₹15000 in Second Semester",
      },
      {
        title: "Eligibility",
        info: "Bachelor Degree (10+2+3) with 50% marks in any discipline, for reserve category-45%.",
      },
      {
        title: "Mode of Admission",
        info: "On merit basis (marks obtained in Graduation).",
      },
      {
        title: "Age limit",
        info: "40 Year",
      },
      {
        title: "No of Seats",
        info: "20 Seats",
      },
    ],
  },
  {
    title: "Department of G.I.S",
    desc: "M.Sc. Remote Sensing and G.I.S",
    table: [
      {
        title: "Fees",
        info: "Tuition fee ₹20,000 per Semester <br/> campus fee ₹2,000 per year.",
      },
      {
        title: "Eligibility",
        info: "Bachelor Degree (10+2+3) with 45% marks and it is mandatory to have one of the following subjects at the graduation level – <br/> 1.Geography <br/> 2.Physics <br/> 3.Botany <br/> 4.Forestry <br/> 5.Computer Science <br/> 6.Statistics <br/> 7.Mathematics <br/> 8.Geology <br/> 9.Civil Engineering <br/> 10.Information Technology <br/> 11.Geoinformatics",
      },
      {
        title: "Mode of Admission",
        info: "On merit basis (marks obtained in Graduation).",
      },
    ],
  },
  // {
  //     title: 'Faculty of Visual Arts',
  //     desc: 'Bachelor of Visual Art',
  //     table:[
  //         {
  //             title: 'Admission Criteria',
  //             info: '10+2 pass with minimum 40% marks*'
  //         },
  //         {
  //             title: 'Age Limit',
  //             info: '30 Years'
  //         },
  //         {
  //             title: 'Intake',
  //             info: '40 Seats'
  //         },
  //         {
  //             title: 'Duration',
  //             info: '04 year ( 08 Semester)'
  //         },
  //         {
  //             title: 'Fees',
  //             info: '1st Sem ₹7770/- <br/> 2nd Sem ₹4000/- <br/> 3rd Sem ₹7550/- <br/> 4th Sem ₹4150/- <br/> 5th Sem ₹7550/- <br/> 6th Sem ₹4000/- <br/> 7th Sem ₹7550/- <br/> 8th Sem ₹4000/-'
  //         },
  //     ],
  //     warning: ["Reservation (Category wise) as per University Rules.", "New Admissions will be held on merit basis"]
  // },
  // {
  //     title: 'Faculty of Visual Arts',
  //     desc: 'Master of Visual Art - Painting',
  //     table:[
  //         {
  //             title: 'Admission Criteria',
  //             info: 'BFA pass with minimum 45% marks'
  //         },
  //         {
  //             title: 'Age Limit',
  //             info: '38 Years'
  //         },
  //         {
  //             title: 'Intake',
  //             info: '20 Seats'
  //         },
  //         {
  //             title: 'Duration',
  //             info: '04 Semester ( 02 Years)'
  //         },
  //         {
  //             title: 'Fees',
  //             info: '1st Sem ₹16,100 <br/> 2nd Sem ₹14,000 <br/> 3rd Sem ₹15,770 <br/> 4th Sem ₹14,000'
  //         },
  //     ],
  //     warning: ["Reservation (Category wise) as per University Rules.", "New Admissions will be held on merit basis"]
  // },
];

const ADMCertificateCourse = [
  {
    title: "Department of Yogic Science",
    desc: "C.Y ( Certificate in Yoga)(Self Mode)",
    table: [
      {
        title: "Duration",
        info: "3 Months",
      },
      {
        title: "Fees",
        info: "₹3000",
      },
      {
        title: "Eligibility",
        info: "Any Person",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "30",
      },
    ],
  },
  {
    title: "Department of Yogic Science",
    desc: "C.N.Y.S ( Certificate in Naturopathy & Yogic Science)(Self Mode)",
    table: [
      {
        title: "Duration",
        info: "6 Months",
      },
      {
        title: "Fees",
        info: "₹6500+₹1800(Exam Fees)",
      },
      {
        title: "Eligibility",
        info: "10+2",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "50",
      },
    ],
  },
  {
    title: "Department of Yogic Science",
    desc: "Certificate in Marma Therapy(Self Mode)",
    table: [
      {
        title: "Duration",
        info: "6 Months",
      },
      {
        title: "Fees",
        info: "₹6500+₹1800(Exam Fees)",
      },
      {
        title: "Eligibility",
        info: "10+2",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "30",
      },
    ],
  },
  {
    title: "Department of Yogic Science",
    desc: "Certificate in Panchakarma Therapy(Self Mode)",
    table: [
      {
        title: "Duration",
        info: "6 Months",
      },
      {
        title: "Fees",
        info: "₹6500+₹1800(Exam Fees)",
      },
      {
        title: "Eligibility",
        info: "10+2",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "30",
      },
    ],
  },
];

const ADMDiplomaCourse = [
  {
    title: "Department of Yogic Science",
    desc: "D.N.Y.S ( Diploma in Naturopathy & Yogic Science)(Self Mode)",
    table: [
      {
        title: "Duration",
        info: "1 Year ( 02 Semester)",
      },
      {
        title: "Fees",
        info: "₹6500+₹1800(Exam Fees)",
      },
      {
        title: "Eligibility",
        info: "10+2 & after CNYS Lateral Entry in II Sem",
      },
      {
        title: "Mode of Admission",
        info: "On Merit Basis",
      },
      {
        title: "Seats",
        info: "50",
      },
    ],
  },
];

const ADMBVocContact = [
  {
    firmName: "Smart Skills Society, हल्द्वानी, उत्तराखंड",
    courseName: "B.Voc. (Automotive Manufacturing and Assembly)",
    contact: "9997241455",
  },
  {
    firmName: "Dream Shapers Society, हल्द्वानी, उत्तराखंड",
    courseName:
      "B.Voc. (Hospitality Management) <br/> B.Voc. (Banking and Financial Management)",
    contact: "9456236510 <br/> 9456185033",
  },
  {
    firmName: "Bhumi IT Innovators Pvt. Ltd. अल्मोडा, उत्तराखंड",
    courseName:
      "B.Sc.(Voc.)-(Cyber Security) <br/> B.Sc.(Voc.)-(Internet of Things) <br/> B.Sc.(Voc.)-(Cloud Computing) <br/> B.Sc.(Voc.)-(Biofuels)",
    contact: "9410733909",
  },
  {
    firmName: "Avalon Academy, देहरादून, उत्तराखंड",
    courseName: "B.Voc. (Airport Management)",
    contact: "9084702705",
  },
  {
    firmName: "Royal College, काठगोदाम, हल्द्वानी, उत्तराखंड",
    courseName: "B.Voc. (Tourism and Resort Management )",
    contact: "8433202065",
  },
  {
    firmName: "KITM College, खटीमा, उत्तराखंड",
    courseName:
      "B. Voc. (Catering Technology and Hotel Management) <br/> B.Sc.(Voc.)-(Information Technology)",
    contact: "9568110012",
  },
  {
    firmName: "STIHM Collage, हल्द्वानी, उत्तराखंड",
    courseName: "B.Voc. (Hotel and Hospitality Administration)",
    contact: "9808104954",
  },
  {
    firmName: "St Adams Institute Of Professional Studies, Garur, Uttarakhand",
    courseName:
      "B.Sc.(Voc.)- Information Technology <br/> B.Voc. (Banking and Financial Management)",
    contact: "8171362312 <br/> 9837522312",
  },
  {
    firmName:
      "Manas College of Science Technology and Management, Pithoragarh, Uttarakhand",
    courseName:
      "B.Sc.(Voc.)- Information Technology <br/> B.Voc. (Banking and Financial Management) <br/> B.Voc. (Nutritional & Healthcare Science)",
    contact: "8750405320",
  },
];

const ADMBVocInstruction = [
  {
    title: "प्रथम सेमेस्टर",
    fee1: "₹15000.00",
    fee2: "₹11250.00",
  },
  {
    title: "द्वितिय सेमेस्टर",
    fee1: "₹15000.00",
    fee2: "₹11250.00",
  },
  {
    title: "तृतिय सेमेस्टर",
    fee1: "₹16500.00 ",
    fee2: "₹12375.00",
  },
  {
    title: "चतुर्थ सेमेस्टर",
    fee1: "₹16500.00 ",
    fee2: "₹12375.00",
  },
  {
    title: "पंचम सेमेस्टर",
    fee1: "₹18000.00 ",
    fee2: "₹13613.00",
  },
  {
    title: "सष्ठम सेमेस्टर",
    fee1: "₹18000.00 ",
    fee2: "₹13613.00",
  },
];

//Library
const LibraryData = [
  {
    label: "Digital Initiatives of MoE",
    children: [
      { "label": "Digital Initiatives of MoE", "link": "https://www.education.gov.in/en/ict-initiatives" },
    ]
  },
  {
    label: "E-BOOKS",
    children: [
      { "label": "Project Gutenberg", "link": "https://www.gutenberg.org/" },
      { "label": "Open Access books on JSTOR", "link": "https://about.jstor.org/librarians/books/open-access-books-jstor/" },
      { "label": "PDF Drive", "link": "https://www.pdfdrive.com/" },
      { "label": "DOAB", "link": "https://www.doabooks.org/" },
      { "label": "Red Print", "link": "http://www.readprint.com/" },
      { "label": "eGyanKosh", "link": "http://egyankosh.ac.in/" },
      { "label": "Free Government E-books and Reports", "link": "https://www.india.gov.in/hi/my-government/documents/e-books" },
      { "label": "Epustakalay", "link": "https://epustakalay.com/" },
      { "label": "Hindibooks", "link": "https://www.hindibookspdf.com/free-hindi-books/" },
      { "label": "Ebooks", "link": "https://www.ebookspdf.in/" },
      { "label": "Our Hindi.com", "link": "https://pdfbooks.ourhindi.com/list-of-all-books/" },
      { "label": "44 Books", "link": "https://www.44books.com/free-hindi-books" },
    ]
  },
  {
    label: "E-JOURNALS",
    children: [
      { "label": "DOAJ (Directory of Open Access Journals)", "link": "https://doaj.org" },
      { "label": "NISCAIR Online Periodicals", "link": "http://nopr.niscair.res.in/" },
      { "label": "Indian Academy of Sciences Journals", "link": "https://www.ias.ac.in/Journals/Overview/" },
      { "label": "Google Scholar", "link": "https://scholar.google.com/" },
      { "label": "Open Access Journals", "link": "https://www.openaccessjournals.com/" },
      { "label": "Electronic Journals Library", "link": "http://ezb.ur.de/ezeit/fl.phtml?bibid=AAAAA&amp;colors=1&amp;lang=en" },
      { "label": "MDPI", "link": "https://www.mdpi.com/" },      
    ]
  },
  {
    label: "E-NEWSPAPERS",
    children: [
      { "label": "Hindustan", "link": "https://epaper.livehindustan.com/" },
      { "label": "Jagran", "link": "https://epaper.jagran.com/epaper" },
      { "label": "Dainik Bhaskar", "link": "https://epaper.bhaskar.com/" },
      { "label": "Navbharat Time", "link": "https://epaper.navbharattimes.com/" },
      { "label": "Jansatta", "link": "https://epaper.jansatta.com/" },
      { "label": "Hindustan Times", "link": "https://epaper.hindustantimes.com/Home/ArticleView" },
      { "label": "The Times of India", "link": "https://epaper.timesgroup.com/TOI/TimesOfIndia/index.html?a=c#" },      
      { "label": "Business Standard", "link": "http://epaper.business-standard.com/" },
    ]
  },
  {
    label: "Law E-resources",
    children: [
      { "label": "India Code", "link": "https://www.indiacode.nic.in/" },
      { "label": "Judgment Information System (JUDIS)", "link": "https://www.judis.nic.in/" },
      { "label": "Law Reports", "link": "https://lawcommissionofindia.nic.in/" },
      { "label": "Legal Information Institute of India (LIIofIndia)", "link": "http://liiofindia.org/" },
      { "label": "Lok Sabha Debates", "link": "http://164.100.47.194/Loksabha/Debates/Debatetextsearch16.aspx" },
      { "label": "Rajya Sabha Debates", "link": "https://rsdebate.nic.in/" },
      { "label": "The Gazette of India", "link": "http://egazette.nic.in/(S(wpxw0cdg53aytufaqtog4i5c))/default.aspx" },
    ]
  }
]

const LibraryStats = [
  {"label": "Books", "value": 230143},
  {"label": "Bound Volumes of Journals", "value": 438},
  {"label": "Periodicals/magazines subscribed", "value": 10},
  {"label": "Thesis", "value": 245},
]

const LibraryMembers = [
  "Prof. Bheema Manral (Professor In-charge Library and chairman)",
  "Prof. P.S.Bisht- Department of Physics, Member",
  "Prof. K.N.Pandey- Department of Sanskrit, Member",
  "Prof. A.S.Adhikari- Department of English, Member",
  "Prof. Balvant Kumar- Department of Botany, Member",
  "Prof. Amit Pant- Department of Law, Member",
  "Prof. M.M. Jinnah- Department of Commerce, Member",
  "Dr. D.S.Bisht- Department of Education, Member",
  "Dr. Sanjeev Arya- Department of Visual Arts, Member",
  "Dr. Bibhash Kumar Mishra , Deputy Librarian and Secretary"
]

//Other
const AboutData = {  
  "visionList": [
    "Offer broad and balanced academic programs that are mutually reinforcing and emphasize high quality and creative instruction at all levels.",
    "Generate new knowledge through a broad array of schularly, research and creative endeavours, which provide a foundation for dealing with the immediate as well as long-term needs of society.",
    "Achieve leadership in each discipline, strengthen interdisciplinary studies, and pioneer new fields of learning.",
    "Participate extensively in state-wide, national and international programmes and encourage others in the university system, at other educational institutions and in state, national and international organizations, to seek benefit from the university's unique educational resources, such as faculty and staff expertise, libraries, archives, museums and research facilities.",
    "Strengthen cultural understanding through opportunities to study languages, cultures, the arts, and the implications of social, political, economic and technological change off campus and abroad.",
    "Maintain a level of excellence and standards in all programs that will give them state-wide, national, and international significance.",
    "Embody, through its policies and programs, respect for, and commitment to, the ideals of a pluralistic, multiracial, open and democratic society.",
  ],
  "goalsList": [
    {
      "title": "Enhance and diversify the university's academic offerings",
      "desc": "Develop timely, interdisciplinary, applied programs that address state and public priorities such as sustainability and the environment, globalization, civic engagement, health care, infrastructure, education, and community-based support systems, to name a few. Current and new academic programs would complement the interdisciplinary research efforts on campus. New academic programs and certificates would complement and expand the range of certificates and degrees available at the university, rather than replicate the current offerings."
    },
    {
      "title": "Respond quickly and responsibly to workforce development needs ",
      "desc": "In today's economic climate, the university's obligation to contribute to economic development is even greater than it has been in the past. Through its economic stimulus programmes, the government is seeking to accelerate job creation by increasing funds available for skill development. The university will cater to the demand for instruction in existing as well as emerging employment areas."
    },
    {
      "title": "Promote and expand lifelong learning opportunities",
      "desc": "Individuals seek learning opportunities for many reasons. Some hope to acquire skills and knowledge that will enable them to find employment in new areas, or to become more skilled in their current jobs. Others seek intellectual enrichment and personal growth. The university would seek to identify the learning needs of prospective students at all life stages and to offer a diverse array of learning opportunities, ranging from full-time programs like undergraduate and graduate degrees, to certificate programs targeting employed adults. The university will collaborate with representatives from government, business, industry, and other arenas to create programmes for lifelong learners."
    },
    {
      "title": "Increase the number of professional courses",
      "desc": "Business leaders cite a pressing need for employees who have technical knowledge and skills in particular areas. To help address this need, some organizations have called for the development of professional master's degrees to prepare highly skilled professionals for employment in industries of the type that drive the state's economy, such as high-value agriculture, MSME, Ayush, renewable energy, IT and ITES, biotechnology and tourism among others. The university will collaborate with local and regional employers to design and offer such professional degrees in areas of greatest demand"
    },
    {
      "title": "Increase revenues",
      "desc": " By introducing new courses, offering professional research and consultancy services, and providing other auxiliary services, the university will aim to create new revenues streams and ensure financial self sufficiency in the long run."
    },
  ]
}

const VCMessageData = {
  "imageURL": "https://strapi.ssju.ac.in/uploads/bio_sketch1_2dab58204b.png",
  "memberName": "Professor Satpal Singh Bisht",
  "designation": "Hon'ble Vice-Chancellor",
  "specialization": "",
  "phone": "-",
  "email": "vc@ssju.ac.in"
}

const BtnColors = ["danger", "accent", "warning", "info"]

const LinkPrefix = ["http", "www", "mailto", "tel", "sms", "mailTo"]

module.exports = {    
  ADMPGCourses,
  ADMBVocCourse,
  ADMSelfCourse,
  ADMCertificateCourse,
  ADMDiplomaCourse,
  ADMBVocContact,
  ADMBVocInstruction,    
  LinkPrefix, 
  LibraryData,
  LibraryStats,
  LibraryMembers,
  BtnColors,  
  AboutData,  
  VCMessageData,  
};