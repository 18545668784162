import React, { useEffect } from "react";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { Layout } from "organisms";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";

export default function ErrorPage() {

  document.title = "404 | Page Not Found"

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="404 | Page Not Found"
        description="The page you are looking for does not exist."
      />
      <Layout>
        <Flex
          bg="white"                
          p={{xs: "8rem 3rem", md: "10rem 3rem"}}
          as="section"
          maxWidth={{xs: "100%", md: "120rem"}}
          mx="auto"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box
            width={{xs: "100%", md: "64rem"}} 
            mb="4rem"
          >
            <Image
              src="/images/404.png"
              alt="404 Error"
              width="100%"
              height="100%"            
            />
          </Box>
          <Text            
            fontSize="3rem"
            fontWeight="800"
            mb="2rem"
            color="primary.500"
          >
            Page Not Found
          </Text>
          <Text            
            fontSize="1.6rem"
            fontWeight="600"
            color="grey.200"
            textAlign="center"
            maxWidth="64rem"  
            lineHeight="1.5"        
          >
            The page you are looking for might have been removed had its name changed or The page you are looking for might have been  had its name changed or is temporarily unavailable.
          </Text>
          <Box
            width="fit-content"
            mt="4rem"
          >
            <GetLink
              url="/"
            >
              <Button
                variant="primary"
                p="1.5rem 3rem"              
              >
                Go to Home
              </Button>
            </GetLink>
          </Box>
        </Flex>
      </Layout>
    </>
  );
}