import React from "react";
import { BsPhone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";

import { Box, Button, Flex, Grid, Image, PillHeading, Text } from "atoms";
import { GetLink } from "molecules";
import {  } from "atoms";

export const MemberCard = ({data, vertical=false}) => {  
  return (
    <Grid
      width="100%"
      gridTemplateColumns={vertical ? "1fr" : {xm: "0.5fr 1fr"}}
      gridColumnGap="1.5rem" 
      gridRowGap="1rem"  
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
      border="1px solid #e0e0e0"
      borderRadius="0.6rem"
      px="1rem"
      bg="white"
    >
      <Flex alignItems="center" flexDirection="column" py="1rem">
        <Box
          width="12rem"
          height="12rem"
          mb={data?.profileURL && "1.5rem"}
        >
          <Image
            src={data?.imageURL}
            alt={data?.memberName}
            width="100%"
            height="100%"
            borderRadius="50%"
          />
        </Box>
        {data?.profileURL && <GetLink url={data?.profileURL}>
          <Box
            minWidth="12rem"
            maxWidth="20rem"
          >          
            <Button
              variant="secondary"
              py="0.8rem"              
            >
              <Text fontSize="1.4rem" fontWeight="600">
                Profile
              </Text>
            </Button>          
          </Box>
        </GetLink>}        
      </Flex>

      <Flex alignItems={vertical ? "center" : {xs: "center", xm: "start"}} flexDirection="column" pb="2rem" pt={!vertical && { xm: "2rem" }}>
        <Text fontSize="1.8rem" fontWeight="600" width="100%" textAlign={vertical ? "center" : { xs: "center", xm: "start" }}>
          {data?.memberName}
        </Text>
        {data?.designation && <Box mt="1rem">
          {vertical ? <PillHeading
            title={data?.designation}
            fontSize="1.2rem"                              
          /> : <Box
            maxWidth="90%"
            bg="accent.100"
            color="white"
            py="0.6rem"
            px="0.8rem"
            borderRadius="0.4rem"
            fontSize="1.2rem" 
            fontWeight="600"  
            textAlign="center"          
          >
            {data?.designation}
          </Box>}
        </Box>}
        {data?.specialization && <Text fontSize="1.4rem" fontWeight="500" mt="1rem" textAlign={vertical ? "center" : {xs: "center", xm: "start"}}>
          {data?.specialization}
        </Text>}
        {data?.phone && <Flex color="accent.200" mt="1.4rem" alignItems="center" justifyContent="center">
          <BsPhone size="2rem" />
          <Text ml="0.5rem" fontSize="1.4rem">
            {data?.phone?.split(",")?.map((mob, index) => (
              <Text key={index} as="span" mr="1rem" fontSize="inherit">
                <GetLink url={`tel:${mob}`}>{mob}</GetLink>
              </Text>
            ))}
          </Text>
        </Flex>}
        {data?.email && <Flex color="primary.100" mt="1.2rem" alignItems="center" justifyContent="center">
          <HiOutlineMail size="2rem" />
          <Text ml="0.6rem" fontSize="1.4rem">
            <GetLink url={`mailTo:${data?.email}`}>{data?.email}</GetLink>
          </Text>
        </Flex>}
      </Flex>
    </Grid>
  )
}