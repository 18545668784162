import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { getMediaCoverage } from "redux/actions";
import { generatePublicUrl, scrollToTop } from "utils/utilities";

export const MediaCoveragePage = () => {

  const dispatch = useDispatch();
  const { media } = useSelector(state => state.app);

  const [ page, setPage ] = useState(1)    
  
  useEffect(() => {
    dispatch(getMediaCoverage(page))
  }, [page, dispatch])

  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  return (
    <Box overflow="hidden" p="3rem">      
      <Box bg="secondary.300" borderRadius="0.4rem" px="2rem" py="2.5rem">        
        <Grid
          gridTemplateColumns={{md: "1fr 1fr", xm: "1fr 1fr 1fr", lg:"1fr 1fr 1fr 1fr"}}
          gridColumnGap="1.5rem"
          gridRowGap="3rem"
          mt="3rem"             
        >
          {media?.mediaCoverage?.map((item) => (            
            <Box height="20rem" width="100%">                
              <Image
                src={generatePublicUrl(item?.image)}
                alt="Media Coverage SSJU"
                width="100%"
                height="100%"
                borderRadius="0.4rem"
                objectFit="cover"
                preview={true}
              />
            </Box>            
          ))}
        </Grid>
        {media?.resultPerPage < media?.totalCount &&  <Flex
          justifyContent="center"
          my="4rem"
        >
          <Pagination
            activePage={page}
            itemsCountPerPage={media?.resultPerPage}
            totalItemsCount={media?.totalCount}
            onChange={(e) => setPage(e)}
            nextPageText="Next"
            prevPageText="Prev"
            firstPageText="1st"
            lastPageText="Last"
            itemClass="pagination-item"
            linkClass="page-link"
            activeClass="pagination-item-active"
            activeLinkClass="pagination-link-active"
          />
        </Flex>}       
        {media?.mediaCoverage?.length > 0 &&  <Text
          textAlign="center"
          fontSize="1.4rem"
          fontWeight="600"
          color="black"
          my="2rem"
        >
          Total Entries Found : {media?.totalCount}
        </Text>}       
      </Box>
    </Box>
  )
}