import { appConstants } from "redux/constants";
import Axios from "utils/axios";
import strapiAxios from "utils/strapiAxios";

export const getExamForms = () => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_EXAM_FORMS_REQUEST })
      let URL = `/exams/exam-form`;         
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EXAM_FORMS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EXAM_FORMS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getExamFormInfo = (examName) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_EXAM_FORM_DETAILS_REQUEST })
      let URL = `/exams/exam-form/${examName}`;         
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EXAM_FORM_DETAILS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EXAM_FORM_DETAILS_FAILURE,
        payload: error.response?.data?.error
      })
    }       
  }
}

export const getExamSchedule = (page, query) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_EXAM_SCHEDULE_REQUEST })
      let URL = `/exams/exam-schedule?page=${page}`;         
      if(query) URL = URL + `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EXAM_SCHEDULE_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EXAM_SCHEDULE_FAILURE,
        payload: error.response?.data?.error
      })
    }        
  }
}

export const getEntranceExamInfo = (courseName) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_ENTRANCE_EXAM_INFO_REQUEST })
      let URL = `/exams/entrance-exam/${courseName}`;         
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ENTRANCE_EXAM_INFO_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ENTRANCE_EXAM_INFO_FAILURE,
        payload: error.response?.data?.error
      })
    }    
  }
}

export const getEntranceSyllabus = (page, query) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_ENTRANCE_SYLLABUS_REQUEST })
      let URL = `/syllabus/entrance?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ENTRANCE_SYLLABUS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ENTRANCE_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }        
  }
}

export const getAlbumInfo = (albumId) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_ALBUM_INFO_REQUEST })
      let URL = `/gallery/${albumId}`;               
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ALBUM_INFO_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ALBUM_INFO_FAILURE,
        payload: error.response?.data?.error
      })
    }     
  }
}

export const getAlbums = (page, query) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_ALBUMS_REQUEST })
      let URL = `/gallery?page=${page}`;               
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ALBUMS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ALBUMS_FAILURE,
        payload: error.response?.data?.error
      })
    }      
  }
}

export const getNEPSyllabus = (page, query) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_NEP_SYLLABUS_REQUEST })
      let URL = `/syllabus/nep?page=${page}`;               
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_NEP_SYLLABUS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_NEP_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }       
  }
}

export const getSportsActivities = (page, query) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_SPORTS_ACTIVITY_REQUEST })
      let URL = `/sports?page=${page}`;               
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_SPORTS_ACTIVITY_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_SPORTS_ACTIVITY_FAILURE,
        payload: error.response?.data?.error
      })
    }       
  }
}

export const getRecruitments = (page, query) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_RECRUITMENTS_REQUEST })
      let URL = `/recruitments?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_RECRUITMENTS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_RECRUITMENTS_FAILURE,
        payload: error.response?.data?.error
      })
    }    
  }
}

export const getNews = (page, query, type) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_NEWS_REQUEST })
      let URL = `/news?page=${page}`;               
      if(query) URL += `&query=${query}`;
      if(type) URL += `&type=${type}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_NEWS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }         
  }
}

export const getEvents = (page, query) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_EVENTS_REQUEST })
      let URL = `/events?page=${page}`;               
      if(query) URL += `&query=${query}`;      
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EVENTS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EVENTS_FAILURE,
        payload: error.response?.data?.error
      })
    }       
  }
}

export const getNewsDetails = (newsId) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_NEWS_DETAILS_REQUEST })
      let URL = `/news/${newsId}`;                     
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_NEWS_DETAILS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_NEWS_DETAILS_FAILURE,
        payload: error.response?.data?.error
      })
    }       
  }
}

export const getEventDetails = (eventId) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_EVENT_REQUEST })
      let URL = `/events/${eventId}`;                     
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EVENT_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }       
  }
}

export const getCareerDetails = (careerId) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_CAREER_REQUEST })
      let URL = `/recruitments/${careerId}`;                     
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_CAREER_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_CAREER_FAILURE,
        payload: error.response?.data?.error
      })
    }       
  }
}

export const getResultDetails = (examId) => {
  return async dispatch => {
    try{      
      dispatch({ type: appConstants.GET_RESULTS_REQUEST })
      let URL = `/exams/results/${examId}`;                     
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_RESULTS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_RESULTS_FAILURE,
        payload: error.response?.data?.error
      })
    }         
  }
}

export const getMediaCoverage = (page) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_MEDIA_REQUEST })
      let URL = `/media-coverages?page=${page}`;         
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_MEDIA_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_MEDIA_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const sendContactMail = (form) => {
  return async dispatch => {
    try{
      dispatch({
        type: appConstants.SEND_CONTACT_US_REQUEST
      })
      const { data } = await Axios.post(`/app/contact`, form)      
      dispatch({
        type: appConstants.SEND_CONTACT_US_SUCCESS,
        payload: {
          message: data?.message
        }
      })      
    }catch(error){      
      dispatch({
        type: appConstants.SEND_CONTACT_US_FAILURE,
        payload: {error: error?.response?.data}
      })
    }
  }
}

export const getVocSyllabus = () => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_VOC_SYLLABUS_REQUEST })
      let URL = `/voc-syllabi?populate=deep&sort=title:asc`      
      const { data } = await strapiAxios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_VOC_SYLLABUS_SUCCESS,
        payload: data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_VOC_SYLLABUS_FAILURE,
        payload: "Something went wrong!"
      })
    }
  }
}

export const getAffiliations = (
  page,
  limit,
  search,
) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_AFFILIAION_REQUEST })
      let URL = `/affiliations?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=createAt:desc`;
      if(search) URL += `&filters[label][$contains]=${search}`;
      const { data } = await strapiAxios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_AFFILIAION_SUCCESS,
        payload: data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_AFFILIAION_FAILURE,
        payload: { error: error?.response?.data }
      })
    }
  }
}

export const getProfessionalSyllabus = () => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_PROFESSIONAL_SYLLABUS_REQUEST })
      let URL = `/professional-syllabi?populate=deep&sort=title:asc`      
      const { data } = await strapiAxios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_PROFESSIONAL_SYLLABUS_SUCCESS,
        payload: data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_PROFESSIONAL_SYLLABUS_FAILURE,
        payload: "Something went wrong!"
      })
    }
  }
}

export const getFundingAgencies = (page, limit, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_FUNDING_AGENCIES_REQUEST })
      let URL = `/research-funding-agencies?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=name:asc`    
      if(query) URL += `&filters[name][$contains]=${query}`;      
      const { data } = await strapiAxios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_FUNDING_AGENCIES_SUCCESS,
        payload: data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_FUNDING_AGENCIES_FAILURE,
        payload: "Something went wrong!"
      })
    }
  }
}

export const getJobPortals = (page, limit, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_JOB_PORTALS_REQUEST })
      let URL = `/job-portals?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=name:asc`    
      if(query) URL += `&filters[name][$contains]=${query}`;      
      const { data } = await strapiAxios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_JOB_PORTALS_SUCCESS,
        payload: data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_JOB_PORTALS_FAILURE,
        payload: "Something went wrong!"
      })
    }
  }
}

export const getRAndD = (page, limit, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_R_AND_D_REQUEST })
      let URL = `/r-and-d-portals?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=name:asc`    
      if(query) URL += `&filters[name][$contains]=${query}`;      
      const { data } = await strapiAxios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_R_AND_D_SUCCESS,
        payload: data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_R_AND_D_FAILURE,
        payload: "Something went wrong!"
      })
    }
  }
}

export const getAboutPage = (slug) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_ABOUT_PAGE_REQUEST })
      let URL = `/abouts?filters[slug]=${slug}&populate=deep`;
      const { data } = await strapiAxios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ABOUT_PAGE_SUCCESS,
        payload: data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ABOUT_PAGE_FAILURE,
        payload: "Something went wrong!"
      })
    }
  }
}

export const getFacultyPage = (id, slugMode) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_FACULTY_PAGE_REQUEST })            
      let URL;
      if(slugMode){
        URL = `/faculty-pages?filters[faculty][slug]=${id}&populate=deep`;
      }else{
        URL = `/faculty-pages?filters[faculty][firebaseId]=${id}&populate=deep`;
      }
      const { data } = await strapiAxios.get(URL);                        
      dispatch({ 
        type: appConstants.GET_FACULTY_PAGE_SUCCESS,
        payload: data?.data[0]
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_FACULTY_PAGE_FAILURE,
        payload: "Something went wrong!"
      })
    }
  }
}