import React from "react";
import { ImLocation2 } from "react-icons/im";
import { AiTwotoneMail, AiTwotonePhone } from "react-icons/ai";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { Box, Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { LibraryAbout, LibraryCollections, LibraryEResources, LibraryServices } from "./molecules";

export const LibraryPage = () => {
  return (
    <Box
      m="2rem"
      boxShadow="0 0 10px rgba(0,0,0,0.2)"
      borderRadius="0.5rem"
      p="1rem"
    >
      <Tabs className="library-tabs">
        <TabList>
          <Tab>About</Tab>
          <Tab>Collections</Tab>
          <Tab>E-Resources</Tab>
          <Tab>OPAC</Tab>
          <Tab>Services</Tab>
          <Tab>Contact</Tab>
        </TabList>

        <TabPanel>
         <LibraryAbout/>
        </TabPanel>
        <TabPanel>
          <LibraryCollections/>
        </TabPanel>
        <TabPanel>
          <LibraryEResources/>
        </TabPanel>
        <TabPanel>
          <Box py="2rem" px="1rem">
            <Text
              fontSize="1.6rem"
              fontWeight="bold"
              textAlign="center"
              mb="1rem"
              textTransform="uppercase"
              color="#0d47a1"
            >
              OPAC(Online Public Access Catalogue)
            </Text>
            <Box my="3rem"></Box>
          </Box>
        </TabPanel>
        <TabPanel>
          <LibraryServices/>
        </TabPanel>
        <TabPanel>
          <Box py="2rem" px="1rem">
            <Text
              fontSize="1.6rem"
              fontWeight="bold"
              textAlign="center"
              mb="1rem"
              textTransform="uppercase"
              color="#0d47a1"
            >
              Contact Details
            </Text>
          </Box>
          <Flex                       
            flexWrap="wrap"
            mb="2rem"
            justifyContent="center"               
          >
            <Flex
              boxShadow="0 0 0 1px #ccc"
              p="1rem"
              borderRadius="0.4rem"
              mt="1rem"
              mx="1rem"
            >
              <Box
                fontSize="2rem"
                mr="0.5rem"
                mt="0.3rem"
                color="accent.100"
              >
                <ImLocation2/>
              </Box>
              <Text
                fontSize="1.4rem"
                color="grey.400"
                lineHeight="2rem"
              >
                A D Pant Central Library <br/>
                Soban Singh Jeena University <br/>
                Campus Almora, Uttarakhand <br/>
                Pin code- 263601
              </Text>
            </Flex>
            <Flex
              boxShadow="0 0 0 1px #ccc"
              p="1rem"
              borderRadius="0.4rem"
              mt="1rem"
              mx="1rem"
            >
              <Box
                fontSize="2rem"
                mr="0.5rem"
                mt="0.2rem"
                color="accent.100"
              >
                <AiTwotoneMail/>
              </Box>
              <Text
                fontSize="1.4rem"
                color="grey.400"
                lineHeight="2rem"
              >
                <GetLink
                  url="mailto:librarian@ssju.ac.in"                  
                >librarian@ssju.ac.in</GetLink>
              </Text>
            </Flex>
            <Flex
              boxShadow="0 0 0 1px #ccc"
              p="1rem"
              borderRadius="0.4rem"
              mt="1rem"
              mx="1rem"
            >
              <Box
                fontSize="2rem"
                mr="0.5rem"
                mt="0.2rem"
                color="accent.100"
              >
                <AiTwotonePhone/>
              </Box>
              <Text
                fontSize="1.4rem"
                color="grey.400"
                lineHeight="2rem"
              >
                <GetLink
                  url="tel:05962 235541"
                >05962 235541</GetLink><br/>
                <GetLink
                  url="tel:9411305981"
                >9411305981</GetLink>
              </Text>
            </Flex>
          </Flex>        
        </TabPanel>
      </Tabs>
    </Box>
  )
}