import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, Image, Text, VocPageTitle } from "atoms";
import { CoursePartnerCard, GetLink, VocImageSlider } from "molecules";
import SEO from "seo/SEO";
import { getStrapiMediaUrl } from "utils/utilities";

export const VocHomePage = () => {

  const { vocHomePage } = useSelector(state => state.data);

  return (
    <>
      <SEO
        title={`Center of Vocational Studies || Soban Singh Jeena University Almora`}
        description={`Center of Vocational Studies || Soban Singh Jeena University Almora`}
        keywords={`Center of Vocational Studies SSJU,Center of Vocational Studies Soban Singh Jeena University Almora,Center of Vocational Studies Soban Singh Jeena University,Center of Vocational Studies Uttarakhand University,Center of Vocational Studies SSJ University,Center of Vocational Studies SSJU`}
      />
      <Box bg="primary.400" pb="4rem">
        {vocHomePage?.images?.map((item) => (
          <Box 
            key={item?.id}
            p="2rem" 
            maxWidth={{xs: "32rem", md: "62rem"}} 
            height={{xs: "20rem", md: "30rem"}} 
            mx="auto"
          >
            <Image
              src={getStrapiMediaUrl(item?.url)}
              alt="Vocational Courses"
              width="100%"
              height="100%"
              objectFit="contain"
            />
          </Box>
        ))}               
      </Box>
      <Box>
        <VocPageTitle
          title="PHOTO GALLERY"
        />
        <VocImageSlider
          data={vocHomePage?.slider?.images}
        />
      </Box>
      <Box px="3rem" maxWidth="144rem" mx="auto">    

        <Box maxWidth="120rem" mx="auto" mb="3rem">
          {vocHomePage?.about?.length > 0 && <>
            <VocPageTitle
              title="ABOUT US"
            />                    
            {vocHomePage?.about?.map((item) => (
              <Text 
                key={item?.id}
                mb="1rem"
                fontSize="1.5rem"
                opacity="0.8"                
                lineHeight="2rem"
                className="text-justify"
              >
                {item?.value}
              </Text>              
            ))}            
          </>}      
          <Grid
            gridTemplateColumns={{md: "1fr 1fr"}}
            gridColumnGap="3rem"
            gridRowGap="2rem"
            mt="4rem"
          >
            <Box>
              {vocHomePage?.vision?.length > 0 && <Box>
                <Text mb="1.5rem" fontSize="2rem" textTransform="uppercase" color="primary.300" fontWeight="600" borderBottom="2px solid" borderColor="grey.50" pb="1rem">
                  Vision of the Centre
                </Text>
                {vocHomePage?.vision?.map((item) => (
                  <Text 
                    key={item?.id}
                    mb="1rem"
                    fontSize="1.5rem"
                    opacity="0.8"                    
                    lineHeight="2rem"
                    className="text-justify"
                  >
                    {item?.value}
                  </Text>
                ))}                
              </Box>}

              {vocHomePage?.mission?.length > 0 && <Box my="3rem">              
                <Text mb="1.5rem" fontSize="2rem" textTransform="uppercase" color="primary.300" fontWeight="600" borderBottom="2px solid" borderColor="grey.50" pb="1rem">
                  Mission of the Centre
                </Text>
                {vocHomePage?.mission?.map((item) => (
                  <Text 
                    key={item?.id}
                    mb="1rem"
                    fontSize="1.5rem"
                    opacity="0.8"                    
                    lineHeight="2rem"
                    className="text-justify"
                  >
                    {item?.value}
                  </Text>
                ))}
              </Box>}
            </Box>
            {vocHomePage?.objective?.length > 0 && <Box>            
              <Text fontSize="2rem" textTransform="uppercase" color="primary.300" fontWeight="600" borderBottom="2px solid" borderColor="grey.50" pb="1rem">
                Objectives
              </Text>
              <Box mt="1.5rem" as="ul" px="1.2rem">
                {vocHomePage?.objective?.map((item) => (
                  <Text 
                    key={item?.id} 
                    as="li" 
                    mb="0.5rem"
                    fontSize="1.5rem"
                    opacity="0.8"                    
                    lineHeight="2rem"
                  >
                    {item?.value}
                  </Text>
                ))}            
              </Box>
            </Box>}
          </Grid>
        </Box>

        <Box maxWidth="120rem" mx="auto" mb="3rem">
          <VocPageTitle
            title="PROGRAMME AND COURSES ON PUBLIC PRIVATE PARTNERSHIP MODE"
          />
          <Text className="text-justify">        
            On the basis of local need base analysis and industry based requirement various vocational courses under the guidelines of UGC & National Skills Qualifications Framework the following programmes / courses are currently running in the centre. In these courses theoretical as well as internship based practicum/ hand on experience are provided to the students to enhance their vocational skills.
          </Text>      
          <Grid
            gridTemplateColumns={{md: "1fr 1fr"}}
            gridColumnGap="3rem"
            gridRowGap="2rem"
            mt="4rem"
          > 
            {vocHomePage?.partners?.length > 0 && <Box>                   
              <Text fontSize="2rem" textTransform="uppercase" color="primary.300" fontWeight="600" borderBottom="2px solid" borderColor="grey.50" pb="1rem">
                Course Partners
              </Text>
              <Box my="1.5rem" as="ol" px="1.2rem">
              {vocHomePage?.partners?.map((item) => (
                <Text 
                  key={item?.id}
                  as="li" 
                  mb="0.8rem"
                  fontSize="1.5rem"
                  opacity="0.8"                    
                  lineHeight="2rem"
                >
                  {item?.value}
                </Text>
              ))}            
              </Box>
            </Box>}
            {vocHomePage?.courses?.length > 0 && <Box my={{xs: "3rem", md: "unset"}}>
              <Text fontSize="2rem" textTransform="uppercase" color="primary.300" fontWeight="600" borderBottom="2px solid" borderColor="grey.50" pb="1rem">
                Programme & Courses
              </Text>
              <Box my="1.5rem" as="ol" px="1.2rem">
                {vocHomePage?.courses?.map((item) => (
                  <Text 
                    key={item?.id} 
                    as="li" 
                    mb="0.8rem"
                    fontSize="1.5rem"
                    opacity="0.8"                    
                    lineHeight="2rem"
                  >
                    {item?.value}
                  </Text>
                ))}            
              </Box>
              <GetLink url="/voc/syllabus">
                <Box maxWidth="20rem" bg="primary.300" color="white" py="1rem" textAlign="center" borderRadius="0.4rem" ml="auto" mt="3rem" cursor="pointer" fontSize="1.6rem">
                  Download Syllabus
                </Box>
              </GetLink>
            </Box>}        
          </Grid>
        </Box>

        {vocHomePage?.industryPartners?.length > 0 && <Box maxWidth="120rem" mx="auto" mb="3rem">        
          <VocPageTitle
            title="INDUSTRY PARTNERS"
          />
          <Grid
            gridTemplateColumns={{md: "repeat(2, 1fr)"}}        
            my="2rem"
            gridGap="1.2rem"            
          >
            {vocHomePage?.industryPartners?.map((item, index) => (
              <Text
                key={item?.id}                
                fontSize="1.5rem"
                opacity="0.8"                
                lineHeight="2rem"
              >
                {index + 1}. {item?.value}
              </Text>
            ))}
          </Grid>
        </Box>}
            
        {vocHomePage?.institutes?.length > 0 && <>
          <VocPageTitle
            title="COURSE PARTNER INSTITUTES"
          />
          <Grid
            gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}        
            my="2rem"
            gridColumnGap="2rem"
            gridRowGap="2rem"
            justifyContent="center"          
          >
            {vocHomePage?.institutes?.map((item) => (
              <CoursePartnerCard data={item} key={item?.id} />
            ))}
          </Grid>        
        </>}

      </Box>
    </>
  )
}