import React, { useState } from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { SocialIcon } from "react-social-icons";
import ReactVisibilitySensor from "react-visibility-sensor";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const Footer = ({
  footer,
  socials,
}) => {

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const { visits } = useSelector((state) => state.data);

  return (
    <ReactVisibilitySensor
      onChange={(isVisible) => {
        if (isVisible) {
          setViewPortEntered(true);
        }
      }}
      delayedCall
    >
      <Box
        bg="primary.200"
        py="3rem"
        px="2rem"
        as="footer"
      >
        <Grid
          gridTemplateColumns={{
            xs: "1fr",
            lg: "1.4fr 2fr"
          }}
          gridColumnGap="1rem"
          gridRowGap="4rem"
        >
          <Box color="white">
            <Box
              width="80%"
              height="8rem"
              mx="auto"
            >
              <Image
                src="/images/whiteTextLogo.svg"
                alt="SSJU LOGO"
                width="100%"
                height="100%"
              />
            </Box>
            <Text
              fontSize="1.4rem"
              fontWeight="500"              
              textAlign="center"
            >
              (AISHE CODE- U-1091)
            </Text>
            <Flex
              justifyContent="center"
              alignItems="center"               
              width={{xs: "100%"}}               
              mt="2rem" 
              style={{gap: "1rem"}}
            >
              {socials?.map((item) => (
                <SocialIcon 
                  key={item?.id}
                  url={item?.url} 
                  fgColor="white"
                  bgColor="transparent"
                  style={{
                    height: 36,
                    width: 36,
                    opacity: 0.8,
                    marginLeft: "0.4rem",                    
                  }}
                  className="social-icon"
                />                    
              ))}              
            </Flex>
            <Text textAlign="center" mt="2.4rem">
              © 2023 SSJ University, Almora. All Rights Reserved.
            </Text>           
            <Text
              textAlign="center"
              mt="1.6rem"
            >
              Unique Visitors:
              <Text
                as="span"
                fontWeight="500"
                fontSize="inherit"
                ml="0.5rem"
              >
                <CountUp
                  start={viewPortEntered ? 0 : null}
                  end={visits?.uniqueVisitors}
                  duration={4}
                  suffix={"+"}
                />
              </Text>
            </Text>            

            <Text textAlign="center" mt="1.6rem">Developed & Maintained By 
              <Text
                as="span"
                fontWeight="600"
                ml="0.5rem"
                color="success.100"
              >
                <GetLink url="https://pgkonnect.com">PGKPL</GetLink>
              </Text>
            </Text>            
          </Box>
          <Grid
            gridTemplateColumns={{
              md: "1fr 1fr 1fr",              
            }}
            gridColumnGap="2rem"
            gridRowGap="1.5rem"
            color="white"
            mt={{lg: "2rem"}}
          >
            {footer?.map((item) => (
              <Box key={item.id}>
                <Text
                  fontSize="1.8rem"
                  fontWeight="500"
                  mb="1.5rem"
                >
                  {item?.label}
                </Text>
                {item?.children?.map((link, index) => (
                  <Box py="1rem" key={index}>
                    <GetLink
                      url={link?.url}
                      type="nav"
                    >
                      <Text
                        fontSize="1.4rem"
                        fontWeight="500"
                        color="white"
                        opacity="0.8"
                        _hover={{
                          opacity: 1,
                        }}
                        transition="all 0.3s ease"
                      >
                        {link?.label}
                      </Text>
                    </GetLink>                    
                  </Box>
                ))}                
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </ReactVisibilitySensor>
  )
}