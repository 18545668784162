import React from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Input, InputSelect, Loader, Text } from "atoms";
import { sendContactMail } from "redux/actions";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid format")
    .required("Email Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
  name: Yup.string()
    .required("Name Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),  
  to: Yup.string()
    .required("Department Required"),
  message: Yup.string().max(2000, "Max 2000 characters allowed"),
});

export const ContactForm = () => {

  const dispatch = useDispatch();
  const { emails } = useSelector((state) => state.data);
  const { loading } = useSelector((state) => state.app);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    const form = {
      name: values.name,
      email: values.email,      
      message: values.message,
      to: values.to      
    }   
    dispatch(sendContactMail(form))
    resetForm();
    setSubmitting(false);
  }

  return (
    <>
      {loading && <Loader/> }      
      <Box maxWidth="100%">
        <Text
          fontSize="2rem"
          fontWeight="600"
          mb="1rem"
        >
          Contact Form
        </Text>
        <Text
          fontSize="1.6rem"
          fontWeight="400"
          color="grey.200"
          mb="3rem"
        >
          Having a query? Feel free to Contact Us
        </Text>
        <Formik
          initialValues={{
            name: "",            
            email: "",
            message: "",
            to: ""
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ touched, errors, values, setFieldValue }) => {
            return (
              <Form>
                <Input                  
                  name="name"
                  type="text"
                  id="name"
                  touched={touched.name}
                  formik={true}
                  errors={errors.name}      
                  label="Name"                      
                  value={values.name}
                />  
                <Input                  
                  name="email"
                  type="email"
                  id="email"
                  touched={touched.email}
                  formik={true}
                  errors={errors.email}      
                  label="Email Address"                      
                  value={values.email}
                  mb="1.2rem"
                />   
                <InputSelect
                  value={values.to}
                  setValue={setFieldValue}
                  options={emails}
                  label="Department"                                             
                  formik={true}
                  fieldName="to"
                  touched={touched.to}
                  errors={errors.to}  
                  name="to"
                  id="to"
                  placeholder="Select Department"                 
                />                       
                <Input   
                  name="message"
                  type="text"
                  id="message"
                  touched={touched.message}
                  formik={true}
                  errors={errors.message}      
                  label="Query*"                      
                  value={values.message}
                  as="textarea"                      
                  rows={5}                                                                           
                />              
                <Button
                  variant="success"
                  width="14rem"                  
                  px="2rem"
                  py="1rem"
                  type="submit"
                  ml="auto"
                  mt="3rem"
                  fontSize="1.6rem"
                  borderRadius="0.5rem"
                  loading={loading}
                >                  
                  Submit                 
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}