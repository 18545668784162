import React, { useEffect } from "react";
import { FaUniversity } from "react-icons/fa";
import { useParams } from "react-router-dom";

import { PageTitle, Text } from "atoms";
import { Layout } from "organisms";
import { AdmissionInfoPage } from "templates";
import { scrollToTop } from "utils/utilities";

const AdmissionInfo = () => {

  const { courseType } = useParams()

  const y2022 = ["ug", "pg", "self-finance", "bvoc"]

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <Layout>
        <PageTitle
          icon={<FaUniversity size="2.5rem" />}
          title={
            <>Online Application Form For Registration{" "} 
            <Text as="span" fontSize="inherit" textTransform="uppercase">
              ({courseType})</Text>
              {y2022.includes(courseType) ? " 2022" : "2021 "}              
            </>
          }
        />
        <AdmissionInfoPage courseType={courseType} />
      </Layout>
    </>
  )
}

export default AdmissionInfo