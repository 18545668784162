import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { IoMdArrowDropdown, IoMdArrowDropleft, IoMdArrowDropright, IoMdArrowDropup } from "react-icons/io";

import { Box, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const Header = ({
  navbar
}) => {

  const [dropDownMenu, setDropDownMenu] = useState("");  
  const [rightDropdownMenu, setRightDropdownMenu] = useState("");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 240) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const closeMenu = () => {
    setDropDownMenu("");    
    setRightDropdownMenu("");
    setMobileMenu(false);
  };

  return (
    <>
      <Box bg="primary.200" py="0.8rem" width="100vw" zIndex="5" as="header">
        {/* Desktop Navbar */}
        <Box
          mx="auto"
          px="2rem"
          color="white"
          alignItems="center"
          justifyContent="space-between"
          fontSize="1.2rem"
          fontWeight="500"
          display={{ xs: "none", lg: "flex" }}
          position={scroll ? "fixed" : "unset"}
          top={scroll ? "0" : "unset"}
          left={scroll ? "0" : "unset"}
          bg="primary.200"
          maxWidth={scroll ? "100vw" : "144rem"}
          width={scroll ? "100vw" : "unset"}          
        >
          {navbar?.map((item) => (
            <Flex 
              key={item?.id}
              px="0.5rem"
              py={scroll ? "1.2rem" : "unset"}
              onMouseEnter={() => {                
                setDropDownMenu(item?.id);
              }}
              onMouseLeave={() => {              
                setDropDownMenu("");                
              }}              
            >
              <GetLink
                url={item?.children?.length > 0 ? "#" : item?.url}
                type="nav"
                onClick={item?.children?.length > 0 ? () => {} : () => closeMenu()}
              >
                <Flex alignItems="center">
                  <Text mr="0.5rem" fontSize="1.5rem">{item?.label}</Text>
                  {item?.children?.length > 0 && <Flex
                    alignItems="center"
                    justifyContent="center"
                  >
                    {item?.id === dropDownMenu ? <IoMdArrowDropup fontSize="1.6rem" /> : <IoMdArrowDropdown fontSize="1.6rem" />}                    
                  </Flex>}
                </Flex>
                <Box
                  position="absolute"
                  top="100%"
                  left="50%"
                  transform="translateX(-50%)"        
                  display={(dropDownMenu === item?.id && item?.children?.length > 0) ? "block" : "none"}
                  pt="1.2rem"
                > 
                  <Box                                  
                    bg="primary.200"                                        
                    borderRadius="0.4rem"
                    minWidth="22rem"                    
                  >
                    {item?.children?.map((child) => (
                      <Box p="1.2rem" key={child?.id}>
                        <GetLink
                          url={child?.children?.length > 0 ? "#" : child?.url}
                          type="nav"
                          onClick={child?.children?.length > 0 ? () => {} : () => closeMenu()}
                        >
                          <Box
                            onMouseOver={() => {
                              setRightDropdownMenu(child?.id);
                            }}
                            onMouseLeave={() => {
                              setRightDropdownMenu("");
                            }}
                            cursor="pointer"
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                              _hover={{
                                color: "primary.100",
                              }}
                            >
                              <Text mr="0.5rem" fontSize="1.5rem">{child?.label}</Text>
                              {child?.children?.length > 0 && <Flex                              
                                alignItems="center"
                                justifyContent="center"                             
                              >
                                {child?.id === rightDropdownMenu ? <IoMdArrowDropleft fontSize="1.6rem" /> : <IoMdArrowDropright fontSize="1.6rem" />}
                              </Flex>}
                            </Flex>
                            <Box
                              position="absolute"                              
                              display={(rightDropdownMenu === child?.id && dropDownMenu === item?.id && child?.children?.length > 0) ? "block" : "none"}
                              pl="1.5rem"
                              top="0"
                              left="100%"
                              transform="translateX(0)"
                            > 
                              <Box                                
                                bg="primary.200"                                
                                borderRadius="0.4rem"
                                minWidth="22rem"                                
                              >
                                {child?.children?.map((subChild) => (
                                  <Box
                                    p="1.2rem"                                    
                                    key={subChild?.id}
                                  >
                                    <GetLink
                                      url={subChild?.url}
                                      type="nav"
                                      onClick={() => closeMenu()}
                                    >
                                      <Text
                                        fontSize="1.5rem"
                                        _hover={{
                                          color: "primary.100",
                                        }}
                                      >
                                        {subChild?.label}
                                      </Text>
                                    </GetLink>                                   
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        </GetLink>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </GetLink>
            </Flex>
          ))}          
        </Box>
        {/* Mobile & Tab Navbar */}
        <Box
          display={{ xs: "flex", lg: "none" }}
          justifyContent={scroll ? "space-between" : "flex-end"}
          color="white"
          px="3rem"
          alignItems="center"
          position={scroll ? "fixed" : "unset"}
          top={scroll ? "0" : "unset"}
          left={scroll ? "0" : "unset"}          
          bg="primary.200"
          width="100vw"
          zIndex="5"
          py={scroll ? "1.2rem" : "unset"}           
        >
          {scroll && <GetLink
            url="/"            
          >
            <Image
              src="/images/ssju-logo.png"
              alt="logo"
              width="4rem"
              height="4rem"              
            />
          </GetLink>}
          <Box onClick={() => setMobileMenu(!mobileMenu)}>
            {mobileMenu ? (
              <AiOutlineClose size="3rem" />
            ) : (
              <AiOutlineMenu size="4rem" />
            )}
          </Box>
        </Box>
      </Box>
      {mobileMenu && <Box
        bg="primary.200"
        width="100vw"
        zIndex="4"
        color="white"        
        fontSize="1.8rem"
        pb="2rem"
        display={{ xs: "block", lg: "none" }}
        position={scroll ? "fixed" : "unset"}
        top={scroll ? "5.4rem" : "unset"}
        maxHeight={scroll ? "calc(100vh - 5.4rem)" : "unset"}
        overflowY="auto"
      >
        {navbar?.map((item) => (
          <Flex 
            key={item?.id} 
            py="1.2rem" 
            px="3rem"            
            cursor="pointer"
          >
            <GetLink
              url={item?.children?.length > 0 ? "#" : item?.url}
              type="nav"
              onClick={item?.children?.length > 0 ? () => {} : () => closeMenu()}
            >
              <Flex 
                alignItems="center"
                opacity={dropDownMenu === item?.id ? "1" : "0.8"}
                _hover={{
                  opacity: "1",
                }}
                transition="all 0.3s ease-in-out"
                onClick={() => {
                  if(dropDownMenu === item?.id) {
                    setRightDropdownMenu("");
                    setDropDownMenu("");                    
                  } else {                    
                    setDropDownMenu(item?.id);
                  }
                }}            
              >
                <Text                   
                  mr="0.5rem"
                >
                  {item?.label}
                </Text>
                {item?.children?.length > 0 && <Flex
                  alignItems="center"
                  justifyContent="center"                 
                >
                  {item?.id === dropDownMenu ? <IoMdArrowDropup fontSize="1.8rem" /> : <IoMdArrowDropdown fontSize="1.8rem" />}                  
                </Flex>}
              </Flex>
              <Box
                display={(dropDownMenu === item?.id  && item?.children?.length > 0) ? "block" : "none"}
                border="1px solid"
                borderColor="#456181"
                borderRadius="0.4rem"
                mt="1rem"                
                minWidth="calc(100vw - 6rem)"
              >
                {item?.children?.map((child, i) => (
                  <Box
                    py="1.2rem"
                    px="1rem"
                    borderBottom={i === item?.children?.length - 1 ? "none" : "1px solid"}
                    borderColor="#456181"
                    key={child?.id}
                    onClick={() => {
                      if(rightDropdownMenu === child?.id) {
                        setRightDropdownMenu("");
                      }else{
                        setRightDropdownMenu(child?.id);
                      }                      
                    }}                    
                  >
                    <GetLink
                      url={child?.children?.length > 0 ? "#" : child?.url}
                      type="nav"
                      onClick={child?.children?.length > 0 ? () => {} : () => closeMenu()}
                    >                      
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        opacity={rightDropdownMenu === child?.id ? "1" : "0.8"}
                        _hover={{
                          opacity: "1",
                        }}
                        transition="all 0.3s ease-in-out"
                      >
                        <Text                          
                          mr="0.5rem"
                        >{child.label}</Text>
                        {child?.children?.length > 0 && <Flex
                          alignItems="center"
                          justifyContent="center"
                        >
                          {child?.id === rightDropdownMenu ? <IoMdArrowDropup fontSize="1.8rem" /> : <IoMdArrowDropdown fontSize="1.8rem" />}
                        </Flex>}
                      </Flex>                      
                    </GetLink>
                    <Box                                  
                      display={(rightDropdownMenu === child?.id && dropDownMenu === item?.id && child?.children?.length > 0) ? "block" : "none"}
                      borderRadius="0.4rem"
                      minWidth="calc(100vw - 8rem)"
                      border="1px solid"
                      borderColor="#456181"
                      mt="1.5rem"
                    >                          
                      {child?.children?.map((subChild, j) => (
                        <Box
                          py="1.2rem"
                          px="1rem"
                          borderBottom={j === child?.children?.length - 1 ? "none" : "1px solid"}
                          borderColor="#456181"
                          key={subChild.id}
                        >
                          <GetLink
                            url={subChild.url}
                            type="nav"
                            onClick={() => closeMenu()}                            
                          >
                            <Text
                              opacity={"0.8"}
                              _hover={{
                                opacity: "1",
                              }}
                              transition="all 0.3s ease-in-out"
                            >
                              {subChild.label}
                            </Text>
                          </GetLink>                          
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </GetLink>
          </Flex>
        ))}        
      </Box>}
    </>
  );
};