import { appConstants } from "redux/constants"

const initState = {    
  albumInfo: {},
  entranceExamInfo: {},
  entranceExamSyllabus: {},
  examForms: [],
  examFormInfo: {},
  examSchedule: {},  
  error: null,
  events: {}, 
  eventInfo: null, 
  gallery: {},
  loading: false,  
  message: null,  
  nepSyllabus: {},
  news: {}, 
  newsInfo: {},  
  recruitments: {},
  careerInfo: null,
  resultInfo: {},
  sportsActivity: {},
  media: {},
  vocSyllabus: {},
  affiliations: {},
  professionalSyllabus: {},
  fundingAgencies: {},
  jobPortals: {},
  rAndD: {},
  aboutPage: {},
  facultyPage: null
}

export const appReducer = (state = initState, action) => {
  switch(action.type){
    case appConstants.GET_EXAM_FORMS_REQUEST:
    case appConstants.GET_EXAM_FORM_DETAILS_REQUEST:
    case appConstants.GET_EXAM_SCHEDULE_REQUEST:
    case appConstants.GET_ENTRANCE_EXAM_INFO_REQUEST:
    case appConstants.GET_ENTRANCE_SYLLABUS_REQUEST:
    case appConstants.GET_ALBUM_INFO_REQUEST:
    case appConstants.GET_ALBUMS_REQUEST:
    case appConstants.GET_NEP_SYLLABUS_REQUEST:
    case appConstants.GET_SPORTS_ACTIVITY_REQUEST:
    case appConstants.GET_RECRUITMENTS_REQUEST:
    case appConstants.GET_NEWS_REQUEST:
    case appConstants.GET_EVENTS_REQUEST:
    case appConstants.GET_NEWS_DETAILS_REQUEST:       
    case appConstants.GET_RESULTS_REQUEST:
    case appConstants.GET_MEDIA_REQUEST:
    case appConstants.SEND_CONTACT_US_REQUEST:
    case appConstants.GET_VOC_SYLLABUS_REQUEST:
    case appConstants.GET_AFFILIAION_REQUEST:
    case appConstants.GET_PROFESSIONAL_SYLLABUS_REQUEST:
    case appConstants.GET_FUNDING_AGENCIES_REQUEST:
    case appConstants.GET_JOB_PORTALS_REQUEST:
    case appConstants.GET_R_AND_D_REQUEST:
    case appConstants.GET_ABOUT_PAGE_REQUEST:
    case appConstants.GET_EVENT_REQUEST:
    case appConstants.GET_CAREER_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case appConstants.GET_FACULTY_PAGE_REQUEST:
      return {
        ...state,
        facultyPage: null,
        loading: true,
      }

    case appConstants.GET_EXAM_FORMS_SUCCESS:
      return {
        ...state,
        examForms: action.payload,
        loading: false,
      }

    case appConstants.GET_EXAM_FORM_DETAILS_SUCCESS:      
      return {
        ...state,        
        examFormInfo: action.payload,
        loading: false,
      }

    case appConstants.GET_EXAM_SCHEDULE_SUCCESS:    
      return {
        ...state,        
        examSchedule: action.payload,
        loading: false,
      }

    case appConstants.GET_ENTRANCE_EXAM_INFO_SUCCESS:
      return {
        ...state,      
        entranceExamInfo: action.payload,
        loading: false,
      }

    case appConstants.GET_ENTRANCE_SYLLABUS_SUCCESS:    
      return {
        ...state,        
        entranceExamSyllabus: action.payload,
        loading: false,
      }

    case appConstants.GET_ALBUM_INFO_SUCCESS:
      return {
        ...state,       
        albumInfo: action.payload,
        loading: false,
      }

    case appConstants.GET_ALBUMS_SUCCESS:
      return {
        ...state,
        gallery: action.payload,
        loading: false,
      }  
      
    case appConstants.GET_NEP_SYLLABUS_SUCCESS:
      return {
        ...state,
        nepSyllabus: action.payload,
        loading: false,
      }

    case appConstants.GET_SPORTS_ACTIVITY_SUCCESS:
      return {
        ...state,
        sportsActivity: action.payload,
        loading: false,
      }

    case appConstants.GET_RECRUITMENTS_SUCCESS:
      return {
        ...state,
        recruitments: action.payload,
        loading: false,
      }

    case appConstants.GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
        loading: false,
      }

    case appConstants.GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        loading: false,
      }

    case appConstants.GET_NEWS_DETAILS_SUCCESS:
      return {
        ...state,
        newsInfo: action.payload,
        loading: false,
      }
  
    case appConstants.GET_RESULTS_SUCCESS:
      return {
        ...state,
        resultInfo: action.payload,
        loading: false,
      }
    
    case appConstants.GET_MEDIA_SUCCESS:
      return {
        ...state,
        media: action.payload,
        loading: false,
      }   
      
    case appConstants.SEND_CONTACT_US_SUCCESS:
      return {
        ...state,
        message: action.payload?.message,
        loading: false,
      }

    case appConstants.GET_VOC_SYLLABUS_SUCCESS:
      return {
        ...state,
        vocSyllabus: action.payload,
        loading: false,
      }

    case appConstants.GET_AFFILIAION_SUCCESS:
      return {
        ...state,
        affiliations: action.payload,
        loading: false,
      }

    case appConstants.GET_PROFESSIONAL_SYLLABUS_SUCCESS:
      return {
        ...state,
        professionalSyllabus: action.payload,
        loading: false,
      }

    case appConstants.GET_FUNDING_AGENCIES_SUCCESS:
      return {
        ...state,
        fundingAgencies: action.payload,
        loading: false,
      }

    case appConstants.GET_JOB_PORTALS_SUCCESS:
      return {
        ...state,
        jobPortals: action.payload,
        loading: false,
      }

    case appConstants.GET_R_AND_D_SUCCESS:
      return {
        ...state,
        rAndD: action.payload,
        loading: false,
      }

    case appConstants.GET_ABOUT_PAGE_SUCCESS:
      return {
        ...state,
        aboutPage: action.payload?.data[0]?.attributes || {},
        loading: false,
      }

    case appConstants.GET_EVENT_SUCCESS:
      return {
        ...state,
        eventInfo: action.payload,
        loading: false,
      }

    case appConstants.GET_CAREER_SUCCESS:
      return {
        ...state,
        careerInfo: action.payload,
        loading: false,
      }

    case appConstants.GET_FACULTY_PAGE_SUCCESS:
      return {
        ...state,
        facultyPage: action.payload,
        loading: false,
      }

    case appConstants.GET_EXAM_FORMS_FAILURE:
    case appConstants.GET_EXAM_FORM_DETAILS_FAILURE:
    case appConstants.GET_EXAM_SCHEDULE_FAILURE:
    case appConstants.GET_ENTRANCE_EXAM_INFO_FAILURE:
    case appConstants.GET_ENTRANCE_SYLLABUS_FAILURE:
    case appConstants.GET_ALBUM_INFO_FAILURE:
    case appConstants.GET_ALBUMS_FAILURE:
    case appConstants.GET_NEP_SYLLABUS_FAILURE:
    case appConstants.GET_SPORTS_ACTIVITY_FAILURE:
    case appConstants.GET_RECRUITMENTS_FAILURE:
    case appConstants.GET_NEWS_FAILURE:
    case appConstants.GET_EVENTS_FAILURE:
    case appConstants.GET_NEWS_DETAILS_FAILURE:      
    case appConstants.GET_RESULTS_FAILURE:
    case appConstants.GET_MEDIA_FAILURE:
    case appConstants.SEND_CONTACT_US_FAILURE:
    case appConstants.GET_VOC_SYLLABUS_FAILURE:
    case appConstants.GET_AFFILIAION_FAILURE:
    case appConstants.GET_PROFESSIONAL_SYLLABUS_FAILURE:
    case appConstants.GET_FUNDING_AGENCIES_FAILURE:
    case appConstants.GET_JOB_PORTALS_FAILURE:
    case appConstants.GET_R_AND_D_FAILURE:
    case appConstants.GET_ABOUT_PAGE_FAILURE:
    case appConstants.GET_EVENT_FAILURE:
    case appConstants.GET_CAREER_FAILURE:
    case appConstants.GET_FACULTY_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }      

    case appConstants.RESET_APP:
      return {
        ...state,
        error: null,
        message: null,
        loading: false,
      }

    case appConstants.RESET:
      return {
        ...initState,
      }

    default: return state;
  }  
}