import React from "react";
import { useSelector } from "react-redux";

import { Box } from "atoms";
import { Footer, VocHeader } from "organisms";

export const VocLayout = ({children}) => {

  const { vocHomePage, footer, socials } = useSelector(state => state.data);

  return (
    <>
      <Box py="1.2rem" px="1.5rem" color="white" fontSize="1.4rem" fontWeight="600" bg="primary.200" textAlign="center">
        {vocHomePage?.message}
      </Box>
      <VocHeader
        data={vocHomePage?.header}
      />
      {children}
      <Footer
        footer={footer}        
        socials={socials}
      />
    </>
  )
}