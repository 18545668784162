import { GetLink } from "molecules/GetLink";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { getStrapiMediaUrl } from "utils/utilities";

export const VocImageSlider = ({
  data
}) => {
  return (
    <Carousel
      autoFocus={true}
      autoPlay
      centerMode            
      infiniteLoop
      className="vocImageSlider"
      showThumbs={false}
      stopOnHover={false}
      showStatus={false}
      interval={4000}
      showIndicators={false}      
    >
      {data?.map((item, index)=>(
        <GetLink
          key={item?.id}
          url={item?.url || "#"}
        >
          <img
            src={getStrapiMediaUrl(item?.image?.url)}
            alt={`Vocational Slider ${index}`}
          />
        </GetLink>
      ))}
    </Carousel>
  )
}