import React from "react";

import { Box, Grid, SectionTitle } from "atoms";
import { RecruitmentCard } from "molecules";

export const HomeRecruitment = ({data}) => {
  return (
    <>
      <Box maxWidth="100vw" p="3rem" bg="grey.50">
        <SectionTitle
          title="Recruitments"
          href="/career"
        />
        <Grid
          my="3rem"
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}
          gridColumnGap="3rem"
          gridRowGap="3rem"
        >
          {data?.map((item) => (
            <RecruitmentCard
              key={item?.id}
              data={item}
            />
          ))}          
        </Grid>
      </Box>
    </>
  )
}