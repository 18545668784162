import React, { useState } from "react";
import { AiOutlineFullscreen } from "react-icons/ai";
import { Lightbox } from "react-modal-image";

import { ImageWrapper } from "./Image.styles";
import { Flex } from "atoms";

export const Image = React.memo(({ src, alt, objectFit, borderRadius, preview=false, ...rest }) => {

  const [ imgPreview, setImgPreview ] = useState(false);

  return(
    <>
      <ImageWrapper {...rest}>
        {preview && <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          width="3.6rem"
          height="3.6rem"
          top="1rem"
          right="1rem"
          borderRadius="50%"
          bg="rgba(255,255,255,0.8)"
          boxShadow="0 0 0.5rem rgba(0,0,0,0.5)"
          cursor="pointer"
          onClick={() => setImgPreview(true)}
          fontSize="2.4rem"
          color="primary.100"
          zIndex="1"
        >
          <AiOutlineFullscreen />          
        </Flex>}        
        <img src={src} alt={alt} width="100%" height="100%" style={{objectFit: objectFit || "unset", borderRadius: borderRadius || "unset"}} />
      </ImageWrapper>
      {imgPreview && <Lightbox
        small={src}
        medium={src}
        large={src}
        alt={alt}
        onClose={() => setImgPreview(false)}        
      />}      
    </>
  )
});
