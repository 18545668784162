import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Text } from "atoms";
import { Layout } from "organisms";
import { getAlbumInfo } from "redux/actions";
import { GalleryInnerPage } from "templates";
import { scrollToTop } from "utils/utilities";

const AlbumImages = () => {
  
  const { albumId } = useParams()

  const dispatch = useDispatch()
  const { loading, albumInfo } = useSelector(state => state.app);  

  useEffect(() => {
    scrollToTop(window);
    if(albumId !== albumInfo?.id) dispatch(getAlbumInfo(albumId));          
  }, [albumId, dispatch, albumInfo?.id]);  

  return (
    <>
      <Layout>
        {!loading && albumInfo?.id !== albumId ? <Text m="2rem" textAlign="center">Album Not Found</Text> : <GalleryInnerPage data={albumInfo}/>}        
      </Layout>
    </>
  )
}

export default AlbumImages