import React from "react";

import { Box, Flex, Text } from "atoms";

export const PageTitle = ({icon, title}) => {
  return (
    <Flex
      width="100%"
      bg="white"
      borderBottom="1px solid rgba(0, 0, 0, 0.08)"
      alignItems="center"
      justifyContent="center" 
      py="1rem"
      px="1.5rem"
    >
      <Box color="accent.300" mt="0.3rem">
        {icon}
      </Box>
      <Text
        fontSize="2rem"
        fontWeight="600"
        ml="0.8rem"
        textAlign="center"
      >
        {title}
      </Text>
    </Flex>
  )
}