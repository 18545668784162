import React from "react";

import { Box, Text } from "atoms";

export const SectionTitle = ({
  title
}) => {
  return (
    <Box mb="3rem">
      <Text
        color="primary.200"
        fontWeight="700"
        fontSize="2.4rem"
        lineHeight="4rem"
        borderBottom="3px solid"
        borderColor="primary.100"
      >
        {title}
      </Text>
    </Box>
  )
}