import React, { useEffect, useState } from "react";
import { MdOutlineDashboard } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { PageHead, RecruitmentCard } from "molecules";
import { getRecruitments } from "redux/actions";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";

export const CareerPage = () => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");

  const dispatch = useDispatch()
  const { recruitments } = useSelector(state => state.app); 

  useEffect(()=>{
    dispatch(getRecruitments(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);  

  return (
    <>      
      <SEO
        title={`Recruitments || Soban Singh Jeena University Almora`}
        description={`Recruitments || Soban Singh Jeena University Almora`}
        keywords={`Recruitments SSJU,Recruitments Soban Singh Jeena University Almora,Recruitments Soban Singh Jeena University,Recruitments Uttarakhand University,Recruitments SSJ University,Recruitments SSJU`}
      />        
      <PageHead
        Icon={<MdOutlineDashboard size="2.5rem" />}
        title="Careers At SSJU"
        query={query}
        setQuery={setQuery}
        page={page}
        setPage={setPage}
        resultPerPage={recruitments?.resultPerPage}
        totalCount={recruitments?.totalCount}        
      >
        <Grid
          my="3rem"
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}
          gridColumnGap="3rem"
          gridRowGap="3rem"        
        >
          {recruitments?.recuritment?.map((item) => (
            <RecruitmentCard
              key={item?.id}
              data={item}
            />
          ))}          
        </Grid> 
      </PageHead>           
    </>
  )
}