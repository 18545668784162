import React, { useEffect, useState } from "react";
import { BsJournalBookmark } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { PageHead, PdfCard } from "molecules";
import { getNEPSyllabus } from "redux/actions";
import SEO from "seo/SEO";
import { formatDate, scrollToTop } from "utils/utilities";

export const NEPSyllabusPage = () => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");

  const dispatch = useDispatch()
  const { nepSyllabus } = useSelector(state => state.app);  

  useEffect(()=>{
    dispatch(getNEPSyllabus(page, query))
  }, [page, query, dispatch]);
  
  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);    

  return (
    <>
      <SEO
        title="NEP Syllabus || Soban Singh Jeena University Almora"
        description="National Education Policy Syllabus || Soban Singh Jeena University Almora"
        keywords="National Education Policy Syllabus SSJU,National Education Policy Syllabus Soban Singh Jeena University Almora,National Education Policy Syllabus Soban Singh Jeena University,National Education Policy Syllabus Uttarakhand University,National Education Policy Syllabus SSJ University,National Education Policy Syllabus SSJU"        
      />          
      <PageHead
        Icon={<BsJournalBookmark size="2.5rem"/>}
        title="NEP Syllabus"
        query={query}
        setQuery={setQuery}
        page={page}
        setPage={setPage}
        resultPerPage={nepSyllabus?.resultPerPage}
        totalCount={nepSyllabus?.totalCount}        
      >
        <Box
          width="fit-content"
          mx="auto"
        >       
          <Grid
            gridTemplateColumns="1fr"
            gridRowGap="1.5rem"      
            my="2rem"      
            px="1rem"
          >
            {nepSyllabus?.syllabus?.map((item, index) => (
              <PdfCard
                key={index}
                title={item.title}
                href={item.href}
                addedOn={formatDate(item.createdAt)}
              />
            ))}             
          </Grid>
        </Box>
      </PageHead>     
    </>
  )
}