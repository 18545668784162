import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Image } from "atoms";
import { GetLink } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const HomeImageSlider = ({
  slides
}) => {

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,    
    centerMode: true,
    centerPadding: "25px",    
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear"
  };

  return (
    <>
      <Box
        minWidth="100vw"
        maxWidth="100vw"        
        height="fit-content"
        className="home-image-slider hide-scrollbar"        
        overflowY="hidden"                
      >
        <Slider {...settings}>
          {slides?.map((slide, index) => (
            <Box
              key={index}
              width="95vw"                
              height="100%"
              borderRadius="0.4rem"              
            >
              <GetLink
                url={slide?.href ? slide?.href : "#"}
              >
                <Image
                  src={getStrapiMediaUrl(slide?.image?.url)}
                  alt={"SSJU IMAGE SLIDER"}
                  width="100%"
                  height="100%"                        
                  borderRadius="4px"                    
                />
              </GetLink>              
            </Box>
          ))}
        </Slider>
      </Box>
    </>
  )
}