import React from "react";

import { Box, Button, Grid, Text } from "atoms";
import { GetLink } from "molecules";
import { BtnColors } from "utils/constant-data";
import { formatDate, generatePublicUrl } from "utils/utilities";

export const EventCard = ({event}) => {
  return (
    <Grid
      gridTemplateColumns="0.2fr 1fr"
      gridColumnGap="1.2rem"
    >
      <Box
        bg="primary.150"
        color="white"
        p="1rem"
        fontSize="1.5rem" 
        fontWeight="600"
        height="100%"
      >
        {formatDate(event?.createdAt)}
      </Box>
      <Box>
        <GetLink url={`/events/${event?.id}`}>
          <Text
            fontSize="1.6rem" 
            fontWeight="600"
            opacity="0.8"
            _hover={{
              color: "accent.200"
            }}
            transition="color 0.3s"
          >            
            {event?.title?.slice(0, 200)}{" "}{event?.title?.length > 200 && "..."}
          </Text>
        </GetLink>
        <Text mt="0.6rem" fontSize="1.4rem" fontWeight="500" color="grey.300" className="text-justify">
          {event?.desc.slice(0, 150)}{" "}{event?.desc?.length > 150 && "..."}          
        </Text>
        {event?.eventSrc?.map((src, index) => (
          <GetLink 
            url={generatePublicUrl(src?.href)}
            key={src?.id}
          >
            <Button
              variant={BtnColors[index]}
              width="fit-content"
              py="1rem"
              px="1.2rem"        
              mt="0.8rem"      
            >
              <Text
                fontSize="1.2rem"
                fontWeight="400"
              >
                {src?.title}
              </Text>
            </Button>
          </GetLink>
        ))}                    
      </Box>
    </Grid>
  )
}