import React from "react";

import { Box, Grid } from "atoms";
import { NewsMarquee } from "molecules";

export const HomeNewsMarquee = ({mq1, mq2, mq3}) => {
  return (
    <>
      <Box maxWidth="100vw" px="3rem" py="3rem" bg="white">
        <Grid
          my="2rem"
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg: "repeat(3, 1fr)"}}
          gridColumnGap="3rem"
          gridRowGap="3rem"
        >          
          <NewsMarquee              
            id={"recent-news"}
            title="Recent News"
            data={mq1}
          />           
          <NewsMarquee              
            id={"announcements"}
            title="University News"
            data={mq2}
          />           
          <NewsMarquee              
            id={"tenders"}
            title="Tenders"
            data={mq3}
          />           
        </Grid>       
      </Box>
    </>
  )
}