import React, { useEffect, useState } from "react";
import { IoNewspaperOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { PageHead, PdfCard } from "molecules";
import { getAffiliations } from "redux/actions";
import { formatDate, getStrapiMediaUrl, scrollToTop } from "utils/utilities";

export const AffiliationPage = () => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");

  const dispatch = useDispatch()
  const { affiliations } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getAffiliations(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);  

  return (
    <PageHead
      Icon={<IoNewspaperOutline size="2.5rem"/>}
      title="Affiliation Page"
      query={query}
      setQuery={setQuery}
      page={page}
      setPage={setPage}
      resultPerPage={affiliations?.meta?.pagination?.total}
      totalCount={affiliations?.meta?.pagination?.pageSize}        
    >
      <Box
        width="fit-content"
        mx="auto"
      >        
        <Grid
          gridTemplateColumns="1fr"
          gridRowGap="1.5rem"      
          my="4rem"      
          px="3rem"
        >
          {affiliations?.data?.map((item) => (
            <Box key={item.id}>
              <PdfCard                
                title={item?.attributes?.label}
                href={getStrapiMediaUrl(item?.attributes?.file?.data?.attributes?.url)}
                addedOn={formatDate(item?.attributes?.createdAt)}
              />
            </Box>            
          ))}         
        </Grid>
      </Box>
    </PageHead>      
  )
}