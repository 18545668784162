import React, { useState } from "react";
import { FiLink2, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useTransition, animated } from "react-spring";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { generatePublicUrl } from "utils/utilities";

export const ImportantLinkCard = ({data}) => {

  const [ expanded, setExpanded ] = useState(false);
  const transitions = useTransition(expanded ? data?.importantLinkUrl : data?.importantLinkUrl.slice(0, 5), {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
  });

  return (
    <Box
      width="100%"
      py="2.5rem"
      px="1.5rem"      
      bg="white"
      borderRadius="0.8rem"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
      border="1px solid rgba(0, 0, 0, 0.1)"            
    >
      <Flex
        flexDirection="column"        
      >
        <Flex alignItems="center" justifyContent="center" mb="3rem">
          <Box
            width="3rem"
            height="3rem"
            mr="1rem"
          >
            <Image
              src={generatePublicUrl(data?.image)}
              alt={data.title}
              width="100%"
              height="100%"
            />
          </Box>
          <Text fontSize="1.6rem" fontWeight="600">{data.title}</Text>
        </Flex>
        {transitions((styles, link) => (
          <animated.div style={styles}>
            <LinkCard
              label={link?.title}
              url={generatePublicUrl(link?.href)}
              newLink={link?.new}
            />
          </animated.div>
        ))}
        {data?.importantLinkUrl.length > 5 && <Flex 
          justifyContent="center" 
          onClick={() => setExpanded(!expanded)}
          cursor="pointer"          
          _hover={{transform: "scale(1.15)"}}
          transition="transform 0.3s"          
        >
          {expanded ? <FiChevronUp size="2rem" /> : <FiChevronDown size="2rem" />}
        </Flex>}
      </Flex>
    </Box>
  )
}

const LinkCard = ({
  label,
  url,
  newLink
}) => {
  return(
    <Flex mb="1.4rem" justifyContent="space-between" alignItems="start">
      <GetLink
        url={url}
      >
          <Grid
            gridTemplateColumns="2rem 1fr"
            gridColumnGap="1rem"                 
          >
            <Box color={newLink ? "danger.100" : "accent.100"}>
              <FiLink2 size="1.8rem"/>
            </Box>
            <Text fontSize="1.4rem" mr="2.5rem" fontWeight={newLink ? "600" : "500"} color={newLink ? "danger.100" : "grey.400"} _hover={{fontWeight: "600"}} transition="color 0.3s">
              {label}
            </Text>
          </Grid>
      </GetLink>            
      {newLink &&  <Box ml="2rem" width="2.5rem" height="2.5rem" position="absolute" top="0" right="0">
        <Image
          src="https://ssju.ac.in/assets/images/new.png"
          alt="new-gif"
          width="100%"
          height="100%"
        />
      </Box>}                       
    </Flex>
  )
}