import React, { useEffect } from "react";
import { GrResources } from "react-icons/gr";

import { PageTitle } from "atoms";
import { Layout } from "organisms";
import { KnowledgeResourcePage } from "templates";
import { scrollToTop } from "utils/utilities";

const KnowledgeResources = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <Layout>      
        <PageTitle
          icon={<GrResources size="2.5rem" />}
          title="Knowledge Resources"
        />
        <KnowledgeResourcePage/>
      </Layout>
    </>
  )
}

export default KnowledgeResources