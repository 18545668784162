import React, { useState } from "react";

import { Box, Grid, Image, Text } from "atoms";
import { getStrapiMediaUrl } from "utils/utilities";

export const HomeImageCards = ({
  cards
}) => {  
  
  const [selectedId, setSelectedId] = useState(""); 

  return (
    <>
      <Box        
        py={{xs: "2rem", md: "3rem"}}
      >
        <Grid
          gridTemplateColumns={`repeat(${cards?.length}, 28rem)`}
          overflowX="auto"
          overflowY="hidden"
          gridRow="auto"
          gridColumnGap="3rem"
          className="hide-scrollbar"
          justifyContent={{lg: "center"}}
          px={{xs: "3rem", lg: "unset"}}           
        >
          {cards?.map((item) => (
            <Box 
              width={{xm: "28rem"}} 
              height="30rem" 
              key={item.id} 
              borderRadius="0.6rem"
              onMouseOver={() => {                  
                setSelectedId(item?.id);
              }}
              onMouseLeave={() => {                  
                setSelectedId("");
              }}
            >
              <Image
                src={getStrapiMediaUrl(item.avatar?.url)}
                alt={item?.name + "-" + item?.designation}
                width="100%"
                height="100%"
                borderRadius="0.6rem"
                objectFit="cover"
              />
              <Box
                position="absolute" 
                display={selectedId === item?.id ? "block" : "none"}                 
                color="white"                                    
                textAlign="center"
                top="18rem"                  
                width="100%"
                height="12rem"
                bg="rgba(0, 0, 0, 0.6)"
                py="2rem"
                borderBottomLeftRadius="0.6rem"
                borderBottomRightRadius="0.6rem"                  
              >
                <Text
                  fontSize="1.8rem"
                  fontWeight="600"
                  mb="1rem"
                >
                  {item?.name}
                </Text>
                <Text
                  fontSize="1.6rem"
                  fontWeight="400"
                  opacity="0.8"
                >
                  {item?.designation}
                </Text>
              </Box>
            </Box> 
          ))}          
        </Grid>
      </Box>
    </>
  )
}