import React from "react";

import { Box, Text } from "atoms";

export const VocPageTitle = ({title}) => {
  return (
    <Box
      width="100%"
      textAlign="center"
      p="3rem 2rem"
    >
      <Text
        fontSize="2.5rem"
        fontWeight="600"
        color="primary.400"
        textTransform="uppercase"
        letterSpacing="0.14rem"
      >
        {title}
      </Text>
      <Box width="10%" mx="auto" height="0.3rem" bg="grey.100" mt="2rem" />
    </Box>
  )
}