import React from "react";

import { Box } from "atoms";
import SEO from "seo/SEO";
import { ADMCertificateCourse, ADMDiplomaCourse, ADMSelfCourse } from "utils/constant-data";

import { AdmissionForm } from "./molecules";

export const AdmissionInfoPage = ({courseType}) => {

  const course = courseType === "ug" ? "UG" : courseType === "pg" ? "PG" : courseType === "bvoc" ? "B.VOC." : courseType === "self-finance" ? "Self Finance" : courseType === "certificate" ? "Certificate" : courseType === "diploma" && "Diploma"
  const courseDesc = courseType === "ug" ? "Under Graduate" : courseType === "pg" ? "Post Graduate" : courseType === "bvoc" ? "B. Vocational" : courseType === "self-finance" ? "Self Finance" : courseType === "certificate" ? "Certificate" : courseType === "diploma" && "Diploma"

  return (
    <>
      <SEO
        title={`${course} Courses Admission || Soban Singh Jeena University Almora`}
        description={`${courseDesc} Courses Admission || Soban Singh Jeena University Almora`}
        keywords={`${course} Courses Admission SSJU,${course} Courses Admission Soban Singh Jeena University Almora,${course} Courses Admission Soban Singh Jeena University,${course} Courses Admission Uttarakhand University,${course} Courses Admission SSJ University,${course} Courses Admission SSJU, ${course} Courses Admission SSJ University,${courseDesc} Courses Admission Soban Singh Jeena University Almora,${courseDesc} Courses Admission Soban Singh Jeena University,${courseDesc} Courses Admission Uttarakhand University,${courseDesc} Courses Admission SSJ University,${courseDesc} Courses Admission SSJU`}
      />
      <Box overflow="hidden" py="3rem" px="3rem" maxWidth="100vw">
        {courseType === "ug" && <AdmissionForm
          startDate="21-06-2022"
          endDate="16-08-2022"
          applyLink="https://www.ssju.in/ERP/index.php/Register/studentregister_ug"
          courseType={courseType}
        />}        
        {courseType === "pg" && <AdmissionForm
          startDate="07-07-2022"
          endDate="Declaring Soon"
          applyLink="https://www.ssju.in/ERP/index.php/Register/pgstudentregister_pg"
          courseType={courseType}
          //courses={ADMPGCourses}
        />}
        {courseType === "bvoc" && <AdmissionForm
          startDate="11-07-2021"
          endDate="Declaring Soon"
          applyLink="https://www.ssju.in/ERP/index.php/Register/studentregister_voc"
          courseType={courseType}
          //courses={ADMBVocCourse}
          bVoc
        />}        
        {courseType === "self-finance" && <AdmissionForm
          startDate="07-07-2022"
          endDate="Declaring Soon"
          applyLink="https://www.ssju.in/ERP/index.php/Register/studentregister_self"
          courseType={courseType}
          courses={ADMSelfCourse}
        />}
        {courseType === "certificate" &&  <AdmissionForm
          startDate="14-08-2021"
          endDate="23-08-2021"
          applyLink="https://www.ssju.in/ERP/index.php/register"
          courseType={courseType}
          courses={ADMCertificateCourse}
        />}
        {courseType === "diploma" &&  <AdmissionForm
          startDate="14-08-2021"
          endDate="23-08-2021"
          applyLink="https://www.ssju.in/ERP/index.php/register"
          courseType={courseType}
          courses={ADMDiplomaCourse}
        />}
      </Box>
    </>
  )
}