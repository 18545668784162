import React from "react";

import { Box, Flex, Text } from "atoms";
import { generatePublicUrl } from "utils/utilities";
import { GetLink } from "molecules";

export const GalleryCard = ({data}) => {
  return (
    <GetLink url={`/${data?.id}/images`}>     
      <Flex 
        minWidth="100%" 
        backgroundImage={`url(${generatePublicUrl(data?.thumbnail)})`}
        alignItems="flex-end"
        justifyContent="flex-end"
        height="45rem"
        borderRadius="0.4rem"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="top center" 
        boxShadow="inset 0px -120px 84px 1px rgba(0,0,0,0.69)"
        transition="all 0.5s 0.1s ease-out"
      >
        <Box p="2rem" pb="3rem" mx="auto">
          <Text color="white" textAlign="center">
            {data?.title}
          </Text>
          <Text color="grey.200" textAlign="center" mt="2rem">
            {data?.galleryImages?.length} Images
          </Text>
        </Box>
      </Flex>      
    </GetLink>
  )
}