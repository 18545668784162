import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, Image, Text } from "atoms";
import { LinkCard } from "molecules";
import { getAboutPage } from "redux/actions";
import SEO from "seo/SEO";
import { getStrapiMediaUrl } from "utils/utilities";
import { SectionCards, SectionImages, SectionList, SectionMembers, SectionMessage, SectionRichText, SectionTable, SectionText, SectionTitle } from "./molecules";

export const AboutTypePage = () => {

  const { aboutType } = useParams();
  const dispatch = useDispatch();
  const { aboutPage, loading } = useSelector(state => state.app);

  useEffect(() => {
    dispatch(getAboutPage(aboutType));
  }, [aboutType, dispatch]); 

  return (
    <>
      {!loading && aboutPage?.slug !== aboutType ? <Text m="2rem" textAlign="center">
        404 Page Not Found
      </Text> : <>
        {aboutPage?.banner?.data && <Box
          width="100%"
          height="auto"
        >
          <Image
            src={getStrapiMediaUrl(aboutPage?.banner?.data?.attributes?.url)}
            alt="Banner Image"
            width="100%"  
            height="auto"
            objectFit="cover"            
          />
        </Box>}
        <Box
          maxWidth={{xm: "102rem"}}
          mx="auto"
          px="2rem"
          py="4rem"
        >
          {aboutPage?.seo?.length > 0 && <SEO
            title={aboutPage?.seo[0]?.title}
            description={aboutPage?.seo[0]?.description}
            keywords={aboutPage?.seo[0]?.keywords}
            image={getStrapiMediaUrl(aboutPage?.seo[0]?.image?.data?.attributes?.url)}
          />}
          {aboutPage?.data?.map((data, index) => {
            switch (data?.__component) {
              case "section.section-text":
                return <SectionText
                  key={index}
                  title={data?.title}
                  description={data?.description}
                />

              case "section.section-cards":
                return <SectionCards
                  title={data?.title}
                  cards={data?.data}
                />

              case "section.section-message":
                return <SectionMessage
                  key={index}
                  title={data?.title}
                  profile={data?.profile}
                  titleEnglish={data?.message?.title?.split("<br/>")[0]}
                  titleHindi={data?.message?.title?.split("<br/>")[1]}
                  messageEnglish={data?.message?.messageEnglish}
                  messageHindi={data?.message?.messageHindi}
                  name={data?.message?.name}
                  designation={data?.message?.designation}
                  image={data?.message?.image?.data && getStrapiMediaUrl(data?.message?.image?.data?.attributes?.url)}
                />

              case "section.section-title":
                return <SectionTitle
                  key={index}
                  title={data?.title}                
                />

              case "section.section-rich-text":
                return <SectionRichText
                  key={index}
                  title={data?.title}
                  description={data?.description}
                />

              case "section.section-table":
                return <SectionTable
                  key={index}
                  title={data?.title}
                  data={data?.data}
                />

              case "section.section-list":
                return <SectionList
                  key={index}
                  title={data?.title}
                  data={data?.data}
                />

              case "section.section-images":
                return <SectionImages
                  key={index}
                  title={data?.title}
                  images={data?.images?.data || []}
                />

              case "section.section-members":
                return <SectionMembers
                  key={index}
                  title={data?.title}
                  data={data?.data}
                />

              case "common.link":
                return <LinkCard
                  key={data?.id}
                  title={data?.label}
                  url={data?.url}
                  bg="primary.100"
                  color="white"
                  width="fit-content"
                  borderRadius="0.4rem"
                />

              default:
                return null
            }
          })}
        </Box>
      </>}     
    </>
  )
}