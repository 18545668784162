import React, { useEffect, useState } from "react";
import { GiArchiveResearch } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { PageHead, ResearchCard } from "molecules";
import { getRAndD } from "redux/actions";
import { getStrapiMediaUrl, scrollToTop } from "utils/utilities";

export const RAndDPage = () => {

  const [ query, setQuery ] = useState("");
  const [ page, setPage ] = useState(1);

  const dispatch = useDispatch();
  const { rAndD } = useSelector(state => state.app);

  useEffect(() => {
    dispatch(getRAndD(page, 12, query));
  }, [query, page, dispatch]);

  useEffect(() => {
    scrollToTop(window)
  }, [page]);

  return (
    <>
      <PageHead
        Icon={<GiArchiveResearch size="2.5rem"/>}        
        title="Research & Development"
        query={query}
        setQuery={setQuery}
        page={page}
        setPage={setPage}
        resultPerPage={rAndD?.meta?.pagination?.pageSize}
        totalCount={rAndD?.meta?.pagination?.total}
      >
        <Grid
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}
          gridColumnGap="2.4rem"
          gridRowGap="2.4rem"          
          my="3rem"
          maxWidth="120rem"              
          mx="auto"
        >
          {rAndD?.data?.map((item)=>(
            <ResearchCard
              key={item?.id}
              image={getStrapiMediaUrl(item?.attributes?.image?.data?.attributes?.url)}
              title={item?.attributes?.name}
              url={item?.attributes?.url}
            />
          ))}          
        </Grid>       
      </PageHead>           
    </>
  )
}