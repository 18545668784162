import React from "react";

import { Box, Grid, SectionTitle } from "atoms";
import { EventCard, NewsCard } from "molecules";

export const HomeNews = ({news, events}) => {
  return (
    <>
      <Grid
        gridTemplateColumns={{lg: "1fr 0.4fr"}}
        py={{xs: "2rem", md: "3rem"}}
        bg="secondary.300"
        px={{xs: "1.5rem", md: "3rem"}}
      >
        <Box>
          <SectionTitle
            title="News"
            href="/news-events"
          />
          <Box my="3rem">
            <Grid
              gridTemplateColumns={{md: "1fr 1fr", xm: "1fr 1fr 1fr"}}
              gridRowGap="3rem"
              justifyContent={{xs: "center"}}
            >
              {news?.map((item) => (<NewsCard news={item} key={item?.id} />))}
            </Grid>            
          </Box>
        </Box>
        <Box>
          <SectionTitle
            title="Events"
            href="/news-events"
          />
          <Box my="3rem">
            <Grid
              gridTemplateColumns={{md: "1fr 1fr", lg: "1fr"}}
              gridColumnGap="2rem"
              gridRowGap="3rem"
              justifyContent={{xs: "center"}}
            >
              {events?.map((item) => (<EventCard event={item} key={item?.id} />))}
            </Grid>              
          </Box>
        </Box>
      </Grid>
    </>
  )
}