import React, { useEffect } from "react";

import { Layout } from "organisms";
import { ContactPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Contact = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <Layout>
        <ContactPage/>
      </Layout>
    </>
  )
}

export default Contact