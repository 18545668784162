import React from "react";
import { BiLinkExternal } from "react-icons/bi";

import { Box, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const ResearchCard = ({
  image,
  title,
  description,
  url,
  label
}) => {
  return (
    <Box
      height="100%"
      bg="white"
      boxShadow="0px 0px 10px 0px rgba(0,0,0,0.1)"
      border="1px solid #e0e0e0"
      borderRadius=".5rem" 
      overflow="hidden"     
    >
      {image && <Box
        width="100%"
        height="12rem"
        p="2rem"
        borderBottom="1px solid #e0e0e0"
      >
        <Image
          src={image}
          alt={title}
          width="100%"
          height="100%"         
          borderRadius=".5rem .5rem 0 0"
          objectFit="contain"
        />
      </Box>}  
      <Flex        
        flexDirection="column"
        justifyContent="space-between"        
        style={{
          gap: "1rem"
        }}
        height={image ? "calc(100% - 12rem)" : "100%"}
      >
        <Box
          p="1.5rem 2rem"          
        >
          <Text
            fontSize="1.5rem"
            fontWeight="600"
            color="grey.200"
            textAlign="center"
            _hover={{
              color: "grey.500"
            }}
            transition="color 0.3s"
            cursor="pointer"
          >
            {title}
          </Text>
          {description && <Box>
            {description?.split("<br/>").map((item, index) => (
              <Text
                key={index}
                fontSize="1.2rem"
                color="grey.300"
                textAlign="center"
                mt="0.8rem"
              >
                {item}
              </Text>
            ))}
          </Box>}
        </Box>
        {url && <GetLink
          url={url}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            p="1rem"   
            color="accent.100"
            _hover={{
              bg: "accent.200",
              color: "white"
            }}     
            transition="background 0.3s, color 0.3s"    
          >
            <Flex
              alignItems="center"
              justifyContent="center"      
              fontSize="2rem"
              mr="0.5rem"
            >
              <BiLinkExternal/>
            </Flex>
            <Text
              fontSize="1.5rem"
              fontWeight="600"              
            >
              {label || "Visit"}
            </Text>
          </Flex>
        </GetLink>}
      </Flex>    
    </Box>
  )
}