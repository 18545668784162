import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Flex, Image } from "atoms";
import { GetLink } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const KnowledgeResouceSlider = ({data}) => {

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,  
    autoplay: true,
    autoplaySpeed: 3000,  
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,          
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]    
  };

  return (
    <>
      <Box
        minWidth="90vw"
        mx="auto"
        maxWidth="90vw"  
        py="2rem"  
        mb="2rem"    
      >
        <Slider {...settings}>
          {data?.map((item, index) => (
            <GetLink url={item?.url} key={index}>
              <Flex
                maxWidth="90%"
                height={{xs: "5rem", md: "8rem"}}
              >
                <Image
                  src={getStrapiMediaUrl(item?.image?.url)}
                  alt={item?.name}
                  width="100%"
                  height="100%"
                  objectFit="contain"
                />
              </Flex>
            </GetLink>
          ))}          
        </Slider>
      </Box>
    </>
  )
}