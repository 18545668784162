import React from "react";
import { FaRegFilePdf } from "react-icons/fa";

import { Flex, Grid, Text } from "atoms";
import { GetLink } from "molecules";
import { generatePublicUrl } from "utils/utilities";

export const PdfCard = ({href, title, addedOn, bg="white"}) => {  
  return (
    <GetLink url={generatePublicUrl(href)}>
      <Grid
        width="100%"
        height="100%"
        bg={bg}
        alignItems="center"
        boxShadow="0px 0px 10px 0px rgba(0,0,0,0.1)"
        border="1px solid #e0e0e0"
        borderRadius="0.4rem"
        overflow="hidden"
        gridTemplateColumns="auto 1fr"
      >
        <Flex
          alignItems="center"
          justifyContent="center"          
          bg="danger.200"
          p="1.6rem"
          fontSize="2.4rem"
          color="white"
          width="fit-content"
          height="100%"
        >
          <FaRegFilePdf/>
        </Flex>
        <Text
          as="p"
          fontSize="1.5rem"
          fontWeight="600"
          color="#707070"
          _hover={{
            color: "grey.500"
          }}
          px="1rem"          
        >
          {title}
          <Text
            as="span"            
            fontSize="1.2rem"
            fontWeight="500"
            color="#707070"
            opacity="0.7"
            ml="1rem"
          >
            {addedOn}
          </Text>
        </Text>
      </Grid>     
    </GetLink>
  )
}