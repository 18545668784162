import { config } from "config";

export const isMobile = () => (typeof window !== "undefined" ? window.innerWidth <= 600 : false);
export const isTab = () => (typeof window !== "undefined" ? window.innerWidth <= 1024 : false);

export const formatDate = (date) => {
  let formattedDate;
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday"]
  if(typeof date === "object"){    
    formattedDate = `${dayNames[date?.toDate().getDay()]}, ${monthNames[date?.toDate().getMonth()]} ${date?.toDate().getDate()}, ${date?.toDate().getFullYear()}`
  }else if(typeof date === "string"){            
    const _date = new Date(date);
    formattedDate = `${dayNames[_date.getDay()]}, ${monthNames[_date.getMonth()]} ${_date.getDate()}, ${_date.getFullYear()}`    
  }
  return formattedDate
}

export const generatePublicUrl = (file) => {
  if(file?.includes("http") || file?.includes("https")) return file;
  if(file?.split("/").length > 1) return file;
  return config.MEDIA_URL + "/" + file
}

export const scrollToTop = (window) =>{
  window.scrollTo({top: 0, left: 0, behavior: "smooth" })
}    

export const printINR= (cost) => {
  return new Intl.NumberFormat("en-IN", {style: "currency", currency: "INR", maximumSignificantDigits: 3}).format(cost)
}

export const isoDateToHtmlInputDate= (date) => {
  let _date = new Date(date);
  return _date.toLocaleDateString("sv-SE");
}

export const htmlInputDateToIsoDate= (date) => {
  let _date = new Date(date);
  return _date.toISOString();
}

export const getStrapiMediaUrl = (file) => {
  return `${config.STRAPI_URL}${file}`;
}