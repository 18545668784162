import React, { useEffect } from "react";
import { MdOutlineRule } from "react-icons/md";

import { Box, PageTitle, Text, VocPageTitle } from "atoms";
import { Layout } from "organisms";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";

const TermsAndCondition = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title={`Terms & Conditions || Soban Singh Jeena University Almora`}
        description={`Terms & Conditions || Soban Singh Jeena University Almora`}
        keywords={`Terms & Conditions SSJU,Terms & Conditions Soban Singh Jeena University Almora,Terms & Conditions Soban Singh Jeena University,Terms & Conditions Uttarakhand University,Terms & Conditions SSJ University,Terms & Conditions SSJU`}
      />
      <Layout>
        <PageTitle
          icon={<MdOutlineRule size="2.5rem"/>}
          title="Terms And Conditions"
        />
        <Box maxWidth="100rem" mx="auto" px={{xs: "3rem", lg: "unset"}} color="grey.300">
          <Box my="4rem">
            <VocPageTitle
              title="TERMS AND CONDITIONS"
            />
            <Text textAlign="center">        
              We reserve the right in our sole discretion to edit or delete any document, information or other content appearing on the Site.
            </Text>      
          </Box>
          <Box my="4rem">
            <VocPageTitle
              title="USE OF INFORMATION"
            />
            <Text textAlign="center" mb="2rem">        
              We reserve the right and you authorize us to the use and assignment of all information regarding Site uses by you and all information provided by you in any manner consistent with Our Privacy Policy.
            </Text>      
            <Text textAlign="center">        
              All of the content and Information is brought to you by Soban Singh Jeena University, Almora, Uttarakhand. For more information feel free to Contact Us.
            </Text>      
          </Box>
          <Box my="4rem">
            <VocPageTitle
              title="DISCLAIMER"
            />
            <Text textAlign="center" mb="2rem">        
              The official website of Soban Singh Jeena University has been developed to provide information related to the University. The document send information displayed on the website are for reference purpose only and cannot be claimed to be a legal document. The information contained on the website is in sync on the inputs received from the University. Though all efforts have been made to ensure the accuracy of the content, the same should not be constructed as a statement of law or use of any legal Services.
            </Text>      
            <Text textAlign="center">        
              Links to other websites that have been included on this website are provided for public convenience only.Soban Singh Jeena University is not responsible for the content or reliability of linked websites and does not necessarily endorse the views expressed within them. We cannot guarantee the availability of such linked Pages of all times.
            </Text>      
          </Box>
        </Box>        
      </Layout>
    </>
  )
}

export default TermsAndCondition