import { dataConstants } from "redux/constants";
import Axios from "utils/axios";
import strapiAxios from "utils/strapiAxios";

export const getInitialData = () => {
  return async (dispatch) => {
    try{      
      dispatch({ type: dataConstants.GET_INITIAL_DATA_REQUEST })
      let URL = `/app/initial-data`;         
      let UUID = localStorage.getItem("ssju-visitor-id");      
      if(!UUID) URL += `?unique=true`;    
      if(!UUID){
        UUID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        localStorage.setItem("ssju-visitor-id", UUID);
      }      
      const { data } = await Axios.get(URL);                  
      const { data: strapiData } = await strapiAxios.get(`/custom/get-initial-data`);            
      dispatch({ 
        type: dataConstants.GET_INITIAL_DATA_SUCCESS,
        payload: {         
          apiData: data.data,
          popUp: strapiData?.homePage?.popUp,
          slider: strapiData?.homePage?.data?.find(item => item.__component === "common.image-slider")?.images,
          displayCards: strapiData?.homePage?.card,
          about: {
            ...strapiData?.homePage?.about,
            cards: strapiData?.homePage?.aboutCards
          },
          featured: strapiData?.homePage?.featured,
          horizontalMQ: strapiData?.homePage?.data?.find(item => item.__component === "common.horizonal-m-qs")?.data,
          headerBtn: strapiData?.initialData?.headerButtons,
          headerLogos: strapiData?.initialData?.headerLogos,
          navbar: strapiData?.initialData?.header,
          footer: strapiData?.initialData?.footer,
          phone: strapiData?.initialData?.phone,
          email: strapiData?.initialData?.email,
          address: strapiData?.initialData?.address,
          addressSm: strapiData?.initialData?.addressSm,
          knowledgeResources: strapiData?.knowledgeResources,
          emails: strapiData?.initialData?.emails,
          socials: strapiData?.initialData?.socials,          
          vocHomePage: strapiData?.vocHomePage,
        }
      })
    }catch(error){           
      dispatch({
        type: dataConstants.GET_INITIAL_DATA_FAILURE,
        payload: error.response?.data?.error
      })
    }  
  }
}

export const closeHomePopUp = () => {
  return async dispatch => {
    dispatch({ type: dataConstants.CLOSE_ADDITIONAL_POPUP })
  }
}

export const updateVisit = () => {
  return async dispatch => {
    dispatch({ type: dataConstants.UPDATE_VISIT })    
    let UUID = localStorage.getItem("ssju-visitor-id");
    let URL = `/app/update-visit`;
    if(!UUID) URL += `?unique=true`;    
    if(!UUID){
      UUID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem("ssju-visitor-id", UUID);
    }
    try{
      await Axios.get(URL);
    }catch(error){
      console.log(error);
    }
  }
}