import React from "react";

import { Box, Text } from "atoms";

export const SectionTable = ({
  title,
  data
}) => { 
  return (
    <Box mb="3rem">        
      {title && <Text
        color="primary.200"
        fontWeight="700"
        fontSize="2.4rem"
        lineHeight="4rem"
        borderBottom="3px solid"
        borderColor="primary.100"
        mb="1.5rem"
      >
        {title}
      </Text>}
      {data?.length > 0 && <Box
        as="table"      
        border="1px solid #909090"
        borderCollapse="collapse" 
        width="100%"       
        borderRadius="0.4rem"
      >    
        {data?.map((item, index) => (
          <Box
            as="tr"
            key={index}
          >
            {item?.map((cell, i) => (
              <Box
                as="td"
                key={i}
                p="1rem"
                border="1px solid #909090"
              >
                <Text                  
                  fontSize="1.5rem"
                  fontWeight={cell?.type === "head" ? "800" :"600"}            
                  color={cell?.type === "head" ? "primary.500" :"#707070"}            
                >
                  {cell?.value}
                </Text>
              </Box>          
            ))}
          </Box>
        ))}
      </Box>}      
    </Box>
  )
}