import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Grid, Image, Text } from "atoms";
import { LinkCard, MemberCard, PdfCard, ResearchCard } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";
import { AddingSoon } from "../AddingSoon";
import { generatePublicUrl } from "utils/utilities";

export const About = ({
  data,
  hod,
  links,
  files
}) => {
  return (
    <>      
      <Box className="tab-body">
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "1fr 0.4fr"}}
          gridGap="2rem"          
        >         
          <Box bg="secondary.300" borderRadius="0.4rem" px="2rem" py="1.5rem">
            {data?.length > 0 ? data?.map((item, index)=>{
              switch(item.__component){
                case "section.section-text":
                  return(
                    <PopulateTitleValue
                      title={item?.title}
                      values={item?.description?.split("<br/>")}
                    />
                  )

                case "section.section-title":
                  return(
                    <PopulateTitleValue
                      title={item?.title}
                      values={[]}                      
                    />
                  )

                case "section.section-list":
                  return(
                    <PopulateTitleValue
                      title={item?.title}
                      values={item?.data?.map((li) => li?.value)}
                      type="list"
                    />
                  )

                case "section.section-images":
                  return(
                    <Box mb={{xs: "1.4rem", md: "1.8rem"}}>
                      {item?.title && <PopulateTitleValue
                        title={item?.title}
                        values={[]}
                      />}
                      <PopulateImages
                        images={item?.images?.data?.map((image) => getStrapiMediaUrl(image?.attributes?.url))}
                      />
                    </Box>
                  )

                case "section.section-table":
                  return(
                    <Box mb={{xs: "1.4rem", md: "1.8rem"}}>
                      {item?.title && <PopulateTitleValue
                        title={item?.title}
                        values={[]}
                      />}
                      <PopulateTable
                        data={item?.data}
                      />
                    </Box>
                  )

                case "section.section-cards":
                  return(
                    <Box mb={{xs: "1.4rem", md: "1.8rem"}}>
                      {item?.title && <PopulateTitleValue
                        title={item?.title}
                        values={[]}
                      />}
                      <PopulateCards
                        data={item?.data}
                      />
                    </Box>
                  )

                // Message
                  
                default:
                  return null
              }
            }) : <AddingSoon/>}        
          </Box>                  
          <Box>
            {hod && <MemberCard
              data={{
                imageURL: getStrapiMediaUrl(hod?.image?.data?.attributes?.url),
                memberName: hod?.name,                
                designation: hod?.designation,
                specialization: hod?.specification,
                phone: hod?.phone,
                email: hod?.email
              }}
              vertical={true}
            />}
            {console.log(links)}
            {files?.length > 0 && <Box
              width="100%"
              bg="#f3f3f3"
              p="2rem"
              mt="2rem"              
            >
              <Text                
                fontSize="2rem"
                fontWeight="600"
                mb="2rem"
                color="primary.200"
              >
                Related Documents
              </Text>

              <Grid
                gridTemplateColumns="1fr"
                gridGap="1rem"
              >
                {files.map((item) => (
                  <PdfCard
                    key={item?.id}
                    title={item?.label}
                    href={getStrapiMediaUrl(item?.file?.data?.attributes?.url)}
                    addedOn={""}
                  />
                ))}
              </Grid>
            </Box>}
            {links?.length > 0 &&<Box
              width="100%"
              bg="#f3f3f3"
              p="2rem"    
              mt="2rem"          
            >
              <Text                
                fontSize="2rem"
                fontWeight="600"
                mb="2rem"
                color="primary.200"
              >
                Related Links
              </Text>
              <Grid
                gridTemplateColumns="1fr"
                gridGap="1rem"
              >
                {links?.map((item) => (
                  <LinkCard
                    key={item?.id}
                    title={item?.label}
                    url={item?.url}
                  />
                ))}
              </Grid>            
            </Box>}
          </Box>
        </Grid>
      </Box>
    </>
  )
}

const PopulateTitleValue = ({ title, values, type="paragraph" }) => (
  <Box
    mb={{xs: "1.4rem", md: "1.8rem"}}
  >
    {title && <Text      
      fontSize="2.2rem"
      fontWeight="600"
      color="primary.200"
      mb="1rem"
    >
      {title}
    </Text>}    
    {values?.map((value, index) => (
      <>
        {type === "list" && <Grid
          gridTemplateColumns="1rem 1fr"
          gridGap="0.8rem"
          mb="1.5rem"         
          alignItems="center"
        >
          <Box
            width="1rem"
            height="1rem"
            bg="primary.100"
            borderRadius="50%"            
          />
          <Text      
            key={index}            
            fontSize="1.6rem"
            fontWeight="500"
            color="#707070"
            lineHeight="2.2rem"
          >
            {value}
          </Text>
        </Grid>}
        {type === "paragraph" && <Text      
          key={index}          
          fontSize="1.6rem"
          fontWeight="500"
          mb="0.8rem"
          color="#707070"
          className="text-justify"           
          lineHeight="2.2rem"
        >
          {value}
        </Text>}
      </>     
    ))}
  </Box>
)

const PopulateImages = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,    
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,        
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: true,    
  };  
  return(
    <Box                            
      mt="1.6rem" 
      width={{xs: "26rem", md: "40rem", xm: "60rem"}}
      height={{xs: "18rem", md: "20rem", xm: "40rem"}}                           
      mx="auto"
    >
      <Slider {...settings}>
        {images?.map((image, index) => (
          <Box
            key={index}
            width={{xs: "26rem", md: "40rem", xm: "60rem"}}
            height={{xs: "18rem", md: "20rem", xm: "40rem"}}
          >
            <Image                                    
              src={image}
              alt={"Slider Image " + index}
              height="100%"
              width="100%"     
              borderRadius="0.4rem"                               
              objectFit="contain"
            />  
          </Box>
        ))}
      </Slider>                                                       
    </Box>
  )
}

const PopulateTable = ({ data }) => {
  return(
    <Box mt="1.6rem">
      <Box as="table" width="100%" borderRadius="1rem">                      
        <Box as="tbody" bg="grey.50" color="grey.400">
          {data?.map((tr ,i)=>(
            <Box as="tr" key={i}>   
              {tr?.map((td, j) => (
                <Box 
                  key={j} 
                  as="td" 
                  textAlign="start" 
                  px="1rem" 
                  py="1.2rem" 
                  fontSize="1.2rem" 
                  fontWeight={td?.type === "head" ? "600" : "400"}
                >{td?.value}</Box>                                                          
              ))}
            </Box>                        
          ))}            
        </Box>
      </Box>
    </Box>
  )
}

const PopulateCards = ({ data }) => {
  return(
    <Grid
      gridTemplateColumns={{md: "repeat(2, 1fr)"}}
      gridColumnGap="2.4rem"
      gridRowGap="2.4rem"          
      mt="3rem"      
    >
      {data?.map((item)=>(
        <ResearchCard
          key={item?.id}
          image={item?.image && getStrapiMediaUrl(item?.image?.data?.attributes?.url)}
          title={item?.title}
          description={item?.description}
          url={item?.linkUrl && generatePublicUrl(item?.linkUrl)}
          label={item?.linkLabel}
        />
      ))}          
    </Grid>       
  )
}