import React, { useEffect } from "react";

import { Layout } from "organisms";
import SEO from "seo/SEO";
import { JobPortalsPage } from "templates";
import { scrollToTop } from "utils/utilities";

const JobPortals = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <Layout>
      <SEO
        title="Job Portals || Soban Singh Jeena University Almora"
        description="Job Portals || Soban Singh Jeena University Almora"
        keywords="Job Portals SSJU,Job Portals Soban Singh Jeena University Almora,Job Portals Soban Singh Jeena University,Job Portals Uttarakhand University,Job Portals SSJ University,Job Portals SSJU"
      />
      <JobPortalsPage/>
    </Layout>
  )
}

export default JobPortals;