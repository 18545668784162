import React from "react";

import { Box, Flex, Grid, PillHeading } from "atoms";
import { ImportantLinkCard } from "molecules";

export const HomeImportantLink = ({data}) => {
  return (
    <>
      <Box maxWidth="100vw" p="3rem" bg="grey.50">
        <Flex justifyContent="center">
          <PillHeading title="Student Important Links"/>
        </Flex>
        <Grid
          mt="3rem"
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}
          gridColumnGap="4rem"
          gridRowGap="3rem"
        >
          {data?.map((item) => (            
            <ImportantLinkCard
              key={item?.id}
              data={item}
            />            
          ))}                    
        </Grid>
      </Box>
    </>
  )
}