import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Layout } from "organisms";
import { closeHomePopUp } from "redux/actions";
import { HomePage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Home = ({
  popUp,
  setPopUp
}) => {

  const dispatch = useDispatch();
  const { home, additionalPopUp, knowledgeResources } = useSelector(state => state.data);

  useEffect(() => {
    scrollToTop(window)       
  }, [])
  
  const closeAdditionalPopUp = () => {
    dispatch(closeHomePopUp())
  }

  return (
    <Layout>      
      <HomePage 
        additionalPopUp={additionalPopUp} 
        closeAdditionalPopUp={closeAdditionalPopUp} 
        homePageData={home}
        popUp={popUp}
        setPopUp={setPopUp}
        knowledgeResources={knowledgeResources}
      />
    </Layout>
  );
};

export default Home;
