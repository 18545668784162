import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Layout } from "organisms";
import { NewsTypePage } from "templates";
import { scrollToTop } from "utils/utilities";

const NewsType = () => {
  
  const { type } = useParams()

  const [ newsType, setNewsType ] = useState();
  const [ name, setName ] = useState("")

  useEffect(() => {
    scrollToTop(window)
  }, [])

  useEffect(() => {
    const map = [
      { value: "recent-news", type: 1, label: "University Recent News" },
      { value: "announcements", type: 2, label: "University Announcements"},
      { value: "tenders", type: 3, label: "University Tenders"}
    ]
    if(type?.length === 1){
      const selected = map.find(item => item.type === parseInt(type))
      setNewsType(selected?.type)
      setName(selected?.label)
    }else{
      const selected = map.find(item => item.value === type) 
      setNewsType(selected?.type)
      setName(selected?.label)
    }
  }, [type])

  return (
    <>
      <Layout>        
        <NewsTypePage
          seoData={name}
          type={newsType}          
        />
      </Layout>
    </>
  )
}

export default NewsType