import React, { useEffect } from "react";
import { MdOutlineHealthAndSafety } from "react-icons/md";

import { Box, PageTitle } from "atoms";
import { Layout } from "organisms";
import { scrollToTop } from "utils/utilities";

const CovidCare = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <Layout>
        <PageTitle
          icon={<MdOutlineHealthAndSafety size="2.5rem" />}
          title="Covid-19 Care Initiative by University"
        />
        <Box
          bg="secondary.300"
          p="4rem"
          textAlign="center"
        >
          Adding Soon
        </Box>
      </Layout>
    </>
  )
}

export default CovidCare