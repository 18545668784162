import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { GalleryCard, PageHead } from "molecules";
import { getAlbums } from "redux/actions";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";

export const GalleryPage = () => {
  
  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");

  const dispatch = useDispatch();
  const { gallery } = useSelector(state => state.app);
  
  useEffect(()=>{
    dispatch(getAlbums(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);  

  return (
    <>      
      <SEO
        title="Gallery || Soban Singh Jeena University Almora"
        description="Gallery || Soban Singh Jeena University Almora"
        keywords="Gallery SSJU,Gallery Soban Singh Jeena University Almora,Gallery Soban Singh Jeena University,Gallery Uttarakhand University,Gallery SSJ University,Gallery SSJU,Images Soban Singh Jeena University Almora,Images Soban Singh Jeena University,Images Uttarakhand University,Images SSJ University"        
      />
      <PageHead        
        title="University Albums"
        query={query}
        setQuery={setQuery}
        page={page}
        setPage={setPage}
        resultPerPage={gallery?.resultPerPage}
        totalCount={gallery?.totalCount}    
        type="pill"    
      >
        <Grid
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}
          gridColumnGap="2rem"
          gridRowGap="2rem"          
          my="3rem"              
        >
          {gallery?.albums?.map((item)=>(
            <GalleryCard
              key={item?.id}
              data={item}
            />
          ))}          
        </Grid>       
      </PageHead>           
    </>
  )
}