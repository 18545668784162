import React, { useEffect } from "react";

import { Layout } from "organisms";
import { ExamSchedulePage } from "templates";
import { scrollToTop } from "utils/utilities";

const ExamSchedule = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <Layout>        
        <ExamSchedulePage/>
      </Layout>
    </>
  )
}

export default ExamSchedule