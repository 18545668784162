import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex, Grid, InputDebounce, Text } from "atoms";
import { EventCard, NewsCard } from "molecules";
import { getNews, getEvents } from "redux/actions";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";

export const NewsPage = () => {

  const [ newsPage, setNewsPage ] = useState(1);
  const [ newsQuery, setNewsQuery ] = useState("");
  const [ eventPage, setEventPage ] = useState(1);
  const [ eventQuery, setEventQuery ] = useState("");

  const dispatch = useDispatch();
  const { news, events } = useSelector(state => state.app);    

  useEffect(()=>{
    dispatch(getNews(newsPage, newsQuery))
  }, [newsPage, newsQuery, dispatch]);

  useEffect(()=>{
    scrollToTop(window);
  }, [newsPage, eventPage]); 

  useEffect(()=>{
    if(newsQuery) setNewsPage(1);
  }, [newsQuery]);  

  useEffect(()=>{
    dispatch(getEvents(eventPage, eventQuery))
  }, [eventPage, eventQuery, dispatch]);

  useEffect(()=>{
    if(eventQuery) setEventPage(1);
  }, [eventQuery]);  

  return (
    <>      
      <SEO
        title="News & Events || Soban Singh Jeena University Almora"
        description="News & Events || Soban Singh Jeena University Almora"
        keywords="News SSJU,News Soban Singh Jeena University Almora,News Soban Singh Jeena University,News Uttarakhand University,News SSJ University,News SSJU,Events Soban Singh Jeena University Almora,Events Soban Singh Jeena University,Events Uttarakhand University,Events SSJ University"        
      />
      <Box overflow="hidden" p="3rem 2rem">
        <Grid
          gridTemplateColumns={{xm: "1fr 0.5fr", lg:"1fr 0.4fr"}}
          gridColumnGap="2rem"
          gridRowGap="2rem"          
        >
          <Box bg="secondary.300" borderRadius="0.4rem" px="2rem" py="2.5rem">
            <Text
              fontSize="2rem"
              fontWeight="600"
              color="primary.200"
            >
              University News
            </Text>
            <Box my="2rem">
              <InputDebounce    
                name="search-news"
                type="text"
                id="search-news"                    
                label="Search News"                
                value={newsQuery}          
                setValue={setNewsQuery}                                        
              />
            </Box>  
            <Grid
              gridTemplateColumns={{md: "1fr 1fr", lg:"1fr 1fr 1fr"}}
              gridColumnGap="1.5rem"
              gridRowGap="3rem"
              mt="3rem"              
            >
              {news?.news?.map((item) => (<NewsCard news={item} key={item?.id} />))}
            </Grid>
            {news?.resultPerPage < news?.totalCount &&  <Flex
              justifyContent="center"
              my="4rem"
            >
              <Pagination
                activePage={newsPage}
                itemsCountPerPage={news?.resultPerPage}
                totalItemsCount={news?.totalCount}
                onChange={(e) => setNewsPage(e)}
                nextPageText="Next"
                prevPageText="Prev"
                firstPageText="1st"
                lastPageText="Last"
                itemClass="pagination-item"
                linkClass="page-link"
                activeClass="pagination-item-active"
                activeLinkClass="pagination-link-active"
              />
            </Flex>}       
            {news?.totalCount > 0 &&  <Text
              textAlign="center"
              fontSize="1.4rem"
              fontWeight="600"
              color="primary.200"
              my="3rem"
            >
              Total Entries Found : {news?.totalCount}
            </Text>} 
          </Box>
          <Box bg="secondary.300" borderRadius="0.4rem" px="1.5rem" py="2.5rem" height="fit-content">
            <Text
              fontSize="2rem"
              fontWeight="600"
              color="primary.200"
            >
              University Events
            </Text>
            <Box my="2rem">
              <InputDebounce            
                name="search-events"
                type="text"
                id="search-events"                    
                label="Search Events"                
                value={eventQuery}          
                setValue={setEventQuery}                                        
              />
            </Box>  
            <Grid
              gridTemplateColumns="1fr"
              gridColumnGap="2rem"
              gridRowGap="3rem"
              justifyContent={{xs: "center"}}
              my="3rem"              
            >
              {events?.events?.map((item) => (<EventCard event={item} key={item?.id} />))}
            </Grid>  
            {events?.resultPerPage < events?.totalCount &&  <Flex
              justifyContent="center"
              my="4rem"
            >
              <Pagination
                activePage={eventPage}
                itemsCountPerPage={events?.resultPerPage}
                totalItemsCount={events?.totalCount}
                onChange={(e) => setEventPage(e)}
                nextPageText="Next"
                prevPageText="Prev"
                firstPageText="1st"
                lastPageText="Last"
                itemClass="pagination-item"
                linkClass="page-link"
                activeClass="pagination-item-active"
                activeLinkClass="pagination-link-active"
              />
            </Flex>}       
            {events?.totalCount > 0 &&  <Text
              textAlign="center"
              fontSize="1.4rem"
              fontWeight="600"
              color="primary.200"
              my="3rem"
            >
              Total Entries Found : {events?.totalCount}
            </Text>} 
          </Box>
        </Grid>
      </Box>
    </>
  )
}