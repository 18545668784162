import React from "react";

import { Flex, Text } from "atoms";
import { GetLink } from "molecules";

export const SectionTitle = ({ title, href, ...props}) => {
  return (
    <Flex
      width="fit-content"
      alignItems="start"
      {...props}
    >
      <Text
        fontSize="2.8rem"
        textTransform="uppercase"
        fontWeight="600"
        mr="1.6rem"
        opacity="0.8"
      >
        {title}
      </Text>
      <GetLink
        url={href}
      >
        <Text
          fontSize="1.4rem"       
          fontWeight="500"
          color="accent.200"
          mt="0.7rem"
          borderLeft="1px solid"
          pl="1rem"
          borderColor="accent.200"
          cursor="pointer"
          transition="color 0.3s"
          _hover={{
            color: "accent.100"
          }}
        >
          View More
        </Text>        
      </GetLink>
    </Flex>
  )
}