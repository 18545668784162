import React, { useEffect } from "react";
import { MdOutlinePrivacyTip } from "react-icons/md";

import { Box, PageTitle, Text, VocPageTitle } from "atoms";
import { Layout } from "organisms";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";

const PrivacyPolicy = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title={`Privacy-Policy || Soban Singh Jeena University Almora`}
        description={`Privacy-Policy || Soban Singh Jeena University Almora`}
        keywords={`Privacy-Policy SSJU,Privacy-Policy Soban Singh Jeena University Almora,Privacy-Policy Soban Singh Jeena University,Privacy-Policy Uttarakhand University,Privacy-Policy SSJ University,Privacy-Policy SSJU`}
      />
      <Layout>
        <PageTitle
          icon={<MdOutlinePrivacyTip size="2.5rem" />}
          title="PRIVACY POLICY"
        />
        <Box maxWidth="100rem" mx="auto" px={{xs: "3rem", lg: "unset"}} color="grey.300">
          <Box my="4rem">
            <VocPageTitle
              title="PRIVACY POLICY"
            />
            <Text textAlign="center">        
              Thanks for visiting website of Soban Singh Jeena University and reviewing our privacy policy. We collect no personal information, like names or addresses, when you visit our website. lf you choose to provide that information to us, it is only used to fulfill your request for information.
            </Text>      
          </Box>
        </Box>
      </Layout>
    </>
  )
}

export default PrivacyPolicy