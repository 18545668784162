import React from "react";
import PropTypes from "prop-types";

import { Box, Text } from "atoms";

export const PillHeading = ({title, fontSize, color, bg, fontWeight, width}) => {
  return (
    <Box
      bg={bg}      
      py="1.2rem"
      px="1.8rem"
      borderRadius="6rem"
      width={width}
    >
      <Text
        fontSize={fontSize}
        color={color}
        fontWeight={fontWeight}
        textAlign="center"
      >
        {title}
      </Text>
    </Box>
  )
}

PillHeading.defaultProps = {
  fontSize: {xs: "1.6rem", md: "1.8rem"},  
  color: "success.300",
  bg: "success.50",
  fontWeight: "700",
  width: "fit-content"
};

PillHeading.propTypes = {  
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
  bg: PropTypes.string,
  fontWeight: PropTypes.string,
  width: PropTypes.string,
};
