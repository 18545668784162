import React from "react";

import { Box, Grid, Text } from "atoms";
import { AddingSoon } from "../AddingSoon";

export const Courses = ({ data }) => {  
  return (
    <>      
      <Box className="tab-body">      
        <Grid
          gridTemplateColumns={data?.length ? {md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)"} : "1fr"}
          gridColumnGap="1.5rem"      
          gridRowGap="1.5rem"      
          my="2rem"      
          px="1rem"
        >
          {data?.length > 0 ?  data?.map((item, index) => (
            <Box
              width="100%"
              px="2rem"
              py="2rem"
              boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.1)"
              borderRadius="4px"
              bg="white"
              key={index}
            >              
              <Text fontSize="1.6rem" fontWeight="600" >{item?.name}</Text>
              {item?.duration && <Text fontSize="1.4rem" fontWeight="500" mt="1rem">{item?.duration}</Text>}
              <Box mt="1rem">
                {item?.description?.split("<br/>")?.map((desc, i) => (
                  <Text key={i} fontSize="1.4rem" fontWeight="500" mt="1rem">{desc}</Text>
                ))}
              </Box>
              {item?.table && <Table data={item?.table} />}
              {item?.info && <Table data={item?.info} />}
              {/* {item?.warning && <Box my="2rem">
                {item?.warning?.map((warn, index) => (
                  <Text key={index} textAlign="center" fontSize="1.2rem" fontWeight="600" color="danger.100">*{warn}</Text>
                ))}
              </Box>} */}
            </Box>
          )) : <AddingSoon/>}      
        </Grid>     
      </Box>
    </>
  )
}

const Table = ({ data }) => {
  return(
    <Box mt="2rem">
      <Box as="table" width="100%" borderRadius="1rem">                      
        <Box as="tbody" bg="grey.50" color="grey.400">
          {data?.map((tr ,i)=>(
            <Box as="tr" key={i}>   
              {tr?.map((td, j) => (
                <Box 
                  key={j} 
                  as="td" 
                  textAlign="start" 
                  px="1rem" 
                  py="1.2rem" 
                  fontSize="1.2rem" 
                  fontWeight={td?.type === "head" ? "600" : "400"}
                >{td?.value}</Box>                                                          
              ))}
            </Box>                        
          ))}            
        </Box>
      </Box>
    </Box>
  )
}