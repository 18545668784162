import React from "react";

import { Box, Button, Flex, Text } from "atoms";
import { GetLink } from "molecules";
import SEO from "seo/SEO";
import { formatDate, generatePublicUrl, printINR } from "utils/utilities";

const steps = [
  "Candidates are advised to check their details to fill exam form and submit the fees. Fees deposited once will not be refunded in any case.",
  "Candidates should keep a copy of the printed application form for further use (if the need arises). DO NOT SUBMIT THE HARD COPY TO THE UNIVERSITY BY POST/MAIL.",
  "Those candidates who do not fulfil the requisite educational qualification (i.e. required percentage and/or qualifying examination) will not be allowed and will not be able to fill/submit the application form.",
  "If required, students can update his/her Email ID, Father's Name, Mother's Name, Photo and Signature. Kindly note this is only one time activity which cannot be done after making the payment. if any updation required after successful payment those students are advised to contact their respective colleges.",
  <>In case of payment failure you are advised to contact on helpdesk no. <b>(+91 9389228355, +91 5962298859)</b> or drop an email <GetLink url="mailto:helpdeskssju@gmail.com"><Box color="accent.200" as="span">(helpdeskssju@gmail.com)</Box></GetLink> of screenshot of that payment</>,
  "When the payment is being process, please do not click/refresh/back button for at least 30 seconds.",
  "Please do not make payment more than 10 times from single debit card/credit card/bank account in 24 hrs.",
  "If photo/signature are not available then get scanned copy of your passport (PP) size photograph and Signature in JPEG/JPG format. Make sure that Photo Image should be less than 400kb and Sign less than 400kb in size. You will be asked to upload the Photo & Sign while filling up the Application form.",
  "Complete the application process after paying the requisite fees and take the print out of confirmation page for further use (if the need arises).",
  "Do review your Photo, Sign, Personal and Educational details before final submission of the Application.",
  "When you finally submit the Application form, a unique Enrolment Number will be assigned to you in Print form",
  "Regularly check the official University website for latest updates and information.",
  "The candidates are compulsorily required to bring a photo identity card along with their Admit Card on the day of examination.",
  "The University reserves the right to change the Date of Examination without assigning any reason.",
  "Canvassing in any form will disqualify the candidature.",
  "Applications submitted on any other format except the Online mode, will not be accepted in any case.",
  "All incomplete Application Forms will be rejected straightaway.",
  "Decision of the University shall be final in all matters.",
  "All legal disputes pertaining to this Exam shall fall within the jurisdiction of Uttarakhand High Court, Nainital only.",
  "It may be noted that Admit Card will be issued 07 days in advance by the University through online portal only. Candidates are required to verify the information printed on the Admit Card and carry the printout of online Admit Card at the time of Examination.",
  "If any student is facing any problem regarding his/her registration number, date of birth, subject or any other details he/she is requested to contact his/her college or Controller of Examination SSJU Almora to retrieve/update his/her details.",
  <>If the transaction is failed, the money will be reversed back to your account in 7 working days. If the money is not refunded in 7 working days you are requested to share the Transaction ID, Transaction Date, Transaction Amount to <GetLink url="mailto:helpdeskssju@gmail.com"><Box color="accent.200" as="span">(helpdeskssju@gmail.com)</Box></GetLink></>,
  "If the student applies for the examination form and makes the payment, the amount is debited from his/her account but if he/she is not able to download the receipt the following transaction will be a failed transaction and the amount will be refunded back within 7 working days. Those student are requested to again make the payment and download the receipt",
]

const btnColor = [
  "success",
  "warning",
  "danger",
]

export const ExamFormPage = ({ data }) => {
  return (
    <>
      <SEO
        title={`${data?.name} - ${data?.year} Exam Form || Soban Singh Jeena University Almora`}
        description={`${data?.name}-${data?.year} Exam Form || Soban Singh Jeena University Almora`}
        keywords={`${data?.name}-${data?.year} Exam Form SSJU,${data?.name}-${data?.year} Exam Form Soban Singh Jeena University Almora,${data?.name}-${data?.year} Exam Form Soban Singh Jeena University,${data?.name}-${data?.year} Exam Form Uttarakhand University,${data?.name}-${data?.year} Exam Form SSJ University,Exam Form SSJU`}
      />      
      <Box width={{xs: "100%", xm: "80%"}} mx="auto" px="3rem">
        <Box as="table" width="100%" borderRadius="1rem" mt="2rem">
          <Box as="thead" bg="success.100" color="white">
            <Box as="tr" fontSize="1.4rem" fontWeight="600" >
              <Box as="th" width="40%" textAlign="start" px="2rem" py="1rem">Particulars</Box>
              <Box as="th" textAlign="start" px="2rem" py="1rem">Start Date</Box>
              <Box as="th" textAlign="start" px="2rem" py="1rem">End Date</Box>
            </Box>
          </Box>
          <Box as="tbody" bg="grey.50" color="grey.400">                
            <Box as="tr">
              <Box as="td" textAlign="start" px="2rem" py="1rem">Fee Submission</Box>
            <Box as="td" textAlign="start" px="2rem" py="1rem" fontWeight="600">{data.startDate ? formatDate(data.startDate) : "Declared Soon"}</Box>
              <Box as="td" textAlign="start" px="2rem" py="1rem" fontWeight="600">{data.endDate && formatDate(data.endDate)}{" "}(Online Mode - Debit Card/Credit Card/Net Banking Mode)</Box>
            </Box>                                 
            <Box as="tr">
              <Box as="td" textAlign="start" px="2rem" py="1rem">Exam Name</Box>
              <Box as="td" textAlign="start" px="2rem" py="1rem" fontWeight="600" colSpan="2">{data.name} - {data.year}</Box>              
            </Box>
            {data?.examFormFees.length > 0 && <>
              <Box as="tr">
                <Box as="td" colSpan="3" textAlign="center" px="2rem" py="1rem" fontWeight="600" color="danger.100">Course Fees</Box>                    
              </Box>
              {data?.examFormFees?.map((course) => (
                <Box as="tr" key={course.id}>
                  <Box as="td" textAlign="start" px="2rem" py="1rem">{course.name}</Box>
                  <Box as="td" colSpan="2" textAlign="start" px="2rem" py="1rem" fontWeight="600">{printINR(course.fee)}</Box>                
                </Box>
              ))}
            </>}
          </Box>
        </Box>
        <Box mt="3rem">
          <Box bg="accent.100" fontSize="1.4rem" fontWeight="600"  px="2rem" py="1rem" color="white" borderTopLeftRadius="0.4rem" borderTopRightRadius="0.4rem">
            TERMS, CONDITIONS AND INSTRUCTIONS
          </Box>
          <Box px="2rem" py="1rem" border="1px solid" borderColor="grey.100" borderBottomLeftRadius="0.4rem" borderBottomRightRadius="0.4rem">
            <Box as="ul" m="0" px="1rem" className="list-image">
              {steps?.map((item, index) => (
                <Box as="li" key={index} mb="1rem">
                  <Text fontWeight="500" fontSize="1.2rem" ml="0.4rem">{item}</Text>                    
                </Box>
              ))}              
            </Box>          
          </Box>
        </Box>
        <Flex flexDirection={{xs:"column", md: "row"}} flexWrap="no-wrap" alignItems="center" justifyContent="space-between" my="3rem">
          {data?.examFormLinks?.map((item, index) => (
              <Box maxWidth="26rem" my="1rem" mx="auto" key={item.id} minWidth="15rem">
              <GetLink url={generatePublicUrl(item?.href)}>
                <Button
                  variant={btnColor[index%3]}
                  p="0.6rem"
                >
                  <Text fontize="1.4rem">
                    {item.title}
                  </Text>
                </Button>
              </GetLink>
            </Box>
          ))}

        </Flex>        
      </Box>
    </>
  )
}