import React from "react";

import { Box } from "atoms";
import { MarkdownJSX } from "molecules";

export const SectionRichText = ({
  title,
  description
}) => {
  return (
    <Box
      width="100%"
      mb="3rem"
    >
      <Box
        bg="primary.200"
        color="white"
        fontSize="2rem"
        fontWeight="bold"
        textAlign="center"
        py="1.5rem"
        px="1rem"
        borderTopLeftRadius="0.4rem"
        borderTopRightRadius="0.4rem"
      >
        {title}
      </Box>
      <Box
        py="1.5rem"
        px="2rem"
        bg="secondary.300"
        borderBottomLeftRadius="0.4rem"
        borderBottomRightRadius="0.4rem"            
      >
        {description && <MarkdownJSX
          content={description}
          textAlign="center"
        />}        
      </Box>
    </Box>
  )
}