import React, { useEffect } from "react";
import { GiBookCover } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, PageTitle } from "atoms";
import { Layout } from "organisms";
import { getExamFormInfo } from "redux/actions"
import { ExamFormPage } from "templates";
import { scrollToTop } from "utils/utilities";

const ExamFormDetails = () => {

  document.title = "Online Exam Form || Soban Singh Jeena University Almora";

  const { examName } = useParams();    

  const dispatch = useDispatch();
  const { loading, examFormInfo } = useSelector(state => state.app);

  useEffect(() => {
    scrollToTop(window)    
    if(examName !== examFormInfo?.id) dispatch(getExamFormInfo(examName));
  }, [ examName, dispatch, examFormInfo?.id ])  

  return (
    <>
      <Layout>        
        <PageTitle
          icon={<GiBookCover size="2.5rem" />}
          title="Online Application Form For Examination 2023"
        />
        {examFormInfo && !examFormInfo.hide && examFormInfo?.id === examName ?
          <ExamFormPage
            data={examFormInfo}
          />
        : !loading && <Box px="2rem" py="3rem" textAlign="center" fontSize="2rem" color="gray">Exam Form Not Found</Box>}        
      </Layout>
    </>
  )
}

export default ExamFormDetails