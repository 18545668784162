import React from "react";
import { BiLinkExternal } from "react-icons/bi";

import { Box, Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { generatePublicUrl } from "utils/utilities";

export const LinkCard = ({
  title,
  url,
  bg="white",
  color="#707070",
  width="100%",
  borderRadius="0"
}) => {
  return (
    <Box
      width={width}
      bg={bg}
      borderRadius={borderRadius}
    >
      <GetLink
        url={generatePublicUrl(url)}
      >
        <Flex
          width="100%"
          height="100%"    
          alignItems="center"
          justifyContent="space-between"
          p="1.2rem"
          color={color}
          _hover={{
            color: "accent.200"
          }}
        >
          <Text
            fontWeight="500"
          >
            {title}
          </Text>
          <Flex
            alignItems="center"
            justifyContent="center"      
            fontSize="2.2rem"
            ml="1rem"
          >
            <BiLinkExternal/>
          </Flex>
        </Flex>
      </GetLink>
    </Box>
  )
}