import React, { useEffect, useState } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

import { Box } from "atoms";
import { InitialLoader } from "molecules";
import { getInitialData } from "redux/actions";
import { AppRoutes } from "routes";
import { theme } from "styles";
import { scrollToTop } from "utils/utilities";
import "styles/globals.css";
import "styles/style.css";

function App() {
  
  const [ scroll, setScroll ] = useState(false);  
  const [ popUp, setPopUp ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.data);

  const handleScroll = () => {
    if (window.scrollY >= 200) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {   
    dispatch(getInitialData());   
    setTimeout(() => {
      setPopUp(true)
    }, 5000); 
  }, [dispatch])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  
  return (    
    <ThemeProvider theme={theme}>              
      <ToastContainer />
      {loading ?
        <InitialLoader/>
      : <Router>
        <AppRoutes 
          popUp={popUp}
          setPopUp={setPopUp}
        />
      </Router>}   
      {scroll && <Box
        position="fixed"
        color="primary.100"
        bg="primary.200"
        bottom="4rem"
        right="2rem"
        width="3rem"
        height="3rem"
        borderRadius="0.2rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={() => scrollToTop(window)}
      >
        <AiOutlineArrowUp size="2rem" />
      </Box>}      
    </ThemeProvider>    
  );
}

export default App;
