import React, { useEffect, useState } from "react";
import { AiOutlinePhone, AiTwotoneMail } from "react-icons/ai";
import { ImLocation} from "react-icons/im";
import { IoMdOpen } from "react-icons/io";

import { Box, Button, Flex, Text } from "atoms";
import { GetLink } from "molecules";

export const HeaderContact = ({
  phone,
  email,
  headerBtn,
  addressSm,
}) => {

  const [ blink, setBlink ] = useState(false);
  const [ dropDown, setDropDown ] = useState(false);

  useEffect(() => {
    setTimeout(()=>{ setBlink(!blink)}, 600)
  }, [blink])

  return (
    <>
      <Flex
        width="100vw"
        bg="primary.200"
        alignItems="center"
        justifyContent="space-between"
        p="0.6rem"
        px={{xs: "1rem", lg: "2rem"}}
        style={{gap: "1rem"}}
      >
        <Flex 
          alignItems={{lg: "center"}}
          flexDirection={{xs: "column", lg: "row"}}
          style={{gap: "0.6rem"}}
        >
          {addressSm && <Option
            url="#"
            label={addressSm}
            Icon={<ImLocation />}
          />}   
          {phone && <Option
            url={`tel:${phone}`}
            label={phone}
            Icon={<AiOutlinePhone />}
          />}   
          {email && <Option
            url={`mailto:${email}`}
            label={email}
            Icon={<AiTwotoneMail />}
          />}              
        </Flex>

        <Flex 
          alignItems={{md: "center"}}
          flexDirection="row"
          flexWrap="wrap"   
          style={{gap: "1rem"}}
        >
          <Box
            transform={!dropDown ? "rotate(180deg)" : "rotate(0deg)"}
            display={{ xs: "block", xm: "none" }}
            color="white"
            _hover={{color: "danger.200"}}
            cursor="pointer"
            transition="0.3s all"
            onClick={()=>setDropDown(!dropDown)}
          >
            <IoMdOpen fontSize="2rem" />
          </Box>

          <Box 
            display={dropDown ? {xs: "flex", xm: "none"} : "none"}
            flexDirection="column"
            style={{gap: "1rem"}}
            bg="primary.200"
            p="1.5rem"
            position="absolute"
            top="4.5rem"
            right="0"
            minWidth="32rem"
            zIndex="6"
            borderRadius="0.5rem"
          >
            <NewButtonOption
              url="/student/exam-schedule"
              label="Exam Schedule"
            />
            {headerBtn?.map((btn, index) => (
              <NewButtonOption
                key={index}
                url={btn.href}
                label={btn.title}
                border={index !== headerBtn.length - 1}
              />
            ))}
          </Box>
          <Box display={{ xs: "none", xm: "block" }}>
            <GetLink url="/student/exam-schedule">            
              <Button
                variant={"self"}
                bg={blink ? "danger.200" : "white"}
                color={!blink ? "danger.200" : "white"}
                minWidth={{xs: "10rem"}}
                p="0.6rem"              
              >
                <Text fontSize="1.2rem" fontWeight="600" >
                  Exam Schedule
                </Text>              
              </Button>            
            </GetLink>
          </Box>

          {headerBtn?.map((btn) => (
            <ButtonOption
              key={btn.id}
              url={btn.href}
              label={btn.title}
              variant={btn.variant}
            />
          ))}         
        </Flex>
      </Flex>
    </>
  )
}

const Option = ({
  url,
  label,
  Icon
}) => (
  <GetLink url={url}>
    <Flex 
      alignItems="center"       
      style={{gap: "0.4rem"}}
    >
      <Box       
        color="danger.200"        
        mt="0.3rem"
        fontSize={{xs: "1.4rem", md: "1.6rem"}}        
      >
        {Icon}
      </Box>
      <Text 
        color="white" 
        fontSize={{xs: "1.2rem", md: "1.4rem"}} 
        fontWeight="600"
      >
        {label}
      </Text>
    </Flex>
  </GetLink>  
)

const ButtonOption = ({
  url,
  label,
  variant
}) => (
  <Box
    display={{xs: "none", xm: "block"}}
  >
    <GetLink 
      url={url}
    >
      <Button
        variant={variant}      
        minWidth={{xs: "10rem"}}
        p="0.6rem"
      >
        <Text fontSize="1.1rem" fontWeight="600" >
          {label}
        </Text>                            
      </Button>
    </GetLink>
  </Box>
)

const NewButtonOption = ({
  url,
  label, 
  border=true 
}) => (
  <Box
    display={{xs: "block", xm: "none"}}
    borderBottom={border ? "1px solid #d9d9d9" : "none"}
    pb="0.6rem"
  >
    <GetLink 
      url={url}
    >
      <Text 
        fontSize="1.4rem" 
        fontWeight="500"
        color="white"
        _hover={{color: "danger.200"}}
        transition="0.3s all"
      >
        {label}
      </Text>
    </GetLink>
  </Box>
)