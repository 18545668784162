import React, { useState } from "react";

import { Box, Flex, Image, Text } from "atoms";
import { MemberCard } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const SectionMessage = ({
  title,
  profile,
  titleEnglish,
  titleHindi,
  messageEnglish,
  messageHindi,
  name,
  designation,
  image
}) => {

  const [ selectedLanguage, setSelectedLanguage ] = useState("english");

  return (
    <Box mb="3rem">
      <Text
        color="primary.200"
        fontWeight="700"
        fontSize="2.4rem"
        lineHeight="4rem"
        borderBottom="3px solid"
        borderColor="primary.100"
      >
        {title}
      </Text>
      <Box
        mt="1.5rem"
      >
        <Box                    
          width="fit-content"
          className="float-left" 
          zIndex={{md: "1"}}
          mr={{md: "0.8rem"}}
          mb={{xs: "1.4rem", md: "0.8rem"}}          
          mx="auto"
        >          
          {image && <Box
            maxWidth="32rem"                        
          >
            <Image
              src={image}
              alt={name}
              borderRadius="0.4rem"
              mb="1rem"
            />
          </Box>}
          {profile && <MemberCard 
            data={{
              imageURL: getStrapiMediaUrl(profile?.avatar?.data?.attributes?.url),
              memberName: profile?.name,
              designation: profile?.designation,
              phone: profile?.phone,
              email: profile?.email,
              specialization: profile?.specialization,
            }} 
          />}
        </Box>
        <Box bg="secondary.300">
          <Flex
            bg="primary.200"                        
            p="1.5rem"            
            borderTopLeftRadius="0.4rem"
            borderTopRightRadius="0.4rem"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              color="white"
              fontSize="2.2rem"
              fontWeight="bold"
            >
              Message
            </Text>
            <Flex
              border="1px solid"
              borderColor="white"              
              borderRadius="0.4rem"
              style={{
                cursor: "pointer",                
              }}
            >
              <Text
                color={selectedLanguage === "english" ? "primary.200" : "white"}
                fontSize="1.4rem"
                p="0.6rem 1rem"
                bg={selectedLanguage === "english" ? "white" : "transparent"}                                              
                onClick={() => setSelectedLanguage("english")}
              >
                English
              </Text>
              {titleHindi && <Text               
                fontSize="1.4rem" 
                p="0.6rem 1rem"
                bg={selectedLanguage === "hindi" ? "white" : "transparent"}
                color={selectedLanguage === "hindi" ? "primary.200" : "white"}             
                onClick={() => setSelectedLanguage("hindi")}
              >
                Hindi
              </Text>}
            </Flex>
          </Flex>        
          <Box
            py="1.5rem"
            px="2rem"            
            borderBottomLeftRadius="0.4rem"
            borderBottomRightRadius="0.4rem"                                    
          >
            {(titleHindi || titleEnglish) && <Box
              fontSize="2rem"
              fontWeight="bold"              
              color="primary.200"
              mb="1rem"
            >
              {selectedLanguage === "english" && titleEnglish}
              {selectedLanguage === "hindi" && titleHindi}
            </Box>}             
            {selectedLanguage === "english" && messageEnglish?.split("<br/>")?.map((item, index) => (
              <Text key={index} fontSize="1.5rem" lineHeight="2.4rem" mb="1rem" color="grey.200" textAlign={{xs: "center", md: "start"}} className="text-justify">
                {item}
              </Text>
            ))}
            {selectedLanguage === "hindi" && messageHindi?.split("<br/>")?.map((item, index) => (
              <Text key={index} fontSize="1.5rem" lineHeight="2.4rem" mb="1rem" color="grey.200" textAlign={{xs: "center", md: "start"}} className="text-justify">
                {item}
              </Text>
            ))}            
          </Box>
        </Box>
      </Box>
    </Box>
  )
}