import React from "react";

import { Box, Text } from "atoms";

export const SectionText = ({
  title,
  description
}) => {
  return (
    <Box mb="3rem">
      <Text
        color="primary.200"
        fontWeight="700"
        fontSize="2.4rem"
        lineHeight="4rem"
        borderBottom="3px solid"
        borderColor="primary.100"
      >
        {title}
      </Text>
      <Text
        mt="1.5rem"
        fontSize="1.6rem"
        lineHeight="2.4rem"
        fontWeight="500"
        color="grey.200"
        className="text-justify"
      >
        {description}
      </Text>
    </Box>
  )
}