import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { generatePublicUrl } from "utils/utilities";
import { BtnColors } from "utils/constant-data";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    padding: "0px",
    border: "none",    
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "6"
  },
};

export const PopUpModal = ({ isOpen, onRequestClose, ariaHideApp, data }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={ariaHideApp}      
    >
      <Box
        width="100%"
        maxWidth={{xs: "30rem", md: "60rem"}}
        bg="white"
        px={{xs: "1rem", md: "3rem"}}        
      >      
        <Flex 
          py="1rem"
          alignItems="center"
          justifyContent="space-between"
          style={{gap: "1rem"}}
        >  
          <Text
            fontWeight="800"
            fontSize={{ xs: "1.6rem", lg: "2rem" }}            
            color="black"            
            opacity="0.8"
            textAlign="start"            
          >
            {data?.title}
          </Text> 
          <Box
            onClick={onRequestClose}            
            cursor="pointer"
            zIndex={2}
            color="danger.100"
          >
            <AiOutlineClose size="2.4rem"/>
          </Box>         
        </Flex>
        <Box
          width="100%"
          height={{xs: "25rem", md: "40rem"}}
          mt="1rem"
          border="1px solid whitesmoke"
          borderRadius="4px"
          overflow="hidden"
        >
          <Image
            src={generatePublicUrl(data?.image)}
            alt={data.title}
            width="100%"
            height="100%"
            objectFit="contain"            
          />
        </Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          mb="1rem"
          style={{gap: "1rem"}}
        >
          {data?.newsSrc?.map((src, index) => (
            <GetLink url={generatePublicUrl(src?.href)} key={src.id}>
              <Button
                variant={BtnColors[index]}
                width={{xs: "10rem", md: "14rem"}}
                py="0.8rem"
                px="1rem"                                
              >
                <Text fontSize="1.2rem" fontWeight="400" >
                  {src?.title}
                </Text>                            
              </Button>
            </GetLink>
          ))}                     
        </Flex>
      </Box>
    </Modal>
  )
}