import React, { useEffect } from "react";

import { VocLayout } from "organisms";
import { VocHomePage } from "templates";
import { scrollToTop } from "utils/utilities";

const VOC = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <VocLayout>
        <VocHomePage/>
      </VocLayout>
    </>
  )
}

export default VOC