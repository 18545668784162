import React, { useEffect } from "react";
import { BsJournalBookmark } from "react-icons/bs";
import { GiBookCover } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Flex, Grid, PageTitle, Text } from "atoms";
import { Layout } from "organisms";
import { getExamForms } from "redux/actions";
import { scrollToTop } from "utils/utilities";
import { GetLink } from "molecules";

const ExamForm = () => {

  document.title = "Online Exam Form || Soban Singh Jeena University Almora";  

  const dispatch = useDispatch();
  const { examForms } = useSelector(state => state.app)

  useEffect(() => {    
    scrollToTop(window)
    dispatch(getExamForms())
  }, [dispatch]);

  return (
    <>
      <Layout>        
        <PageTitle
          icon={<GiBookCover size="2.5rem" />}
          title="Online Application Form For Examination 2022"
        />
        <Grid
          px="2rem"
          py="4rem"
          placeItems="center"
          gridTemplateColumns="repeat(auto-fit, minmax(24rem, 1fr))"
          gridGap="2rem"
        >
          {examForms?.map((item) => (
            <ExamFormCard
              key={item?.id}
              name={item?.name}
              href={item?.id}
              year={item?.year}
            />
          ))}          
        </Grid>  
      </Layout>
    </>
  )
}

const ExamFormCard = ({ name, href, year}) => (
  <Box
    width="24rem"
    borderRadius="0.4rem"
    boxShadow= "rgba(0, 0, 0, 0.24) 0px 3px 8px"    
  >
    <Flex justifyContent="center" alignItems="center" p="3rem" flexDirection="column">
      <Box fontSize="2rem" color="info.200">
        <BsJournalBookmark/>
      </Box>
      <Text textAlign="center" fontSize="1.6rem" fontWeight="600" mt="0.5rem" color="grey.400">{name}-{year}</Text>      
    </Flex>
    <GetLink url={`/exam-form-details/${href}`}>
      <Button variant="warning" py="1rem" borderRadius="0 0 0.4rem 0.4rem">
        <Text fontSize="1.4rem" fontWeight="bold">View Form</Text>        
      </Button>
    </GetLink>
  </Box>
)

export default ExamForm