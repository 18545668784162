import React, { useEffect, useState } from "react";

import { Box } from "atoms";
import { HomeImageSlider, PopUpModal } from "molecules";
import SEO from "seo/SEO";
import { getStrapiMediaUrl } from "utils/utilities";
import { HomeAbout, HomeFeatureCourse, HomeHorizontalMQ, HomeImageCards, HomeImportantLink, HomeKnowledgeResource, HomeNews, HomeNewsMarquee, HomeRecruitment } from "./molecules";

export const HomePage = ({additionalPopUp, closeAdditionalPopUp, homePageData, popUp, setPopUp, knowledgeResources}) => { 
  
  const [ newsPopUp, setNewsPopUp ] = useState(false);    

  useEffect(() => {
    setTimeout(() => {
      setNewsPopUp(true)
    }, 5000);
  });  
 
  return (
    <Box overflow="hidden">
      <SEO
        title="Welcome to Official website of Soban Singh Jeena University Almora"
        description="Official website of Soban Singh Jeena University Almora"
        keywords="SSJU,Soban Singh Jeena University Almora,Soban Singh Jeena University,Uttarakhand University,SSJ University"        
      />
      {additionalPopUp && homePageData?.popUp && popUp && <PopUpModal
        data={{
          title: homePageData?.popUp?.title,
          image: getStrapiMediaUrl(homePageData?.popUp?.image?.url),
          newsSrc: homePageData?.popUp?.Buttons,      
        }}
        isOpen={additionalPopUp && homePageData?.popUp && popUp}
        onRequestClose={() => {
          setPopUp(false)
          closeAdditionalPopUp()
        }}
        ariaHideApp={() => {
          setPopUp(false)
          closeAdditionalPopUp()
        }}
      />}  
      {newsPopUp && homePageData?.news?.length > 0 && homePageData?.news[0]?.showPopUp === true &&  <PopUpModal
        data={homePageData?.news[0]}
        isOpen={newsPopUp}
        onRequestClose={() => setNewsPopUp(false)}
        ariaHideApp={() => setNewsPopUp(false)}
      />} 
      {/* <HomeCampusSection/>  */}
      <HomeImageSlider
        slides={homePageData?.slider}
      /> 
      {homePageData?.displayCards?.length > 0 &&
        <HomeImageCards
          cards={homePageData?.displayCards}
        />
      }
      <HomeAbout
        data={homePageData?.about}
      />
      {homePageData?.horizontalMQ?.map((item, index) => (
        <HomeHorizontalMQ
          key={item?.id}
          title={item?.title}
          info={item?.description}
          hrefText={"🔗"+item?.btnTitle}
          href={item?.btnUrl}
          pl={`${index+20}rem`}
        />
      ))}            
      <HomeNews
        news={homePageData?.news}
        events={homePageData?.events}
      />
      <HomeImportantLink
        data={homePageData?.importantLink}
      />
      <HomeNewsMarquee
        mq1={homePageData?.mq1}
        mq2={homePageData?.mq2}
        mq3={homePageData?.mq3}
      />
      <HomeRecruitment
        data={homePageData?.recruitment}
      />
      <HomeFeatureCourse
        courses={homePageData?.featured}
      />
      <HomeKnowledgeResource
        data={knowledgeResources}
      />
    </Box>
  );
};
