import React from "react";

import { Box, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const CoursePartnerCard = ({data}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      maxWidth="100%"
      padding="10px 15px"
      boxShadow="1px 1px 2px 0px rgba(132,127,127,0.75)"
      borderRadius="4px"
      py="3rem"
      mx="auto"    
    >
      <Box width="20rem" height="20rem" boxShadow="0px 0px 2px 0px rgba(132,127,127,0.75)" borderRadius="50%">
        <Image
          src={getStrapiMediaUrl(data?.image?.url)}
          alt={data?.name}
          width="100%"
          height="100%"
          borderRadius="50%"          
        />
      </Box>
      <Box mt="2rem" textAlign="center">
        <Text fontSize="1.8rem" fontWeight="600" opacity="0.8" className="break-word">
          {data?.name}
        </Text>
        {data?.phone?.length > 0 && <Text fontSize="1.4rem" mt="1.5rem" opacity="0.8" className="break-word">
            Contact Number -           
            {data?.phone?.map((phone, index)=>(
              <GetLink 
                key={phone?.id} 
                url={`tel:${phone?.value}`}               
              >
                <Text as="span" mr="0.5rem" color="accent.100" fontSize="1.4rem" className="break-word">
                  {phone?.value}
                  {index < data?.phone?.length - 1 && <Text as="span" mr="0.5rem" color="accent.100">,</Text>}
                </Text>
              </GetLink>
            ))}          
        </Text>} 
        {data?.email?.length > 0 && <Text fontSize="1.4rem" mt="1.5rem" opacity="0.8" className="break-word">
          Email -
          {data?.email?.map((email, index)=>(
            <GetLink 
              key={email?.id} 
              url={`mailTo:${email?.value}`}
            >
              <Text as="span" mr="0.5rem" color="accent.100" fontSize="1.4rem" className="break-word">
                {email?.value}
                {index < data?.email?.length - 1 && <Text as="span" mr="0.5rem" color="accent.100">,</Text>}
              </Text>
            </GetLink>
          ))}                      
        </Text>}           
        {data?.website && <Text fontSize="1.4rem" mt="1.5rem"  opacity="0.8" className="break-word">
          Click here and Visit -
          <GetLink 
            url={data?.website}
          >
            <Text as="span" mr="0.5rem" color="accent.100" fontSize="1.4rem" className="break-word">{data?.website}</Text>
          </GetLink>
        </Text>}  
        {data?.info?.map((info)=>(
          <Text
            key={info?.id}
            mt="1rem"
            fontSize="1.4rem"
            opacity="0.8"
            lineHeight="2rem"
            className="break-word"
          >
            {info?.value}
          </Text>
        ))}
      </Box>
    </Flex>
  )
}