import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, Text } from "atoms";
import { ContactForm, GetLink } from "molecules";
import SEO from "seo/SEO";

export const ContactPage = () => {

  const { email, address, phone } = useSelector((state) => state.data);

  return (
    <>
      <SEO
        title={`Contact || Soban Singh Jeena University Almora`}
        description={`Contact || Soban Singh Jeena University Almora`}
        keywords={`Contact SSJU,Contact Soban Singh Jeena University Almora,Contact Soban Singh Jeena University,Contact Uttarakhand University,Contact SSJ University,Contact SSJU`}
      />
      <Grid
        mt="6rem"
        mb="3rem"
        gridTemplateColumns={{xm: "1fr 0.6fr"}}
        gridColumnGap="3rem"
        gridRowGap="3rem"
        px="3rem"
        pb="4rem"        
      >
        <Box 
          width="100%"          
        >
          <Box 
            width="100%"
            minHeight={{xs: "50rem", md: "40rem"}}
            as="iframe"            
            frameBorder="0" 
            title="map" 
            marginHeight="0" 
            marginWidth="0" 
            scrolling="no" 
            src="https://maps.google.com/maps?q=SSJ%20Campus&t=&z=13&ie=UTF8&iwloc=&output=embed"             
          />
          <Box
            position="absolute"
            width="80%"
            py={{xs: "2rem", xm: "3rem"}}
            px="3rem"        
            bg="white"
            borderRadius="0.4rem"
            zIndex="3"
            bottom={{xs: "3rem", xm: "8rem"}}
            left="10%"
            boxShadow="0 0 4px rgb(0 0 0 / 14%)"
          >
            <Grid
              gridTemplateColumns={{md: "1fr 0.6fr"}}
              gridColumnGap="3rem"
              gridRowGap="2rem"
            >
              <Option
                label="Address"
                value={address}
                url={null}
              />              

              <Grid
                gridTemplateColumns="1fr"
                gridRowGap="2rem"
              >
                <Option
                  label="Email"
                  value={email}
                  url={`mailto:${email}`}
                />
                <Option
                  label="Phone"
                  value={phone || "Updating Soon"}
                  url={phone ? `tel:${phone}` : "#"}
                />              
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <ContactForm/>
        </Box>
      </Grid>
    </>
  )
}

const Option = ({
  label,
  value,
  url
}) => (
  <Box>
    <Text 
      textTransform="uppercase" 
      mb="0.5rem"
      fontWeight="500"
    >
      {label}
    </Text>
    <GetLink
      url={url}
    >
      <Text
        fontSize="1.4rem"
        fontWeight="500"
        color={url ? "success.100" : "grey.200"}
      >
        {value}
      </Text>
    </GetLink>
  </Box>      
)