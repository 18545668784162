export const appConstants = {
  GET_EXAM_FORMS_REQUEST: "GET_EXAM_FORMS_REQUEST",
  GET_EXAM_FORMS_SUCCESS: "GET_EXAM_FORMS_SUCCESS",
  GET_EXAM_FORMS_FAILURE: "GET_EXAM_FORMS_FAILURE",
  GET_EXAM_FORM_DETAILS_REQUEST: "GET_EXAM_FORM_DETAILS_REQUEST",
  GET_EXAM_FORM_DETAILS_SUCCESS: "GET_EXAM_FORM_DETAILS_SUCCESS",
  GET_EXAM_FORM_DETAILS_FAILURE: "GET_EXAM_FORM_DETAILS_FAILURE",
  GET_EXAM_SCHEDULE_REQUEST: "GET_EXAM_SCHEDULE_REQUEST",
  GET_EXAM_SCHEDULE_SUCCESS: "GET_EXAM_SCHEDULE_SUCCESS",
  GET_EXAM_SCHEDULE_FAILURE: "GET_EXAM_SCHEDULE_FAILURE",
  GET_ENTRANCE_EXAM_INFO_REQUEST: "GET_ENTRANCE_EXAM_INFO_REQUEST",
  GET_ENTRANCE_EXAM_INFO_SUCCESS: "GET_ENTRANCE_EXAM_INFO_SUCCESS",
  GET_ENTRANCE_EXAM_INFO_FAILURE: "GET_ENTRANCE_EXAM_INFO_FAILURE",
  GET_ENTRANCE_SYLLABUS_REQUEST: "GET_ENTRANCE_SYLLABUS_REQUEST",
  GET_ENTRANCE_SYLLABUS_SUCCESS: "GET_ENTRANCE_SYLLABUS_SUCCESS",
  GET_ENTRANCE_SYLLABUS_FAILURE: "GET_ENTRANCE_SYLLABUS_FAILURE",
  GET_ALBUM_INFO_REQUEST: "GET_ALBUM_INFO_REQUEST",
  GET_ALBUM_INFO_SUCCESS: "GET_ALBUM_INFO_SUCCESS",
  GET_ALBUM_INFO_FAILURE: "GET_ALBUM_INFO_FAILURE",
  GET_ALBUMS_REQUEST: "GET_ALBUMS_REQUEST",
  GET_ALBUMS_SUCCESS: "GET_ALBUMS_SUCCESS",
  GET_ALBUMS_FAILURE: "GET_ALBUMS_FAILURE",
  GET_NEP_SYLLABUS_REQUEST: "GET_NEP_SYLLABUS_REQUEST",
  GET_NEP_SYLLABUS_SUCCESS: "GET_NEP_SYLLABUS_SUCCESS",
  GET_NEP_SYLLABUS_FAILURE: "GET_NEP_SYLLABUS_FAILURE",
  GET_SPORTS_ACTIVITY_REQUEST: "GET_SPORTS_ACTIVITY_REQUEST",
  GET_SPORTS_ACTIVITY_SUCCESS: "GET_SPORTS_ACTIVITY_SUCCESS",
  GET_SPORTS_ACTIVITY_FAILURE: "GET_SPORTS_ACTIVITY_FAILURE",
  GET_RECRUITMENTS_REQUEST: "GET_RECRUITMENTS_REQUEST",
  GET_RECRUITMENTS_SUCCESS: "GET_RECRUITMENTS_SUCCESS",
  GET_RECRUITMENTS_FAILURE: "GET_RECRUITMENTS_FAILURE",
  GET_NEWS_REQUEST: "GET_NEWS_REQUEST",
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILURE: "GET_NEWS_FAILURE",
  GET_NEWS_DETAILS_REQUEST: "GET_NEWS_DETAILS_REQUEST",
  GET_NEWS_DETAILS_SUCCESS: "GET_NEWS_DETAILS_SUCCESS",
  GET_NEWS_DETAILS_FAILURE: "GET_NEWS_DETAILS_FAILURE",
  GET_EVENTS_REQUEST: "GET_EVENTS_REQUEST",
  GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
  GET_EVENTS_FAILURE: "GET_EVENTS_FAILURE",  
  GET_RESULTS_REQUEST: "GET_RESULTS_REQUEST",
  GET_RESULTS_SUCCESS: "GET_RESULTS_SUCCESS",
  GET_RESULTS_FAILURE: "GET_RESULTS_FAILURE",
  GET_MEDIA_REQUEST: "GET_MEDIA_REQUEST",
  GET_MEDIA_SUCCESS: "GET_MEDIA_SUCCESS",
  GET_MEDIA_FAILURE: "GET_MEDIA_FAILURE",
  SEND_CONTACT_US_REQUEST: "SEND_CONTACT_US_REQUEST",
  SEND_CONTACT_US_SUCCESS: "SEND_CONTACT_US_SUCCESS",
  SEND_CONTACT_US_FAILURE: "SEND_CONTACT_US_FAILURE",
  GET_VOC_SYLLABUS_REQUEST: "GET_VOC_SYLLABUS_REQUEST",
  GET_VOC_SYLLABUS_SUCCESS: "GET_VOC_SYLLABUS_SUCCESS",
  GET_VOC_SYLLABUS_FAILURE: "GET_VOC_SYLLABUS_FAILURE",
  GET_AFFILIAION_REQUEST: "GET_AFFILIAION_REQUEST",
  GET_AFFILIAION_SUCCESS: "GET_AFFILIAION_SUCCESS",
  GET_AFFILIAION_FAILURE: "GET_AFFILIAION_FAILURE",
  GET_PROFESSIONAL_SYLLABUS_REQUEST: "GET_PROFESSIONAL_SYLLABUS_REQUEST",
  GET_PROFESSIONAL_SYLLABUS_SUCCESS: "GET_PROFESSIONAL_SYLLABUS_SUCCESS",
  GET_PROFESSIONAL_SYLLABUS_FAILURE: "GET_PROFESSIONAL_SYLLABUS_FAILURE",
  GET_FUNDING_AGENCIES_REQUEST: "GET_FUNDING_AGENCIES_REQUEST",
  GET_FUNDING_AGENCIES_SUCCESS: "GET_FUNDING_AGENCIES_SUCCESS",
  GET_FUNDING_AGENCIES_FAILURE: "GET_FUNDING_AGENCIES_FAILURE",
  GET_JOB_PORTALS_REQUEST: "GET_JOB_PORTALS_REQUEST",
  GET_JOB_PORTALS_SUCCESS: "GET_JOB_PORTALS_SUCCESS",
  GET_JOB_PORTALS_FAILURE: "GET_JOB_PORTALS_FAILURE",
  GET_R_AND_D_REQUEST: "GET_R_AND_D_REQUEST",
  GET_R_AND_D_SUCCESS: "GET_R_AND_D_SUCCESS",
  GET_R_AND_D_FAILURE: "GET_R_AND_D_FAILURE",
  GET_ABOUT_PAGE_REQUEST: "GET_ABOUT_PAGE_REQUEST",
  GET_ABOUT_PAGE_SUCCESS: "GET_ABOUT_PAGE_SUCCESS",
  GET_ABOUT_PAGE_FAILURE: "GET_ABOUT_PAGE_FAILURE",
  GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILURE: "GET_EVENT_FAILURE",
  GET_CAREER_REQUEST: "GET_CAREER_REQUEST",
  GET_CAREER_SUCCESS: "GET_CAREER_SUCCESS",
  GET_CAREER_FAILURE: "GET_CAREER_FAILURE",
  GET_FACULTY_PAGE_REQUEST: "GET_FACULTY_PAGE_REQUEST",
  GET_FACULTY_PAGE_SUCCESS: "GET_FACULTY_PAGE_SUCCESS",
  GET_FACULTY_PAGE_FAILURE: "GET_FACULTY_PAGE_FAILURE",  
  RESET_APP: "RESET_APP",
  RESET: "RESET",
}

export const dataConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",  
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",  
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",   
  UPDATE_VISIT: "UPDATE_VISIT",
  CLOSE_ADDITIONAL_POPUP: "CLOSE_ADDITIONAL_POPUP",
}