import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Text } from "atoms";
import { Layout } from "organisms";
import { getEventDetails } from "redux/actions";
import { EventInfoPage } from "templates";
import { scrollToTop } from "utils/utilities";

const EventInfo = () => {
  const { eventId } = useParams()
  
  const dispatch = useDispatch()
  const { loading, eventInfo } = useSelector(state => state.app);

  useEffect(() => {
    scrollToTop(window);
    if(eventId !== eventInfo?.id) dispatch(getEventDetails(eventId));
  }, [ eventId, dispatch, eventInfo?.id ]) 

  return (
    <>
      <Layout>
        { !loading && eventInfo?.id !== eventId ? <Text my="2rem" textAlign="center">Event Not Found</Text> : <EventInfoPage data={eventInfo}/>}        
      </Layout>
    </>
  )
}

export default EventInfo