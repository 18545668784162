import React from "react";

import { Box, Grid, Text } from "atoms";
import { MemberCard } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const SectionMembers = ({
  title,
  data
}) => {
  return (
    <Box mb="3rem">        
      {title && <Text
        color="primary.200"
        fontWeight="700"
        fontSize="2.4rem"
        lineHeight="4rem"
        borderBottom="3px solid"
        borderColor="primary.100"
        mb="1.5rem"
      >
        {title}
      </Text>}
      <Grid
        gridTemplateColumns={{md: "repeat(2, 1fr)"}}
        gridGap="2rem"                          
      >
        {data?.map((item, index) => (
          <MemberCard 
            data={{
              imageURL: item?.image?.data && getStrapiMediaUrl(item?.image?.data?.attributes?.url),
              memberName: item?.name,
              designation: item?.designation,
              phone: item?.phone,
              email: item?.email,
              specialization: item?.specialization,
              profileURL: item?.profile?.data && getStrapiMediaUrl(item?.profile?.data?.attributes?.url)
            }}
            key={index} 
          />
        ))}        
      </Grid>
    </Box>
  )
}
