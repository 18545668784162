import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Text } from "atoms";
import { Layout } from "organisms";
import { getCareerDetails } from "redux/actions";
import { CareerInfoPage } from "templates";
import { scrollToTop } from "utils/utilities";

const CareerInfo = () => {
  const { careerId } = useParams()
  
  const dispatch = useDispatch()
  const { loading, careerInfo } = useSelector(state => state.app);

  useEffect(() => {
    scrollToTop(window);
    if(careerId !== careerInfo?.id) dispatch(getCareerDetails(careerId));
  }, [ careerId, dispatch, careerInfo?.id ]) 

  return (
    <>
      <Layout>
        { !loading && careerInfo?.id !== careerId ? <Text my="2rem" textAlign="center">Recruitment Not Found</Text> : <CareerInfoPage data={careerInfo}/>}        
      </Layout>
    </>
  )
}

export default CareerInfo