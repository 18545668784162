import React, { useEffect } from "react";

import { Layout } from "organisms";
import { AffiliationPage } from "templates";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";

const Affiliation = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title={`Affiliation || Soban Singh Jeena University Almora`}
        description={`Affiliation || Soban Singh Jeena University Almora`}
        keywords={`Affiliation SSJU,Affiliation Soban Singh Jeena University Almora,Affiliation Soban Singh Jeena University,Affiliation Uttarakhand University,Affiliation SSJ University,Affiliation SSJU`}
      />
      <Layout>        
        <AffiliationPage/>        
      </Layout>
    </>
  )
}

export default Affiliation