import React, { useEffect } from "react";
import { BsImage } from "react-icons/bs";

import { PageTitle } from "atoms";
import { Layout } from "organisms";
import SEO from "seo/SEO";
import { MediaCoveragePage } from "templates";
import { scrollToTop } from "utils/utilities";

const MediaCoverage = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title={`Media Coverages || Soban Singh Jeena University Almora`}
        description={`Media Coverages || Soban Singh Jeena University Almora`}
        keywords={`Media Coverages SSJU,Media Coverages Soban Singh Jeena University Almora,Media Coverages Soban Singh Jeena University,Media Coverages Uttarakhand University,Media Coverages SSJ University,Media Coverages SSJU`}
      />
      <Layout>
        <PageTitle
          icon={<BsImage size="2.5rem"/>}
          title="Media Coverages SSJU"
        />
        <MediaCoveragePage />
      </Layout>
    </>
  )
}

export default MediaCoverage