import React from "react";

import { Box, Text } from "atoms";

export const LibraryServices = () => {
  return (
    <Box py="2rem" px="1rem">
      <Text
        fontSize="1.6rem"
        fontWeight="bold"
        textAlign="center"
        mb="1rem"
        textTransform="uppercase"
        color="#0d47a1"
      >
        Services We Provide
      </Text>
      <Box my="2rem">
        <Text as="p" fontSize="1.4rem" color="grey.300" lineHeight="2rem">
          <Text
            as="span"
            fontWeight="bold"
            color="grey.500"
            fontSize="inherit"
          >
            Inter-library loan : {" "}          
          </Text>
          The Central Library offers inter-library loan service to its users
          for books and periodicals not available in this Library by procuring them on loan
          from university central library or from other libraries through DELNET.
        </Text>
        <Text as="p" fontSize="1.4rem" color="grey.300" lineHeight="2rem">
          <Text
            as="span"
            fontWeight="bold"
            color="grey.500"
            fontSize="inherit"
          >
            Reference Service : {" "}
          </Text>
          Reference person in the reading room and reference room assists in locating the required material.
        </Text>
        <Text as="p" fontSize="1.4rem" color="grey.300" lineHeight="2rem">
          <Text
            as="span"
            fontWeight="bold"
            color="grey.500"
            fontSize="inherit"
          >
            Book Bank :{" "}
          </Text>
          Library provides Book Bank facility to general students, and students
          belonging to various reservation categories. Such students get books from book
          bank section for a year on a nominal payment of 10 percent cost of the book.
        </Text><Text as="p" fontSize="1.4rem" color="grey.300" lineHeight="2rem">
          <Text
            as="span"
            fontWeight="bold"
            color="grey.500"
            fontSize="inherit"
          >
            Reprography Service :{" "}
          </Text>
          Based on requisition, Xerox copies of documents are made
          available mainly for academic purpose, subject to the condition that the documents
          protected under the Copy Right Act will be reproduced entirely for personal use.
        </Text>       
      </Box>      
    </Box>
  )
}