import React from "react";
import { useSelector } from "react-redux";

import { Box } from "atoms";
import { Footer, Header, HeaderContact, HeaderLogo } from "organisms";

export const Layout = ({children}) => {

  const { headerBtn, navbar, footer, email, addressSm, socials } = useSelector(state => state.data);

  return (
    <>  
      <HeaderContact
        headerBtn={headerBtn}        
        email={email}        
        addressSm={addressSm}        
      /> 
      <HeaderLogo/>
      <Header
        navbar={navbar}
      />
      <Box as="main">
        {children}
      </Box>   
      <Footer
        footer={footer}        
        socials={socials}
      />
    </>
  );
};
