import React, { useEffect } from "react";
import { BsJournalBookmark } from "react-icons/bs";

import { PageTitle } from "atoms";
import { Layout } from "organisms";
import { ProfessionalSyllabusPage } from "templates";
import { scrollToTop } from "utils/utilities";

const ProfessionalSyllabus = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <Layout> 
      <PageTitle
        icon={<BsJournalBookmark size="2.5rem" />}
        title="Professional Courses Syllabus"
      />     
      <ProfessionalSyllabusPage/>      
    </Layout>
  )
}

export default ProfessionalSyllabus