import React, { useEffect, useState } from "react";
import { BsNewspaper } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { NewsCard, PageHead } from "molecules";
import { getNews } from "redux/actions";
import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";

export const NewsTypePage = ({seoData, type}) => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");  

  const dispatch = useDispatch();
  const { news } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getNews(page, query, type))
  }, [page, query, dispatch, type]);

  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);    

  return (
    <>      
      <SEO
        title={`${seoData} || Soban Singh Jeena University Almora`}
        description={`${seoData} || Soban Singh Jeena University Almora`}
        keywords={`${seoData} SSJU,${seoData} Soban Singh Jeena University Almora,${seoData} Soban Singh Jeena University,${seoData} Uttarakhand University,${seoData} SSJ University,${seoData} SSJU`}
      />                              
      <PageHead
        Icon={<BsNewspaper size="2.5rem"/>}          
        title={seoData}
        query={query}
        setQuery={setQuery}
        page={page}
        setPage={setPage}
        resultPerPage={news?.resultPerPage}
        totalCount={news?.totalCount}    
        bg={true}    
      >
        <Grid
          gridTemplateColumns={{md: "1fr 1fr", xm: "1fr 1fr 1fr", lg:"1fr 1fr 1fr 1fr"}}
          gridColumnGap="1.5rem"
          gridRowGap="3rem"
          mt="3rem"             
        >
          {news?.news?.map((item) => (<NewsCard news={item} key={item.id} />))}
        </Grid>
      </PageHead>                    
    </>
  )
}