import React from "react";

import { Box, Flex, PillHeading, Image, Text } from "atoms";

export const ProfileCard = ({
  img,
  name,
  designation,
  timeline,  
  children
}) => {
  return (
    <Flex
      alignItems="center"
      px="2rem"
      py="2.4rem"
      bg="white"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
      flexDirection="column"
      borderRadius="1rem"
      height="100%"
    >
      <Box width="13rem" height="13rem">
        <Image
          src={img}
          alt={name}
          width="100%"
          height="100%"
          borderRadius="50%"
          objectFit="cover"
        />
      </Box>
      <Text
        fontSize="2rem"
        color="black"
        opacity="0.9"
        fontWeight="600"
        mt="2.4rem"    
        textAlign="center"        
      >
        {name}
      </Text>
      {designation &&  <Text
        fontSize="1.6rem"
        color="black"
        fontWeight="500"
        textAlign="center"        
        my="2rem"    
        opacity="0.8"        
      >
        {designation}
      </Text>}      
      <PillHeading
        title={timeline}
        fontSize="1.6rem"              
        fontWeight="600"        
        width="fit-content"       
      />
      {children}
    </Flex>
  )
}