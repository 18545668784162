import React from "react";

import { Box, Grid } from "atoms";
import { PdfCard } from "molecules";
import { formatDate, getStrapiMediaUrl } from "utils/utilities";
import { AddingSoon } from "../AddingSoon";

export const MarksDistribution = ({ data }) => {
  return (
    <>      
      <Box className="tab-body">
        <Box
          width="fit-content"
          mx="auto"
        >
          <Grid
            gridTemplateColumns={data?.length ? { xm: "1fr 1fr" } : "1fr"}
            gridRowGap="1.5rem"      
            my="2rem"      
            px="1rem"
          >
            {data?.length > 0 ? data?.map((item, index) => (
              <PdfCard
                key={index}
                title={item?.label}
                href={getStrapiMediaUrl(item?.file?.data?.attributes?.url)}
                addedOn={formatDate(item?.file?.data?.attributes?.updatedAt)}
              />
            )) : <AddingSoon/>}      
          </Grid>
        </Box>
      </Box>
    </>
  )
}