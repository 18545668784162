import React from "react";

import { Box, Grid, Image, Text } from "atoms";
import { getStrapiMediaUrl } from "utils/utilities";

export const SectionImages = ({
  title,
  images
}) => {
  return (
    <Box mb="3rem">        
      {title && <Text
        color="primary.200"
        fontWeight="700"
        fontSize="2.4rem"
        lineHeight="4rem"
        borderBottom="3px solid"
        borderColor="primary.100"
        mb="1.5rem"
      >
        {title}
      </Text>}
      <Grid
        gridTemplateColumns={{
          xs: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)"
        }}
        gridGap="2rem"        
      >
        {images?.map((image, index) => (
          <Box
            width="100%"
          >
            <Image
              key={index}
              src={getStrapiMediaUrl(image?.attributes?.url)}
              alt={index}
              width="100%"
              height="auto" 
              objectFit="contain"             
            />
          </Box>
        ))}
      </Grid>
    </Box>
  )
}