import React, { useEffect, useState } from "react";

import { Box, Flex, InputDebounce, Text } from "atoms";
import { GetLink } from "molecules";
import SEO from "seo/SEO";
import { generatePublicUrl } from "utils/utilities";

const steps = [
  "Click on your Course",
  "You will be redirected to Result Page",
  "Fill your Enrollment Number and Date of Birth",
  "View and Download Result"
]

const CourseOption = ({data}) => (
  <Box as="li" mb="1.5rem" display={data?.hide ? "none" : "li"} width="fit-content">
    <GetLink url={generatePublicUrl(data?.href)}>
      <Text fontWeight="600" fontSize="1.5rem" ml="0.4rem" color="accent.100">
        {data?.title}
        <Text as="span" ml="0.5rem" fontSize="1.2rem" color="danger.100">- click to view🔗</Text>
      </Text>                    
    </GetLink>
  </Box>
)

export const ResultPage = ({examName, examCourses}) => {

  const [ query, setQuery ] = useState("")
  const [ filtered, setfiltered ] = useState([])

  useEffect(() => {
    const search = () => {    
      const filteredList = examCourses?.filter(item => {
        const title = item?.title?.toLocaleLowerCase().replace('.', '');
        const searchQuery = query?.toLocaleLowerCase().replace('.', '');
        return title.includes(searchQuery);
      });
      setfiltered(filteredList)      
    }
    if(query === ""){
      setfiltered([])
      return
    }else{
      search()
    }
  }, [query, examCourses])

  useEffect(() => {    
    setfiltered([]);
    setQuery("");
  }, [examCourses])

  return (
    <>
      <SEO
        title={`${examName} Result || Soban Singh Jeena University Almora`}
        description={`${examName} Result || Soban Singh Jeena University Almora`}
        keywords={`${examName} Result SSJU,${examName} Result Soban Singh Jeena University Almora,${examName} Result Soban Singh Jeena University,${examName} Result Uttarakhand University,${examName} Result SSJ University,${examName} Result SSJU`}
      />
      <Box width={{xs: "100%", xm: "80%"}} mx="auto" px="3rem">
        <Box mt="3rem">
          <Box bg="success.100" fontSize="1.4rem" fontWeight="600"  px="2rem" py="1rem" color="white" borderTopLeftRadius="0.4rem" borderTopRightRadius="0.4rem">
            Online Results announced of {examName} for the following Courses
          </Box>        
          <Box px="2rem" py="1rem" border="1px solid" borderColor="grey.100" borderBottomLeftRadius="0.4rem" borderBottomRightRadius="0.4rem">
            <Flex
              alignItems="center"
              justifyContent="end"              
              px="1rem"
              mb="2rem"
            >
              <Box my="2rem">
                <InputDebounce            
                  name="search-result"
                  type="text"
                  id="search-result"                    
                  label="Search Result"                
                  value={query}          
                  setValue={setQuery}                                        
                />
              </Box>               
            </Flex>
            <Box as="ul" m="0" px="1rem" className="list-image">
              {filtered.length > 0 ?  filtered?.map((item, index) => (
                  <CourseOption
                    key={index}
                    data={item}
                  />
                ))
              : examCourses?.length > 0 &&  examCourses?.map((item, index) => (
                <CourseOption
                  key={index}
                  data={item}
                />
              ))}                        
            </Box>          
          </Box>
        </Box>
        <Box my="3rem">
          <Box bg="warning.200" fontSize="1.4rem" fontWeight="600"  px="2rem" py="1rem" color="white" borderTopLeftRadius="0.4rem" borderTopRightRadius="0.4rem">
            Steps To View Results
          </Box>
          <Box px="2rem" py="1rem" border="1px solid" borderColor="grey.100" borderBottomLeftRadius="0.4rem" borderBottomRightRadius="0.4rem">
            <Box as="ul" m="0" px="1rem" className="list-image">
              {steps?.map((item, index) => (
                <Box as="li" key={index} mb="1rem">
                  <Text fontWeight="600" fontSize="1.2rem">
                    Step{" "}{index + 1}-
                    <Text as="span" fontWeight="500" fontSize="1.2rem" ml="0.4rem">{item}</Text>                    
                  </Text>
                </Box>
              ))}              
            </Box>          
          </Box>
        </Box>       
      </Box>
    </>
  )
}