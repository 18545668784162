import React from "react";

import { Box, Button, Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { printINR, generatePublicUrl } from "utils/utilities";

const steps = [
  "Apply for Registration Form.",
  "Fill and Submit the Registration Form.",
  "Login Credential will be sent to student on registered email-id. Login with shared Login Credentials.",
  "After Login, Fill Complete Form: Center Preference, Personal Details, Address and Family details, Academic Details, Weightage and do the confirmation.",
  "Upload Photo and Signature",
  "Proceed for Payment",
  "Print Payment Reciept and Form",
]

export const FormInfo = ({
  showTable,
  showStpes,
  showTC,
  startDate,
  endDate,
  admitCardDate,
  admitCardLink,
  examDate,
  fee,
  otherDocs,
  applyLink,
  closed,
  name,
  year 
}) => {

  const tc = [
    {
      "title": "प्रवेश पंजीकरण- आवश्यक निर्देश",
      "list": [
        "प्रवेश पंजीकरण पोर्टल दिनांक " + startDate + " को सायं 07 बजे से छात्रों हेतु खोला जाएगा।",
        "प्रवेश पंजीकरण पोर्टल दिनांक " + endDate + " तक 11:59 PM तक खुला रहेगा।",
        "प्रवेश पंजीकरण से पूर्व दिए गए निर्देशों को सावधानीपूर्वक पढ़ कर समझ लें।",
        "एक बार आनलाईन प्रवेश पंजीकरण में छात्र द्वारा दी गई/भरी गई सूचनाओं में किसी भी प्रकार का सुधार संभव नहीं है।",
        "प्रवेश लेने वाला अभ्यर्थी स्वयं अथवा अपने सामने ही आनलाईन पंजीकरण की सभी सूचनाएं अंकित करे।",
        "आनलाईन माध्यम से जमा की जाने वाली सभी प्रकार की फीस छात्र अपने डेबिट/क्रेडिट/यू0पी0आई0 के माध्यम से करे क्योकि नेटवर्क अथवा अन्य तकनीकी खामी के कारण भुगतान फेल होने की दृष्टि में बैंक द्वारा भुगतान छात्र के ही खाते में हो सकेगा। अन्यथा की स्थिति के लिये छात्र स्वयं जिम्मेदार होगा।",
        "पंजीकरण फार्म Lock/Submit करने से पूर्व एक बार अंकित की गई सारी सूचनाओं को भली-भांति पुनः जॉच लें।",
      ]
    },
    {
      "title": "Admission Registration- Required Instructions",
      "list": [
        "Get scanned copy of your passport (PP) size photograph and Signature in JPEG/JPG format. Make sure that Photo Image should be less than 400kb and Signature less than 200kb in size. You will be asked to upload the Photo & Sign while filling up the Application form.",
        "Complete the application process after paying the requisite fees and take the print out of confirmation page for further use (if the need arises).",
        "Regularly check the official University website for latest updates and information.",
        "If the transaction is failed, the money will be reversed back to your account in 7 working days. If the money is not refunded in 7 working days you are requested to share the Transaction ID, Transaction Date, Transaction Amount to helpdeskssju@gmail.com.",
        "If the student applies for the examination form and makes the payment, the amount is debited from his/her account but if he/she is not able to download the receipt the following transaction will be a failed transaction and the amount will be refunded back within 7 working days. Those student are requested to again make the payment and download the receipt",
        "Maximum Transaction per day from a single bank account is 10, if anyone tries to make the payment more than 10 times a day from a single bank account/ debit card/credit card then the transaction will be failed/not authorised. Debited Money from the account in such cases will be refunded back with in 7 working days or the payee will have to contact the bank from which he/she has done the transaction.",        
      ]
    }
  ]

  return (
    <Box width={{xs: "100%", xm: "80%"}} mx="auto">
      {showTable && <Box as="table" width="100%" borderRadius="1rem">
        <Box as="thead" bg="success.100" color="white">
          <Box as="tr" fontSize="1.4rem" fontWeight="600" >
            <Box as="th" width="40%" textAlign="start" px="2rem" py="1rem">Particulars</Box>
            <Box as="th" textAlign="start" px="2rem" py="1rem">Start Date</Box>
            <Box as="th" textAlign="start" px="2rem" py="1rem">End Date</Box>
          </Box>
        </Box>
        <Box as="tbody" bg="grey.50" color="grey.400">
          <Box as="tr">
            <Box as="td" textAlign="start" px="2rem" py="1rem">Fee Submission</Box>
            <Box as="td" textAlign="start" px="2rem" py="1rem" fontWeight="600">{startDate ? startDate : "Declared Soon"}</Box>
            <Box as="td" textAlign="start" px="2rem" py="1rem" fontWeight="600">{endDate}{" "}(Online Mode - Debit Card/Credit Card/Net Banking Mode)</Box>
          </Box>
          <Box as="tr">
            <Box as="td" textAlign="start" px="2rem" py="1rem">Admit Card Downloading</Box>
            <Box as="td" colSpan="2" textAlign="start" px="2rem" py="1rem" fontWeight="600">
              <Flex alignItems="center">
                <Text fontSize="1rem">
                  {admitCardDate ? admitCardDate : "Declared Soon"}
                </Text>
                {admitCardDate && <GetLink url={generatePublicUrl(admitCardLink)}>
                  <Button width="fit-content" py="0.4rem" px="0.8rem" variant="danger" ml="2rem">
                    <Text fontSize="1.2rem">
                      Download Now
                    </Text> 
                  </Button>
                </GetLink>}
              </Flex>
            </Box>                
          </Box>
          <Box as="tr">
            <Box as="td" textAlign="start" px="2rem" py="1rem">Tentative Examination Date</Box>
            <Box as="td" colSpan="2" textAlign="start" px="2rem" py="1rem" fontWeight="600">{examDate ? examDate : "Declared Soon"}</Box>                
          </Box>
          <Box as="tr">
            <Box as="td" textAlign="start" px="2rem" py="1rem">Exam Name</Box>
            <Box as="td" textAlign="start" px="2rem" py="1rem" fontWeight="600" colSpan="2">{name}-{year}</Box>              
          </Box>
          {fee && <Box as="tr">
            <Box as="td" textAlign="start" px="2rem" py="1rem">Fees</Box>
            <Box as="td" colSpan="2" textAlign="start" px="2rem" py="1rem" fontWeight="600">{printINR(fee)}</Box>                
          </Box>}
        </Box>
      </Box>}

      {showStpes && <Box mt="3rem">
        <Box bg="accent.100" fontSize="1.4rem" fontWeight="600"  px="2rem" py="1rem" color="white" borderTopLeftRadius="0.4rem" borderTopRightRadius="0.4rem">
          Steps To Apply Online
        </Box>
        <Box px="2rem" py="1rem" border="1px solid" borderColor="grey.100" borderBottomLeftRadius="0.4rem" borderBottomRightRadius="0.4rem">
          <Box as="ul" m="0" px="1rem" className="list-image">
            {steps?.map((item, index) => (
              <Box as="li" key={index} mb="1rem">
                <Text fontWeight="600" fontSize="1.2rem">
                  Step {index+1}-
                  <Text as="span" fontWeight="500" fontSize="1.2rem" ml="0.4rem">{item}</Text>
                </Text>
              </Box>
            ))}              
          </Box>
          <Text fontWeight="600" fontSize="1.4rem" color="danger.100">
            NOTE - Candidates are advised to submit the requisite fee by using Online Mode to submit Exam Form.
          </Text>
        </Box>
      </Box>}

      {showTC &&  <Box mt="3rem">
        <Box bg="accent.100" fontSize="1.4rem" fontWeight="600"  px="2rem" py="1rem" color="white" borderTopLeftRadius="0.4rem" borderTopRightRadius="0.4rem">
          TERMS, CONDITIONS AND INSTRUCTIONS
        </Box>
        <Box px="2rem" py="1rem" border="1px solid" borderColor="grey.100" borderBottomLeftRadius="0.4rem" borderBottomRightRadius="0.4rem">
          {tc?.map((item, index) => (
            <Box key={index}>
              <Text fontSize="1.6rem" fontWeight="600" my="1rem" textAlign="center">{item.title}</Text>
              <Box as="ul" my="1.5rem" px="1rem" className="list-image">
              {item.list.map((li, i) => (
                <Box as="li" key={i} mb="1rem">
                  <Text fontWeight="500" fontSize="1.2rem" ml="0.4rem">{li}</Text>                        
                </Box>
              ))}              
              </Box>
            </Box>
          ))}           
        </Box>
      </Box>}

      {otherDocs && <Box mt="3rem">
        <Box bg="danger.100" fontSize="1.4rem" fontWeight="600" px="2rem" py="1rem" color="white" borderTopLeftRadius="0.4rem" borderTopRightRadius="0.4rem">
          Other Documents
        </Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          border="1px solid" 
          borderColor="grey.100" 
          borderBottomLeftRadius="0.4rem" 
          borderBottomRightRadius="0.4rem"
        >
          {otherDocs?.map((btn) => (
            <Box maxWidth="20rem" mx="auto" my="2rem" key={btn.id}>
              <GetLink url={generatePublicUrl(btn?.href)}>
                <Button
                  variant="info"
                  py="0.6rem"
                  px="1rem"
                >
                  <Text fontize="1.4rem" fontWeight="500">
                    {btn?.title}
                  </Text>
                </Button>
              </GetLink>
            </Box>
          ))}
        </Flex>
      </Box>}
      {closed ? 
        <Box my="2rem" textAlign="center" fontSize="1.4rem" fontWeight="600" color="gray">Online Application Closed</Box>
      : <Box maxWidth="20rem" mx="auto" my="3rem">
        <GetLink url={generatePublicUrl(applyLink)}>
          <Button
            variant="success"
            py="0.6rem"
          >
            <Text fontize="1.4rem">
              Apply Online
            </Text>
          </Button>
        </GetLink>
      </Box>}
    </Box>
  )
}

FormInfo.defaultProps = {
  showTable: true,
  showStpes: true,
  showTC: true,  
}