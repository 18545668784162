import React from "react";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { LinkCard } from "molecules";

export const Description = ({
  image,
  title,
  createdAt,
  desc,
  src
}) => {
  return (
    <Flex maxWidth="120rem" px="1rem" py="3rem" mx="auto" flexDirection={{ xs: "column", xm: "row"}} style={{ gap: "2rem"}}>
      <Box flex="1">
        {image && <Box 
          width={{xs: "100%", md: "24rem", lg:"36rem"}}             
          className="float-left"            
          mb="2rem"
          mx={{ xs: "auto", md: "unset" }}
          pr={{ xs: "0", md: "2rem" }}
        >
          <Image
            src={image}
            alt={title}
            width="100%"
            height="100%"
            objectFit="contain"
            preview={true}
          />
        </Box>}
        <Box>
          <Text 
            fontSize="1.8rem" 
            fontWeight="600" 
            color="accent.200" 
            textAlign={{xs: "center", md: "unset"}} 
            lineHeight="2.6rem"
            mb="1rem"              
          >
            {title}
            <Text 
              as="span" 
              color="grey.300" 
              fontSize="1.4rem"
            > - {createdAt}</Text>
          </Text>          
          <Box>
            {desc?.split("<br/>").map((item, index) => (
              <Text 
                key={index} 
                fontSize="1.6rem" 
                fontWeight="420" 
                color="grey.300" 
                mb="0.6rem"                   
                lineHeight="2.4rem"
                textAlign={{xs: "center", md: "unset"}}
              >
                {item}
              </Text>
            ))}
          </Box>            
        </Box>
      </Box>
      {src?.length > 0 && <Box
        width="100%"
        bg="#f3f3f3"
        p="2rem 1.5rem"                    
        flex="0.3"        
        borderRadius="0.6rem"
        height="fit-content"
      >
        <Text           
          fontSize="2rem"
          fontWeight="800"            
          color="primary.200"
          mb="2rem"
        >
          Related Links
        </Text>
        <Grid
          gridTemplateColumns="1fr"
          gridGap="1rem"
        >
          {src?.map((src, index) => (
            <LinkCard
              key={src?.id}
              title={src?.title}
              url={src?.url}
            />
          ))}
        </Grid>            
      </Box>}              
    </Flex>
  )
}
