import React, { useEffect } from "react";

import { Layout } from "organisms";
import SEO from "seo/SEO";
import { RAndDPage } from "templates";
import { scrollToTop } from "utils/utilities";

const RAndD = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <Layout>
      <SEO
        title="Research And Development || Soban Singh Jeena University Almora"
        description="Research And Development || Soban Singh Jeena University Almora"
        keywords="Research And Development SSJU,Research And Development Soban Singh Jeena University Almora,Research And Development Soban Singh Jeena University,Research And Development Uttarakhand University,Research And Development SSJ University,Research And Development SSJU"
      />
      <RAndDPage/>
    </Layout>
  )
}

export default RAndD;