import React, { useEffect } from "react";

import { VocPageTitle } from "atoms";
import { VocLayout } from "organisms";
import { VocSyllabusPage } from "templates";
import { scrollToTop } from "utils/utilities";

const VOCSyllabus = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <VocLayout>
        <VocPageTitle
          title="Download Syllabus"
        />
        <VocSyllabusPage/>
      </VocLayout>
    </>
  )
}

export default VOCSyllabus