import React from "react";

import { Box, Button, Grid, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { BtnColors } from "utils/constant-data";
import { formatDate, generatePublicUrl } from "utils/utilities";

export const RecruitmentCard = ({data}) => {
  return (
    <Box
      width="100%"    
      boxShadow="0px 10px 25px rgba(0, 0, 51, 0.08)"    
      borderRadius="0.8rem"        
      bg="white"
    >
      <Box
        width="100%"
        height="30rem"
        bg="white"
      >
        <Image
          src={generatePublicUrl(data?.image)}
          alt={data?.title}
          width="100%"
          height="100%"
          borderRadius="4px"
          objectFit="contain"
          preview={true}
        />
      </Box>
      <Box pt="1.5rem" px="2.5rem" borderTop="1px solid" borderColor="grey.100">
        <GetLink
          url={`/career/${data?.id}`}          
        >
          <Text fontSize="1.6rem" fontWeight="600" color="grey.200" _hover={{ color: "primary.100" }} transition="color 0.3s">
            {data?.title}
          </Text>
        </GetLink>
        <Text fontSize="1.4rem" fontWeight="500" color="grey.400" mt="3rem" className="text-justify">
          {data?.desc}
        </Text>
        <Text mt="1rem" fontSize="1.2rem" fontWeight="500" color="grey.300">
          {formatDate(data?.createdAt)}
        </Text>        
      </Box>
      <Grid
        gridTemplateColumns="repeat(2, 1fr)"
        gridColumnGap="0.5rem"
        gridRowGap="1rem"
        mt="3rem"
        pb="1rem"
        px="1rem"
      >
        {data?.recuritmentSrc?.map((src, index)=> (
          <GetLink url={generatePublicUrl(src?.href)}>
            <Button
              variant={BtnColors[index]}
              width="100%"
              py="1rem"
              px="1.2rem"              
            >
              <Text fontSize="1.2rem" fontWeight="400" >
                {src?.title}
              </Text>                            
            </Button>
          </GetLink>
        ))}                        
      </Grid>
    </Box>
  )
}