import React from "react";

import { Box, Text } from "atoms";
import { LibraryStats } from "utils/constant-data";

export const LibraryCollections = () => {
  return (
    <Box py="2rem" px="1rem">
      <Text
        fontSize="1.6rem"
        fontWeight="bold"
        textAlign="center"
        mb="1rem"
        textTransform="uppercase"
        color="#0d47a1"
      >
        Our Resources
      </Text>
      <Box mb="2rem">
        <Text as="p" fontSize="1.4rem" color="grey.300" lineHeight="2rem">
          The Library has a rich collection of books on Science, Arts, Humanities,
          Education, Law and Management Sciences. The collection includes Text Books,
          Reference Books, Hard-bound Volumes of Journals and Thesis as mentioned
          hereunder.
        </Text>
      </Box>
      <Box
        as="table"        
        width="100%"
        textAlign="center"
        my="2rem"        
      >
        <Box
          as="tr"          
          width="100%"
          textAlign="center"          
        >
          <Box
            as="th"
            border="1px solid #ccc"
            borderCollapse="collapse"
            py="1rem"
            fontSize="1.3rem"
            textTransform="capitalize"
            color="#2e81f4"
          >
            Resources
          </Box>
          <Box
            as="th"
            border="1px solid #ccc"
            borderCollapse="collapse"
            py="1rem"
            fontSize="1.3rem"
            textTransform="capitalize"
            color="#2e81f4"
          >
            Numbers
          </Box>          
        </Box>
        {LibraryStats?.map((item, index) => (
          <Box
            as="tr"
            key={index}
          >
            <Box
              as="td"
              border="1px solid #ccc"
              borderCollapse="collapse"
              py="1rem"
              fontSize="1.3rem"            
              color="grey.500"
            >
              {item?.label}
            </Box>
            <Box
              as="td"
              border="1px solid #ccc"
              borderCollapse="collapse"
              py="1rem"
              fontSize="1.3rem"            
              color="grey.500"
              fontWeight="600"
            >
              {item?.value}
            </Box>           
          </Box>
        ))}        
      </Box>
    </Box>
  )
}