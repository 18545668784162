import React from "react";
import { FcAlarmClock }  from "react-icons/fc";

import { Box, Flex, Image,  Text } from "atoms";
import { getStrapiMediaUrl } from "utils/utilities";

export const CourseCard = ({data}) => {
  return (
    <Box width="100%">
      <Box
        width="100%"
        height="24rem"
      >
        <Image
          src={getStrapiMediaUrl(data?.image?.url)}
          alt={data?.name}          
          width="100%"
          height="100%"
          borderRadius="4px 4px 0 0"
          objectFit="cover"
        />
      </Box>
      <Flex
        bg="white"
        py="2rem"
        px="1rem"
        borderBottomLeftRadius="0.4rem"
        borderBottomRightRadius="0.4rem"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize="1.6rem"
          fontWeight="600"
          color="accent.200"          
        >
          {data?.name}
        </Text>
        <Flex color="grey.200"> 
          <Box mt="0.2rem" mr="0.4rem">
            <FcAlarmClock size="1.5rem"/>
          </Box>         
          <Text fontWeight="600" fontSize="1.4rem">
            {data?.duration}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}