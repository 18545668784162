import React from "react";

import { Box } from "atoms";
import { FormInfo } from "./molecules";
import SEO from "seo/SEO";
import { formatDate } from "utils/utilities";

export const EntranceExamInfoPage = ({data}) => {

  return (
    <>
      <SEO
        title={`${data?.name} ${data?.year} - Entrance Exam Form || Soban Singh Jeena University Almora`}
        description={`${data?.name} - Entrance Exam Form - ${data?.year} || Soban Singh Jeena University Almora`}
        keywords={`${data?.name} - Entrance Exam Form - ${data?.year} SSJU,${data?.name} - Entrance Exam Form - ${data?.year} Soban Singh Jeena University Almora,${data?.name} - Entrance Exam Form - ${data?.year} Soban Singh Jeena University,${data?.name} - Entrance Exam Form - ${data?.year} Uttarakhand University,${data?.name} - Entrance Exam Form - ${data?.year} SSJ University,${data?.name} - Entrance Exam Form - ${data?.year} SSJU,Images Soban Singh Jeena University Almora,Images Soban Singh Jeena University,Images Uttarakhand University,Images SSJ University`}
      />
      <Box overflow="hidden" py="3rem" px="3rem" maxWidth="100vw">
        <FormInfo
          startDate={data?.startDate ? formatDate(data?.startDate) : null}
          endDate={data?.endDate ? formatDate(data?.endDate) : null}
          admitCardDate={data?.admitCardDate ? formatDate(data?.admitCardDate) : null}
          admitCardLink={data?.admitCardLink}
          examDate={data?.examDate ? formatDate(data?.examDate) : null}
          otherDocs={data?.entranceExamDocs}
          applyLink={data?.applyLink}
          closed={data?.closed ? data?.closed : null}
          name={data?.name}
          year={data?.year}
        />       
      </Box>
    </>
  )
}