import React from "react";

import { Box, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { BiLinkExternal } from "react-icons/bi";

export const KnowledgeResourceCard = ({name, info, link, img}) => {
  return (
    <Flex
      width="100%"
      height="100%"
      boxShadow="0px 0px 10px 0px rgba(0,0,0,0.1)"
      border="1px solid #e0e0e0"
      borderRadius="0.4rem"
      flexDirection="column"
      justifyContent="space-between"
    >      
      <Box p="1rem">
        <Box width="100%" height="12rem" borderBottom="1px solid #999" textAlign="center">
          <Image
            src={img}
            alt={name}
            width="100%"
            height="100%"
            objectFit="contain"
          />
        </Box>
        <Box mt="1rem">
          <Text fontWeight="600" fontSize="1.6rem" color="grey.500">{name}</Text>
        </Box>
        <Box mb="1rem">
          {info?.map((item) => (
            <Text key={item?.id} mt="0.8rem" fontWeight="400" fontSize="1.4rem" color="grey.300" opacity="0.8" className="text-justify" lineHeight="2rem">{item?.value}</Text>
          ))}          
        </Box>
      </Box>
      <Box>
        <GetLink url={link}>
          <Flex
            alignItems="center"
            justifyContent="center"
            p="1rem"   
            color="accent.100"
            _hover={{
              bg: "accent.200",
              color: "white"
            }}     
            transition="background 0.3s, color 0.3s"    
          >
            <Flex
              alignItems="center"
              justifyContent="center"      
              fontSize="2rem"
              mr="0.5rem"
            >
              <BiLinkExternal/>
            </Flex>
            <Text
              fontSize="1.5rem"
              fontWeight="600"              
            >
              Learn More
            </Text>
          </Flex>
        </GetLink>
      </Box>
    </Flex>
  )
}