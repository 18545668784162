import React from "react";

import { Box, Text } from "atoms";
import { GetLink } from "molecules";
import { ADMBVocContact } from "utils/constant-data";

export const BVocContact = () => {
  return (
    <Box>
      <Box py="2rem" color="danger.100">
        NOTE - सोबन सिंह जीना विश्वविद्यालय अल्मोडा के तहत संचालित व्यवसायिक शिक्षा एवं कौशल विकास केंद्र के उपरोक्त तीन पाठ्यक्रमों में सोबन सिंह जीना परिसर अल्मोड़ा में किसी भी कक्षा में अध्ययनरत विद्यार्थी Addon कोर्स के तहत उपरोक्त में से किसी भी कोर्स में प्रवेश ले सकता है। सीटों की संख्या सीमित होने के कारण इंटरमीडिएट में प्राप्त अंकों की वरीयता क्रम अनुसार ही प्रवेश दिया जाएगा।
      </Box>
      <Box maxWidth="100%" my="2rem">
        <Text textAlign="center" fontWeight="600">Contact Information</Text>
        <Box 
          p="4rem 2rem"
          boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.1)"
          mt="3rem"
          borderRadius="0.4rem"
          overflow="scroll"
          className="hide-scrollbar"
        >
          <Box as="table" maxWidth="100%" borderRadius="1rem">
            <Box as="thead" bg="warning.200" color="white">
              <Box as="tr" fontSize="1.4rem" fontWeight="600" >
                <Box as="th" textAlign="start" px="1rem" py="0.8rem">पाठ्यक्रम संचालकों का नाम एवं पता</Box>
                <Box as="th" textAlign="start" px="1rem" py="0.8rem">पाठ्यक्रम का नाम</Box>
                <Box as="th" textAlign="start" px="1rem" py="0.8rem">मोबाइल न०</Box>
              </Box>
            </Box>
            <Box as="tbody" bg="grey.50" color="grey.400">
              {ADMBVocContact?.map((info, index) => (
                <Box as="tr" key={index}>
                  <Box as="td" textAlign="start" px="1rem" py="1rem" fontSize="1.3rem">{info.firmName}</Box>
                  <Box as="td" textAlign="start" px="1rem" py="1rem" fontWeight="600" fontSize="1.3rem">
                    {info?.courseName.split("<br/>").map((course, i) => (
                      <Text key={i} fontSize="inherit" mb="0.6rem">
                        {course}
                      </Text>
                    ))}
                  </Box>
                  <Box as="td" textAlign="start" px="1rem" py="1rem" fontWeight="600" fontSize="1.3rem">
                    {info?.contact?.split("<br/>")?.map((contact, i) => (
                      <GetLink url={`tel:${contact}`} key={i}>
                        <Text fontSize="inherit" mb="0.8rem">
                          {contact}
                        </Text>
                      </GetLink>
                    ))}
                  </Box>
                </Box>
              ))}              
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}