import React, { useEffect, useState } from "react";
import { BsJournalBookmark } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { PageHead, PdfCard } from "molecules";
import { getEntranceSyllabus } from "redux/actions";
import SEO from "seo/SEO";
import { formatDate, scrollToTop } from "utils/utilities";

export const EntranceSyllabusPage = () => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");

  const dispatch = useDispatch()
  const { entranceExamSyllabus } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getEntranceSyllabus(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);    

  return (
    <>      
      <SEO
        title="Entrance Syllabus || Soban Singh Jeena University Almora"
        description="Entrance Syllabus || Soban Singh Jeena University Almora"
        keywords="Entrance Syllabus SSJU,Entrance Syllabus Soban Singh Jeena University Almora,Entrance Syllabus Soban Singh Jeena University,Entrance Syllabus Uttarakhand University,Entrance Syllabus SSJ University,Entrance Syllabus SSJU"        
      /> 
      <PageHead
        Icon={<BsJournalBookmark size="2.5rem"/>}
        title="Entrance Exam Syllabus"
        query={query}
        setQuery={setQuery}
        page={page}
        setPage={setPage}
        resultPerPage={entranceExamSyllabus?.resultPerPage}
        totalCount={entranceExamSyllabus?.totalCount}        
      >
        <Box
          width="fit-content"
          mx="auto"
        >
          <Grid
            gridTemplateColumns="1fr"
            gridRowGap="1.5rem"      
            my="3rem"      
            px="1rem"
          >
            {entranceExamSyllabus?.syllabus?.map((item, index) => (
              <PdfCard
                key={index}
                title={item.title}
                href={item.href}
                addedOn={formatDate(item.createdAt)}
              />
            ))}      
          </Grid>
        </Box>
      </PageHead>
    </>
  )
}