import React from "react";

import { Box, Button, Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { formatDate } from "utils/utilities";

export const NewsMarquee = ({data, id, title}) => {

  const onMEnter = () =>{
    document.getElementById(`mq${id}`).stop()
  }

  const onMOut = () =>{
    document.getElementById(`mq${id}`).start()
  }

  return (
    <Box
      bg="white"
      py="2rem"
      px="2rem"
      borderRadius="0.4rem"
      boxShadow="0px 0px 10px 0px rgba(0,0,0,0.1)"
      border="1px solid #f0f0f0"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pb="1rem"
        borderBottom="1px solid"
        borderColor="grey.100"
      >
        <Text
          fontSize="1.8rem"
          fontWeight="700"
          opacity="0.8" 
        >
          {title}
        </Text>
        <Box maxWidth="10rem">          
          <GetLink url={`/news-type/${id}`}>            
            <Button
              py="0.6rem"
              px="1rem"
              variant="primaryOutline"
            >
              <Text
                fontSize="1.3rem"
                fontWeight="500"
              >
                View More
              </Text>
            </Button>            
          </GetLink>
        </Box>
      </Flex>
      <Box mt="3rem">
        <Box
          as="marquee"
          behaviour="scroll"                 
          scrolldelay="230" 
          direction="up" 
          height="300px" 
          width="100%" 
          loop="INFINITE"                 
          id={`mq${id}`}
          onMouseOver={onMEnter}
          onMouseOut={onMOut}
        >
          {data?.map((item) => (
            <GetLink url={`/news/${item?.id}`} key={item?.id}>                
              <Box
                display={item?.hide ? "none" : "grid"}
                gridTemplateColumns="10rem 1fr"
                gridColumnGap="0.5rem"
                cursor="pointer"                
                mb="2rem"                  
              >
                <Box
                  bg="primary.150"
                  py="1.2rem"
                  px="1rem"
                  color="white"
                  borderRadius="0.2rem" 
                  height="100%"                     
                >
                  <Text fontSize="1.2rem">{formatDate(item?.createdAt)}</Text>
                </Box>
                <Text 
                  fontSize="1.4rem"
                  fontWeight="500"
                  _hover={{
                    color: "primary.150",                    
                  }}
                  transition="all 0.3s ease-in-out"
                >
                  {item?.title}
                </Text>
              </Box>               
            </GetLink>
          ))}          
        </Box>        
      </Box>
    </Box>
  )
}