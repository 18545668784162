import React from "react";

import { Box, Flex, Text } from "atoms";

export const SectionList = ({
  title,
  data
}) => {
  return (
    <Box mb="3rem">        
      {title && <Text
        color="primary.200"
        fontWeight="700"
        fontSize="2.4rem"
        lineHeight="4rem"
        borderBottom="3px solid"
        borderColor="primary.100"
        mb="1.5rem"
      >
        {title}
      </Text>}
      <Box>
        {data?.map((value, index) => (
          <Flex
            key={index}
            alignItems="start"             
            mb="1rem"
          >
            <Box
              minWidth="1rem"
              width="1rem"
              height="1rem"
              bg="primary.100"
              borderRadius="50%"
              mr="1rem"      
              mt="0.5rem"      
            />
            <Text              
              fontSize="1.5rem"
              fontWeight="500"
              color="#707070"
            >
              {value?.value?.split("<br/>")?.length > 1 ? <>                
                {value?.value?.split("<br/>")?.map((item, index) => (
                  <Flex
                    key={index}                    
                    mb="1rem"                    
                  >
                    <Box
                      display={index === 0 ? "none" : "block"}
                      minWidth="1rem"
                      width="1rem"
                      height="1rem"
                      bg="primary.100"
                      borderRadius="50%"
                      mr="1rem" 
                      mt="0.5rem"           
                    />
                    <Text>
                      {item}
                    </Text>
                  </Flex>
                ))}
              </> : value?.value}              
            </Text>
          </Flex>
        ))}
      </Box>
    </Box>
  )
}