import React, { useEffect } from "react";
import { FcGraduationCap } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, PageTitle } from "atoms";
import { Layout } from "organisms";
import { getResultDetails } from "redux/actions";
import { ResultPage } from "templates";
import { scrollToTop } from "utils/utilities";


const ExamResults = () => {
  
  const { examName } = useParams()

  const dispatch = useDispatch()
  const { loading, resultInfo } = useSelector(state => state.app);

  useEffect(() => {
    scrollToTop(window);
    if(examName !== resultInfo?.id) dispatch(getResultDetails(examName));    
  }, [examName, dispatch, resultInfo?.id]) 

  return (
    <>      
      <Layout>
        <PageTitle
          icon={<FcGraduationCap size="2.5rem"/>}
          title="SOBAN SINGH JEENA UNIVERSITY, ALMORA, ONLINE RESULTS"
        /> 
        {resultInfo && !resultInfo.hide && resultInfo.id === examName ?
          <ResultPage
            examName={resultInfo?.title}
            examCourses={resultInfo?.resultCourses}
          />
        : !loading && <Box px="2rem" py="3rem" textAlign="center" fontSize="2rem" color="gray">Result Not Found</Box>}               
      </Layout>
    </>
  )
}

export default ExamResults