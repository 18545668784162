import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { KnowledgeResourceCard } from "molecules";
import SEO from "seo/SEO";
import { getStrapiMediaUrl } from "utils/utilities";

export const KnowledgeResourcePage = () => {

  const { knowledgeResources } = useSelector(state => state.data);

  return (
    <>
      <SEO
        title="Knowledge Resources || Soban Singh Jeena University Almora"
        description="Knowledge Resources || Soban Singh Jeena University Almora"
        keywords="Knowledge Resources SSJU,Knowledge Resources Soban Singh Jeena University Almora,Knowledge Resources Soban Singh Jeena University,Knowledge Resources Uttarakhand University,Knowledge Resources SSJ University,Knowledge Resources SSJU,Images Soban Singh Jeena University Almora,Images Soban Singh Jeena University,Images Uttarakhand University,Images SSJ University"        
      />
      <Box overflow="hidden" p="3rem">
        <Grid
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)", lg:"repeat(4, 1fr)"}}
          gridGap="2rem"          
          justifyContent="center"
        >
          {knowledgeResources?.map((item) => (
            <KnowledgeResourceCard  
              key={item?.id}
              name={item?.title}
              link={item?.url}
              info={item?.description}
              img={getStrapiMediaUrl(item?.image?.url)}
            />
          ))}
        </Grid>
      </Box>      
    </>
  )
}