import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { toast } from "react-toastify";

import { Loader } from "atoms";
import ErrorPage from "pages/ErrorPage";
import Home from "pages/home";
import AboutType from "pages/about-type";
import Affiliation from "pages/affiliation";
import Faculty from "pages/faculty";
import NewsEvents from "pages/news-events";
import NewsInfo from "pages/news-info";
import NewsType from "pages/news-type";
import Gallery from "pages/gallery";
import AlbumImages from "pages/album-images";
import EntranceExamInfo from "pages/entrance-exam-info";
import EntranceSyllabus from "pages/entrance-syllabus";
import ExamResults from "pages/results";
import ExamSchedule from "pages/exam-schedule";
import ExamForm from "pages/exam-form";
import ExamFormDetails from "pages/exam-form-details";
import AdmissionInfo from "pages/admission-info";
import Career from "pages/career";
import SportsActivities from "pages/sports-activities";
import Contact from "pages/contact";
import VOC from "pages/voc-home";
import VOCSyllabus from "pages/voc-syllabus";
import CovidCare from "pages/covid-care";
import MediaCoverage from "pages/media-coverage";
import TermsAndCondition from "pages/terms-and-condition";
import PrivacyPolicy from "pages/privacy-policy";
import KnowledgeResources from "pages/knowledge-resources";
// import CollegeAffiliation from "pages/college-affiliation";
import NepSyllabus from "pages/nep-syllabus";
import NEP from "pages/nep";
import Library from "pages/library";
import ProfessionalSyllabus from "pages/professional-syllabus";
import { appConstants } from "redux/constants";
import FundingAgencies from "pages/funding-agencies";
import JobPortals from "pages/job-portals";
import RAndD from "pages/r-and-d";
import CareerInfo from "pages/career-info";
import EventInfo from "pages/event-info";

export const AppRoutes = ({
  popUp,
  setPopUp
}) => {  

  const dispatch = useDispatch();
  const { loading, error, message } = useSelector((state) => state.app);

  useEffect(() => {
    if(error) {
      toast.error(error);
      dispatch({ type: appConstants.RESET_APP });
    }
    if(message) {
      toast.success(message);
      dispatch({ type: appConstants.RESET_APP });
    }
}, [error, message, dispatch]);

  return (
    <Suspense fallback={<Loader />}>
      {loading && <Loader />}
      <Routes>
        <Route path="/" exact element={<Home 
          popUp={popUp}
          setPopUp={setPopUp}
        />} />        
        <Route path="/about/:aboutType" exact element={<AboutType/>} />   
        <Route path="/admission-info/:courseType" exact element={<AdmissionInfo/>} />               
        <Route path="/affiliation" exact element={<Affiliation/>} />   
        <Route path="/:albumId/images" exact element={<AlbumImages/>} />   
        <Route path="/career" exact element={<Career/>} />
        <Route path="/career/:careerId" exact element={<CareerInfo/>} />
        <Route path="/central-library" exact element={<Library/>} />                                  
        {/* <Route path="/college-affiliation/:authType" exact element={<CollegeAffiliation/>} />                             */}
        <Route path="/contact" exact element={<Contact/>} />
        <Route path="/covid-care" exact element={<CovidCare/>} />
        <Route path="/entrance-exam-info/:courseName" exact element={<EntranceExamInfo/>} />
        <Route path="/entrance-syllabus" exact element={<EntranceSyllabus/>} />
        <Route path="/exam-form-details" exact element={<ExamForm />} />        
        <Route path="/exam-form-details/:examName" exact element={<ExamFormDetails/>} />        
        <Route path="/faculty/:facultyId" exact element={<Faculty/>} />          
        <Route path="/faculty/:parentFacultyId/:facultyId" exact element={<Faculty/>} />                  
        <Route path="/gallery" exact element={<Gallery/>} />                
        <Route path="/knowledge-resources" exact element={<KnowledgeResources/>} />        
        <Route path="/media-coverage" exact element={<MediaCoverage/>} />          
        <Route path="/nep" exact element={<NEP/>} />
        <Route path="/nep-syllabus" exact element={<NepSyllabus/>} />
        <Route path="/news-events" exact element={<NewsEvents/>} />
        <Route path="/news/:newsId" exact element={<NewsInfo/>} />
        <Route path="/events/:eventId" exact element={<EventInfo/>} />
        <Route path="/news-type/:type" exact element={<NewsType/>} />                       
        <Route path="/privacy-policy" exact element={<PrivacyPolicy/>} />        
        <Route path="/sports-activities" exact element={<SportsActivities/>} />   
        <Route path="/student/exam-schedule" exact element={<ExamSchedule/>} />                                  
        <Route path="/student/results/:examName" exact element={<ExamResults/>} />     
        <Route path="/terms-and-condition" exact element={<TermsAndCondition/>} />   
        <Route path="/voc/" exact element={<VOC/>} />          
        <Route path="/voc/syllabus" exact element={<VOCSyllabus/>} />                                  
        <Route path="/professional-courses/syllabus" exact element={<ProfessionalSyllabus/>} />                                  
        <Route path="/R-and-D/funding-agencies" exact element={<FundingAgencies/>} />                                  
        <Route path="/student/job-portals" exact element={<JobPortals/>} />                                  
        <Route path="/R-and-D/portals" exact element={<RAndD/>} />                                  
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
};