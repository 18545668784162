import React, { useEffect } from "react";

import { Layout } from "organisms";
import { EntranceSyllabusPage } from "templates";
import { scrollToTop } from "utils/utilities";

const EntranceSyllabus = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <Layout>      
      <EntranceSyllabusPage/>
    </Layout>
  )
}

export default EntranceSyllabus