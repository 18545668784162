import React, { useState } from "react";
import { IoSchoolOutline } from "react-icons/io5";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { Box, PageTitle } from "atoms";
import { About, Courses, MarksDistribution, Members, Syllabus } from "./molecules";
import SEO from "seo/SEO";

export const FacultyPage = ({
  about,
  courses,
  faculty,
  marksDistribution,
  members,
  seo,
  syllabus
}) => {

  const [tabIndex, setTabIndex] = useState(0)  

  return (
    <>
      {seo && <>
        <SEO
          title={seo?.title}
          description={seo?.description}
          keywords={seo?.keywords}
        />
        <PageTitle
          icon={<IoSchoolOutline size="2.5rem"/>}
          title={seo?.title?.split(" || ")[0] || "Faculty Information"}
        />   
      </>}
      <Box mx="3rem" my="4rem">
        <Box
          width={{xs: "100%", xm: "110rem"}}                
          mx={{xm: "auto"}}    
        >
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList className="facultyTab-options" >
              <Tab className="facultyTab-option">About</Tab>
              <Tab className="facultyTab-option">Syllabus</Tab>
              <Tab className="facultyTab-option">Faculties</Tab>
              <Tab className="facultyTab-option">Marks Distribution</Tab>
              <Tab className="facultyTab-option">Courses</Tab>
            </TabList>

            <TabPanel>
              <About 
                data={about}
                hod={members?.find(member => member?.isHod === true)}
                links={about?.filter(item => item?.__component === "common.link")}
                files={about?.filter(item => item?.__component === "common.file")}
              />            
            </TabPanel>
            <TabPanel>
              <Syllabus data={syllabus} />
            </TabPanel>
            <TabPanel>
              <Members data={members} />            
            </TabPanel>
            <TabPanel>
              <MarksDistribution data={marksDistribution} />
            </TabPanel>
            <TabPanel>
              <Courses data={courses} />
            </TabPanel>
          </Tabs> 
        </Box>
      </Box>
    </>
  )
}