const host = window.location.hostname;
let apiURL = host === "localhost" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
let strapiURL = host === "localhost" ? process.env.REACT_APP_STRAPI_URL_DEV : process.env.REACT_APP_STRAPI_URL_PROD;

export const config = {  
  "BASE_URL": apiURL,  
  "API_URL": apiURL + "/api/v1",    
  "STRAPI_URL": strapiURL,
  "MEDIA_URL": apiURL + "/media",
  "APP_NAME": "SSJU",
  "APP_VERSION": "1.0.0"
}