import React from "react";

import { Box, Text } from "atoms";

export const DetailCourseCard = ({data}) => {
  return (
    <Box
      width="100%"
      px="2rem"
      py="2rem"
      boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      bg="white"
    >
      <Text fontSize="1.6rem" fontWeight="600" >{data.title}</Text>
      <Text fontSize="1.4rem" fontWeight="500" mt="1rem">{data.desc}</Text>
      <Box mt="2rem">
        <Box as="table" width="100%" borderRadius="1rem">                      
          <Box as="tbody" bg="grey.50" color="grey.400">
            {data?.table?.map((item, index)=>(
              <Box as="tr" key={index}>              
                <Box as="td" textAlign="start" px="1rem" py="1.2rem" fontSize="1.2rem" fontWeight="500">{item.title}</Box>
                <Box as="td" textAlign="start" px="1rem" py="1.2rem">
                  {item?.info?.split("<br/>")?.map((splitted, i) => (
                    <Text key={index} fontSize="1.2rem" fontWeight="600">{splitted}</Text>
                  ))}
                </Box>
              </Box>
            ))}            
          </Box>
        </Box>
      </Box>
      {data?.warning && <Box my="2rem">
        {data?.warning?.map((warn, index) => (
          <Text key={index} textAlign="center" fontSize="1.2rem" fontWeight="600" color="danger.100">*{warn}</Text>
        ))}
      </Box>}
    </Box>
  )
}