import React from "react";

import { Box, SectionTitle } from "atoms";
import { KnowledgeResouceSlider } from "molecules";

export const HomeKnowledgeResource = ({
  data
}) => {
  return (
    <>
      <Box maxWidth="100vw" px="3rem" py="3rem">       
        <SectionTitle
          title="Knowledge Resources"
          href="/knowledge-resources"          
        />                
      </Box>
      <KnowledgeResouceSlider
        data={data}
      />
    </>
  )
}