import React, { useEffect, useState } from "react";
import { AiOutlineSchedule } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { PageHead, PdfCard } from "molecules";
import { getExamSchedule } from "redux/actions";
import SEO from "seo/SEO";
import { formatDate, scrollToTop } from "utils/utilities";

export const ExamSchedulePage = () => {
  
  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");

  const dispatch = useDispatch()
  const { examSchedule } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getExamSchedule(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    scrollToTop(window);
  }, [page]); 

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);  

  return (
    <>
      <SEO
        title={`Examination Schedules || Soban Singh Jeena University Almora`}
        description={`Examination Schedules || Soban Singh Jeena University Almora`}
        keywords={`Examination Schedules SSJU,Examination Schedules Soban Singh Jeena University Almora,Examination Schedules Soban Singh Jeena University,Examination Schedules Uttarakhand University,Examination Schedules SSJ University,Examination Schedules SSJU`}
      />
      <PageHead
        Icon={<AiOutlineSchedule size="2.5rem"/>}
        title="Examination Schedules"
        query={query}
        setQuery={setQuery}
        page={page}
        setPage={setPage}
        resultPerPage={examSchedule?.resultPerPage}
        totalCount={examSchedule?.totalCount}        
      >
        <Box
          width="fit-content"
          mx="auto"
        >        
          <Grid
            gridTemplateColumns="1fr"
            gridRowGap="1.5rem"      
            my="4rem"      
            px="3rem"
          >
            {examSchedule?.schedules?.map((item) => (
              <Box display={item.hide ? "none" : "block"} key={item.id}>
                <PdfCard                
                  title={item?.title}
                  href={item?.href}
                  addedOn={formatDate(item?.createdAt)}
                />
              </Box>            
            ))}         
          </Grid>
        </Box>
      </PageHead>                  
    </>
  )
}