import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { Text } from "atoms";
import { Layout } from "organisms";
import { getFacultyPage } from "redux/actions";
import { FacultyPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Faculty = () => {

  const { facultyId } = useParams();
  let location = useLocation();
  let query = new URLSearchParams(location.search);

  let mode = query.get("mode");

  const dispatch = useDispatch();
  const { loading, facultyPage } = useSelector((state) => state.app);

  useEffect(() => {
    scrollToTop(window);
    if(mode === "slug"){
      if(facultyId !== facultyPage?.attributes?.faculty?.data?.attributes?.slug) dispatch(getFacultyPage(facultyId, true));
    }else{
      if(facultyId !== facultyPage?.attributes?.faculty?.attributes?.attributes?.firebaseId) dispatch(getFacultyPage(facultyId, false));
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyId, dispatch, mode]);

  return (
    <>
      <Layout>                
        {!loading &&
          (mode === "slug" ? facultyId !== facultyPage?.attributes?.faculty?.data?.attributes?.slug : facultyId !== facultyPage?.attributes?.faculty?.data?.attributes?.firebaseId) ?
            <Text m="2rem" textAlign="center">Faculty Not Found</Text>
        : <FacultyPage           
          about={facultyPage?.attributes?.about}
          courses={facultyPage?.attributes?.courses}
          faculty={facultyPage?.attributes?.data?.attributes}
          members={facultyPage?.attributes?.members}
          marksDistribution={facultyPage?.attributes?.marksDistribution}
          seo={facultyPage?.attributes?.seo}
          syllabus={facultyPage?.attributes?.syllabus}
        />}                
      </Layout>
    </>
  )
}

export default Faculty