import React from "react";

import { Box } from "atoms";
import { GetLink } from "molecules";

export const HomeHorizontalMQ = ({
  title,
  info,
  href,
  hrefText,
  pl
}) => {
  return (
    <Box
      as="marquee" 
      behaviour="scroll"                 
      scrolldelay="100" 
      height="28px" 
      width="100%" 
      loop="INFINITE"        
      borderTop="1px solid #f2f2f2"
      pt="5px"                                                
      color="accent.200"
      fontSize="1.4rem"    
      pl={pl}        
    >      
        {title && <Box as="strong" mr="0.5rem">{title}</Box>}
        {info && info}
        {href &&  <GetLink url={href}>
          <Box as="strong" color="danger.200" cursor="pointer" ml="0.5rem">
            {hrefText}
          </Box>
        </GetLink>}       
    </Box>     
  )
}
