import React from "react";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { BtnColors } from "utils/constant-data";
import { formatDate, generatePublicUrl } from "utils/utilities";

export const NewsCard = ({news}) => {
  return (
    <Box
      flexDirection="column"
      width="28rem"
      mx="auto"
      display={news.hide ? "none" : "flex"}
    >
      <Box
        width="28rem"
        height="36rem"
        bg="white"
        borderRadius="0.4rem"        
      >
        <Image
          src={generatePublicUrl(news?.image)}
          alt={news?.title}
          width="100%"
          height="100%"
          borderRadius="4px"
          objectFit="contain"
          preview={true}
        />
      </Box>
      <GetLink url={`/news/${news?.id}`}>        
        <Text mt="1rem" fontSize="1.5rem" fontWeight="600" opcaity="0.8" _hover={{color: "accent.200" }} transition="color 0.3s">
          {news?.title?.slice(0, 80)}{" "}{news?.title?.length > 80 && "..."}
        </Text>        
      </GetLink>
      <Text mt="1rem" fontSize="1.4rem" fontWeight="500" color="grey.400" className="text-justify">
        {news?.desc.slice(0, 80)}{" "}{news?.desc?.length > 80 && "..."}
      </Text>
      <Text mt="1rem" fontSize="1.2rem" fontWeight="500" color="grey.300">
        {formatDate(news?.createdAt)}
      </Text>
      <Flex
        width="100%"
        mt="1.5rem"
        flexWrap="wrap"        
        justifyContent="center"
        alignItems="center"
      >
        {news?.newsSrc?.map((src, index) => (
          <GetLink url={generatePublicUrl(src?.href)} key={src.id}>
            <Button
              variant={BtnColors[index]}
              width="12rem"
              py="1rem"
              px="1.2rem"     
              m="0.5rem"         
            >
              <Text fontSize="1.2rem" fontWeight="400" >
                {src?.title}
              </Text>                            
            </Button>
          </GetLink>
        ))}                          
      </Flex>     
    </Box>
  )
}