import React from "react";
import { BiArrowBack } from "react-icons/bi";
import ImageGallery from "react-image-gallery";
import { useNavigate } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";

import { Box, Flex, Grid, PillHeading, Text } from "atoms";
import SEO from "seo/SEO";
import { formatDate, generatePublicUrl } from "utils/utilities";

export const GalleryInnerPage = ({data}) => {

  const navigate = useNavigate();

  return (
    <>
      <SEO
        title={`${data?.title} - Images || Soban Singh Jeena University Almora`}
        description={`${data?.title} - Images || Soban Singh Jeena University Almora`}
        keywords={`${data?.title} - Images SSJU,${data?.title} - Images Soban Singh Jeena University Almora,${data.title} - Images Soban Singh Jeena University,${data.title} - Images Uttarakhand University,${data.title} - Images SSJ University,${data.title} - Images SSJU,Images Soban Singh Jeena University Almora,Images Soban Singh Jeena University,Images Uttarakhand University,${data.title}-Images SSJ University`}        
      />
      <Box maxWidth="110rem" mx="auto" px={{xs: "3rem", lg: "unset"}} my="3rem">
        <Grid
          gridTemplateColumns="3rem 1fr"
          gridColumnGap="2rem"
          alignItems="center"
        >
          <Box color="primary.300" cursor="pointer" onClick={() => navigate(-1)}>
            <BiArrowBack size="3rem"/>
          </Box>
          <Flex justifyContent="center">
            <PillHeading
              title={formatDate(data?.createdAt)}
            />
          </Flex>
        </Grid>
        <Box my="2.5rem">
          <Text textAlign="center" color="accent.100" fontWeight="600" fontSize="1.7rem">
            {data?.title}
          </Text>
        </Box>
        <Box maxWidth="100%">                  
          {data?.galleryImages && <ImageGallery
            items={data?.galleryImages.map((item) => ({
              original: generatePublicUrl(item?.image),
              thumbnail: generatePublicUrl(item?.image),
            }))}
          />}                
        </Box>
      </Box>
    </>
  )
}