import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";

import { Box, Error } from "atoms";

export const InputSelect = ({
  value,
  setValue,
  onChange=null,
  options,
  label,  
  formik=false,
  fieldName,
  touched,
  errors,
  name, 
  id,    
  disabled,
  placeholder,
  mb  
}) => {

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };  

  return (
    <Box width="100%" mb={mb || "0"}>
      <div className="input-container">
        {formik ? <>
          <Field
            as="select" 
            disabled={disabled}       
            id={id}
            name={name}
            onFocus={handleFocus}
            onBlur={handleBlur}            
            className={`input-field ${(isFocused) ? "input-field--focused" : ""} ${touched && errors ? "input-error" : ""}`}                    
            value={value}
            onChange={onChange ? onChange : (e) => {             
              setValue(fieldName, e.target.value)
            }}          
          >
            <Field as="option" value="">{placeholder}</Field>
            {options?.map((item, index) => (
              <Field
                as="option"  
                key={index} 
                value={item.value}
                style={{
                  textTransform: "capitalize"
                }}
              >
                {item.label}
              </Field>
            ))}
          </Field>
          <ErrorMessage name={name} component={Error} />             
        </> : <select
          className={`input-field ${isFocused || value ? "input-field--focused" : ""}`}
          value={value}
          onChange={onChange ? onChange : (e) => {
            setValue(e.target.value)
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          name={name}
          id={id}
          disabled={disabled}
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>}        
        <label
          className={`input-label ${(isFocused || value || value === "") ? "input-label--focused" : ""}`}
        >
          {label}
        </label>
      </div>
    </Box>  
  )
}