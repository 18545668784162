import React from "react";

import { Box, Text } from "atoms";

export const AddingSoon = () => {
  return (
    <Box
      p="2rem"
      width="100%"
    >
      <Text
        fontSize="1.8rem"
        fontWeight="600"
        textAlign="center"
        color="grey.400"
      >
        Adding Soon
      </Text>
    </Box>
  )
}
