import React from "react";

import { Box, Flex, Grid, Text } from "atoms";
import { LibraryData } from "utils/constant-data";

export const LibraryEResources = () => {
  return (
    <Box py="2rem" px="1rem">
      <Text
        fontSize="1.6rem"
        fontWeight="bold"
        textAlign="center"
        mb="1rem"
        textTransform="uppercase"
        color="#0d47a1"
      >
        OPEN ACCESS E-RESOURCES
      </Text>
      <Box my="3rem">
        {LibraryData?.map((item, index) => (
          <Box key={index}>
            <Text
              fontSize="1.6rem"
              fontWeight="bold"  
              textAlign="center"   
              mb="2rem"
              color="danger.200"
            >
              {item?.label}
            </Text>
            <Grid
              gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
              placeItems="center"
              gridColumnGap="1rem"
              gridRowGap="1rem"
              mb="3rem"                        
            >
              {item?.children?.map((child, i) => (
                <Flex
                  key={i}
                  boxShadow="0 0 0 1px #ccc"
                  borderRadius="0.5rem"
                  p="1rem"                  
                  width="100%" 
                  height="100%"      
                  maxWidth="300px"                          
                  flexDirection="column"                     
                  justifyContent="space-between"            
                >
                  <Text
                    fontSize="1.4rem"
                    fontWeight="bold"
                    textAlign="center"
                    mb="1rem"
                    color="grey.400"
                  >
                    {child?.label}
                  </Text>
                  <Box
                    as="a"
                    href={child?.link}
                    target="_blank" 
                    rel="noopener noreferrer"
                    mt="2rem"
                    bg="accent.100"
                    width="100%"
                    textAlign="center"
                    p="0.7rem"
                    color="white"
                    borderRadius="0.4rem"
                    fontSize="1.2rem"                
                    textTransform="uppercase"
                  >
                    Visit
                  </Box>              
                </Flex>
              ))}              
            </Grid>          
          </Box>
        ))}        
      </Box>
    </Box>
  )
}