import React from "react";

import { Box, Text } from "atoms";
import { LibraryMembers } from "utils/constant-data";

export const LibraryAbout = () => {
  return (
    <Box py="2rem" px="1rem">
      <Text
        fontSize="1.6rem"
        fontWeight="bold"
        textAlign="center"
        mb="1rem"
        textTransform="uppercase"
        color="#0d47a1"
      >
        Library Details
      </Text>
      <Box mb="2rem">
        <Text as="p" fontSize="1.4rem" color="grey.300" lineHeight="2rem">
          A.D. Pant Central Library is situated in an independent double-storied building in
          Almora Campus of the University. The Central Library has a large collection of
          books covering Science, Social Science, Management and Humanities and its
          related fields. Presently, this library has a repository of over 2,30,000 books under
          about 1,09,000 titles. It provides library-services to more than 6500 undergraduate
          and postgraduate students, research scholars, and nearly 200 faculty and staff
          members with the help of 12 staff members. The Library also provides reprography
          and Internet facilities to its members. The library is an active member of DELNET
          (Developing Library Network) and, hence, has access to more than 7000 top
          libraries of national and international level. DELNET has collection of Science,
          Social Science, Management and Humanities and its related field’s. Through
          DELNET we are providing Inter Library Loan Service to our users.
        </Text>
        <Text as="p" fontSize="1.4rem" color="grey.300" lineHeight="2rem">
          The library spanning over an area of 1276 sqm. and equipped with Library
          Management System; which is automated through TLMS Library Management
          Software and E-granthalaya software. TLMS Software Package is an Integrated
          Library Management System (ILMS) with Barcode Scanner that supports all in-
          house operations of the library.
        </Text>
        <Text as="p" fontSize="1.4rem" color="grey.300" lineHeight="2rem">
          The Library offers computerized Catalogue Search Services for user community
          through the On-line Public Access Catalogue (OPAC) which allows access of
          bibliographic details of the books available in the Library.
        </Text>
      </Box>
      <Text
        fontSize="1.6rem"
        fontWeight="bold"
        textAlign="center"
        my="1rem"
        textTransform="uppercase"
        color="#0d47a1"
      >
        Library Timings
      </Text>
      <Box
        as="table"        
        width="100%"
        textAlign="center"
        my="2rem"        
      >
        <Box
          as="tr"          
          width="100%"
          textAlign="center"          
        >
          <Box
            as="th"
            border="1px solid #ccc"
            borderCollapse="collapse"
            py="1rem"
            fontSize="1.3rem"
            textTransform="capitalize"
            color="#2e81f4"
          >
            On all working days
          </Box>
          <Box
            as="th"
            border="1px solid #ccc"
            borderCollapse="collapse"
            py="1rem"
            fontSize="1.3rem"
            textTransform="capitalize"
            color="#2e81f4"
          >
            Lunch Hour
          </Box>
          <Box
            as="th"
            border="1px solid #ccc"
            borderCollapse="collapse"
            py="1rem"
            fontSize="1.3rem"
            textTransform="capitalize"
            color="#2e81f4"
          >
            Circulation Counter
          </Box>
        </Box>
        <Box
          as="tr"
        >
          <Box
            as="td"
            border="1px solid #ccc"
            borderCollapse="collapse"
            py="1rem"
            fontSize="1.3rem"            
            color="grey.500"
          >
            10:00 AM to 4:00 PM
          </Box>
          <Box
            as="td"
            border="1px solid #ccc"
            borderCollapse="collapse"
            py="1rem"
            fontSize="1.3rem"            
            color="grey.500"
          >
            1:00 PM to 2:00PM
          </Box>
          <Box
            as="td"
            border="1px solid #ccc"
            borderCollapse="collapse"
            py="1rem"
            fontSize="1.3rem"            
            color="grey.400"
          >
            11:00 AM to 3:00 PM
          </Box>
        </Box>
      </Box>
      <Text
        color="danger.100"
        fontSize="1.2rem"
      >* The above mentioned library timings are subject to change, which will be notified on the notice board.</Text>
      <Text
        color="danger.100"
        fontSize="1.2rem"
      >* The Circulation desk normally closes at 3:00 PM</Text>
      <Text
        fontSize="1.6rem"
        fontWeight="bold"
        textAlign="center"
        mt="3rem"
        mb="2rem"
        textTransform="uppercase"
        color="#0d47a1"
      >
        Library Committee
      </Text>
      <Box>
        <Text fontSize="1.4rem" fontWeight="600">
          A D Pant Central Library has a library Committee with following member:
        </Text>
        <Box
          as="ol"
          ml="-1.5rem"
        >
          {LibraryMembers?.map((member, index) => (
            <Text 
              as="li"               
              key={index}
              my="0.5rem"
              fontSize="1.4rem"
              color="grey.300"
              fontWeight="500"
            >
              {member}
            </Text>
          ))}          
        </Box>
        <Text
          color="info.100"
          my="2rem"
          fontSize="1.3rem"
        >
          Library committee meets at regular intervals and strives to render the library user friendly.
        </Text>
      </Box>
    </Box>
  )
}