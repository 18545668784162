import { dataConstants } from "redux/constants";

const initState = {  
  additionalPopUp: false,
  error: null,
  home: {
    events: [],
    importantLink: [],
    mq1: [],
    mq2: [],
    mq3: [],
    news: [],
    recruitment: [],
    popUp: null,
    slider: [],
    displayCards: [],
    about: null,
    horizontalMQ: [],
    featured: [],
  },
  headerBtn: null,
  headerLogos: null,
  navbar: null,
  footer: null,
  phone: "",
  email: "",
  address: "",
  addressSm: "",
  knowledgeResources: [],
  emails: [],
  socials: [],
  vocHomePage: null,
  loading: true,
  message: null,
  visits: null  
}

export const dataReducer = (state = initState, action) => {
  switch(action.type){

    case dataConstants.GET_INITIAL_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case dataConstants.GET_INITIAL_DATA_SUCCESS:      
      return {        
        ...state,    
        additionalPopUp: action.payload?.popUp?.show,              
        home: {
          events: action.payload?.apiData?.events,
          importantLink: action.payload?.apiData?.impLinks,
          mq1: action.payload?.apiData?.mq1,
          mq2: action.payload?.apiData?.mq2,
          mq3: action.payload?.apiData?.mq3,
          news: action.payload?.apiData?.news,
          recruitment: action.payload?.apiData?.recruiments,                    
          popUp: action.payload?.popUp,
          slider: action.payload?.slider,
          displayCards: action.payload?.displayCards,
          about: action.payload?.about,
          horizontalMQ: action.payload?.horizontalMQ,                    
          featured: action.payload?.featured,
        },
        headerBtn: action.payload?.headerBtn,
        headerLogos: action.payload?.headerLogos,
        navbar: action.payload?.navbar,
        footer: action.payload?.footer,
        phone: action.payload?.phone,
        email: action.payload?.email,
        address: action.payload?.address,
        addressSm: action.payload?.addressSm,
        knowledgeResources: action.payload?.knowledgeResources,        
        emails: action.payload?.emails,
        socials: action.payload?.socials,
        vocHomePage: action.payload?.vocHomePage,
        visits: action.payload?.apiData?.visitData,
        loading: false,                
      }

    case dataConstants.GET_INITIAL_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case dataConstants.CLOSE_ADDITIONAL_POPUP:
      return{
        ...state,
        additionalPopUp: false
      }

    default: return state;
  }
}