import React from "react";

import { Box, Button, Grid, PillHeading, Text } from "atoms";
import { ProfileCard } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";
import { GetLink } from "molecules";

export const HomeAbout = ({
  data
}) => {
  return (
    <>
      <Grid
        gridTemplateColumns={{xs: "1fr", xm: "1fr 1fr"}}
        gridGap="2rem"
        px={{xs: "3rem", lg: "8rem"}}
        py={{xs: "3rem", lg: "4rem"}}
        mb="2rem"
      >
        <Box>
          <PillHeading
            title={data?.heading}
          />
          <Text
            fontSize={{xs: "2.2rem", xm: "2.4rem"}}
            color="accent.100"
            fontWeight="700"
            mt="1.6rem"            
          >
            {data?.title}
          </Text>
          <Text
            fontSize="1.6rem"
            color="primary.200"
            fontWeight="600"
            mt="1.6rem"
            lineHeight="2.6rem"
            opacity="0.5"
            className="text-justify"
          >
            {data?.description}
          </Text>          
          <GetLink url="/about/university-at-a-glance">
            <Button
              variant="primaryOutline"
              width="20rem"
              mt="1.6rem"
              py="1rem"            
              style={{borderRadius: "4rem"}}
              minWidth="20rem"
            >
              Learn More
            </Button>
          </GetLink>          
        </Box>

        <Grid
          gridTemplateColumns={{xs: "1fr", md: "1fr 1fr"}}
          gridGap="2rem"
          height="fit-content"
          mt={{xs: "3rem", xm: "6rem"}}          
        >
          {data?.cards?.map((item) => (
            <Box
              key={item?.id}
              height="100%"
            >
              <ProfileCard
                img={getStrapiMediaUrl(item?.avatar?.url)}
                name={item?.name}
                designation={item?.designation}
                timeline={item?.timeline}                
              />
            </Box>
          ))}          
        </Grid>
      </Grid>
    </>
  )
}