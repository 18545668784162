import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { PdfCard } from "molecules";
import { getProfessionalSyllabus } from "redux/actions";
import SEO from "seo/SEO";
import { formatDate } from "utils/utilities";
import { getStrapiMediaUrl } from "utils/utilities";

export const ProfessionalSyllabusPage = () => {

  const dispatch = useDispatch()
  const { professionalSyllabus } = useSelector(state => state.app);  

  useEffect(()=>{
    dispatch(getProfessionalSyllabus())
  }, [dispatch]);    

  return (
    <>
      <SEO
        title="Professional Courses Syllabus || Soban Singh Jeena University Almora"
        description="Professional Courses Syllabus || Soban Singh Jeena University Almora"
        keywords="Professional Courses Syllabus, Soban Singh Jeena University Almora, Professional Courses Syllabus SSJU, Professional Courses Syllabus SSJU Almora, Professional Courses Syllabus SSJU Uttarakhand, Professional Courses Syllabus SSJU University, Professional Courses Syllabus SSJU Almora Uttarakhand"
      />          
      <Box
        width="fit-content"
        mx="auto"
      >       
        <Grid
          gridTemplateColumns="1fr"
          gridRowGap="1.5rem"      
          my="2rem"      
          px="1rem"
        >
          {professionalSyllabus?.data?.map((item) => (
            <PdfCard
              key={item?.id}
              title={item?.attributes?.title}
              href={getStrapiMediaUrl(item?.attributes?.file?.data?.attributes?.url)}
              addedOn={formatDate(item.attributes?.createdAt)}
            />
          ))}             
        </Grid>
      </Box>      
    </>
  )
}